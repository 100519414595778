<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Cadastro de Formas Pagamento</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="Pagamento.cfpId" label="Id" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <v-text-field v-model="Pagamento.cfpNome"
                                        label="Nome" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-select v-model="Pagamento.cfpVisivel" label="Visivel" :items="StatusAtivo"
                                        item-title="nome" item-value="cfpVisivel" variant="outlined">
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="Pagamento.cfpId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="outlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="Pagamentos" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-cfpVisivel="item">
                            <span>{{ item.cfpVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'CadastroFormasPagamentoView',
    components: {
        EasyDataTable
    },
    data: () => ({
        Pagamento: {
            cfpId: 0,
            cfpNome: '',
            cfpVisivel: '',
        },
        Pagamentos: [],
        StatusAtivo: [{ nome: "Sim", cfpVisivel: true }, { nome: "Não", cfpVisivel: false }],
        headers: [
            { text: "Id", value: "cfpId", sortable: true, },
            { text: "Nome", value: "cfpNome", sortable: true, },
            { text: "Visivel", value: "cfpVisivel", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
    }),
    methods: {
        Limpar() {
            this.Pagamento.cfpId = 0;
            this.Pagamento.cfpNome = '';
            this.Pagamento.cfpVisivel = '';
            this.Get();
        },
        async Add() {
            if (this.Pagamento.cfpNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else {
                if (this.Pagamento.cfpVisivel === "" || this.Pagamento.cfpVisivel === null){
                        this.Pagamento.cfpVisivel = true;
                    }
                axios.post(`/Process/CadFormasPagamento/Add`, this.Pagamento)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                            this.Limpar();
                        }
                    });
                
            }
        },
        async Put() {
            if (this.Pagamento.cfpNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else {
                if (this.Pagamento.cfpVisivel === "" || this.Pagamento.cfpVisivel === null){
                        this.Pagamento.cfpVisivel = true;
                    }
                axios.put(`/Process/CadFormasPagamento/Update`, this.Pagamento)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        EditGrid(item) {
            // Lógica para editar o item
            this.Pagamento = item;
            this.$scrollToTop();
        },
        DeleteGrid(item) {
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.cfpId;
                axios.delete(`/Process/CadFormasPagamento/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                    }
                });
            }
            });
        },
        Get() {
            axios.get(`/Process/CadFormasPagamento/GetAll`)
                .then(response => {
                    this.Pagamentos = response.data;
                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
