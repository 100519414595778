<template>
       <div>
        <div class="pd-ltr-20">
           <div class="faq-wrap" v-if="PedidosCli!='' && DadosGarantidora != '' && Cliente != '' && Imobiliaria!= '' && PedidosEndereco!= '' && PlanosGarantias != '' && ListaPedidoItem != '' && ListaPedidoItemAdicional != '' && FormasPagamento != ''">
                <div id="accordion">
                    <div class="card-box pd-20 height-100-p mb-30">
                        <div id="Topo" class="collapse show">
                            <div class="card-body" v-if="PedidosCli.pspId == 7">
                              <v-container>
                                    <v-row>
                                    <v-col cols="12" md="12" style="text-align: center;">
                                        <img :src="$imgURL + DadosGarantidora[0].insInstitucionalImagens[0].itgUrlImagem" alt="" style="width:200px;height:auto;">
                                    </v-col>
                                    </v-row>
                                    <div ref="tabela">
                                    <v-row>
                                    <v-col cols="12" md="12" style="text-align: center;">
                                            <h5>Quadro Resumo - CONTRATO Nº {{ pedidoId }}</h5>
                                    </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-card style="padding: 15px;" color="#f7f7f7">
                                                <div>
                                                    <h5 class="subtitulo" style="padding-bottom: 15px;">Dados Inquilino</h5>
                                                    <div class="pedido_dados" style="font-size: 14px;"><strong>Nome Completo:</strong> {{ Cliente.cliNomeEmpresa }}</div>
                                                    <div class="pedido_dados" style="font-size: 14px;"><strong>Documento:</strong> {{ Cliente.cliCpf == "" ? Cliente.cliCnpj : Cliente.cliCpf  }} </div>
                                                    <div class="pedido_dados" style="font-size: 14px;"><strong>Data Cadastro:</strong> {{ Cliente.cliDataCadastro != null ? formattedDate(Cliente.cliDataCadastro) : "" }} </div>
                                                    <div class="pedido_dados" style="font-size: 14px;"><strong>Telefone:</strong> {{ Cliente.cliWhatsapp }} </div>
                                                    <div class="pedido_dados" style="font-size: 14px;"><strong>E-mail:</strong> {{ Cliente.cliEmail }} </div>
                                                </div>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-card style="padding: 15px;" color="#f7f7f7">
                                                    <h5 class="subtitulo" style="padding-bottom: 15px;">Dados Administrador</h5>
                                                    <div class="pedido_dados" style="font-size: 14px;"><strong>Nome/Razão Social:</strong> {{ Imobiliaria.cliNomeEmpresa }}</div>
                                                    <div class="pedido_dados" style="font-size: 14px;"><strong>Documento:</strong> {{ Imobiliaria.cliCpf == "" ? Imobiliaria.cliCnpj : Imobiliaria.cliCpf  }} </div>
                                                    <div class="pedido_dados" style="font-size: 14px;"><strong>Endereço:</strong> {{ EnderecoImob.cliEndereco }}, {{ EnderecoImob.cliNumero }} - Compl: {{  EnderecoImob.cliComplemento }} </div>
                                                    <div class="pedido_dados" style="font-size: 14px;"><strong>Bairro/Cidade/UF:</strong> {{ EnderecoImob.cliBairro }} - {{ EnderecoImob.cliCidade }} / {{ EnderecoImob.cliEstado }} </div>
                                                    <div class="pedido_dados" style="font-size: 14px;"><strong>Contatos:</strong> {{ Imobiliaria.cliWhatsapp }} - {{ Imobiliaria.cliEmail }} </div>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-card style="padding: 15px;" color="#f7f7f7">
                                                <div>
                                                    <h5 class="subtitulo" style="padding-bottom: 15px;">Dados do imóvel da locação</h5>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                        <strong>Endereço:</strong> {{ PedidosEndereco[0].pdcEndereco}}, {{ PedidosEndereco[0].pdcNumero }} - {{ PedidosEndereco[0].pdcComplemento }}
                                                    </p>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                        <strong>Cidade/UF:</strong> {{ PedidosEndereco[0].pdcCidade }} / {{ PedidosEndereco[0].pdcEstado }}
                                                    </p>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                        <strong>Bairro:</strong> {{ PedidosEndereco[0].pdcBairro}}
                                                    </p>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                        <strong>CEP:</strong> {{ PedidosEndereco[0].pdcCep }}
                                                    </p>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                        <strong>Tipo Imóvel:</strong> {{ PedidosCli.pedTipoFrete }} {{ PedidosEndereco[0].pdcResidencialTipo }}
                                                    </p>
                                                </div>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                        <v-card style="padding: 15px;" color="#f7f7f7">
                                                <div>
                                                    <h5 class="subtitulo" style="padding-bottom: 15px;">Dados da Contratação</h5>
                                                    <div v-for="produto in ListaPedidoItem" :key="produto.pdiId">
                                                    <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                        <strong>Multiplicador:</strong> 18x
                                                    </p>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                        <strong>Valor Total Contratado: </strong> R$ {{ formatarValor(valorProdAdicional * 18) }}
                                                    </p>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                        <strong>CUSTOS DE SAÍDA:</strong> 3x
                                                    </p>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                        <strong>Valor Locatício:</strong> R$ {{ valorProdAdicional > 0 ? formatarValor(valorProdAdicional) : 0 }}
                                                    </p>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                        <strong>VALOR DA TAXA:</strong> R$ {{ formatarValor(((valorProdAdicional * 12) * produto.pdiCampoExtra2)/100) }}
                                                    </p>
                                                    <p class="texto-mensagem mb-0" style="color:#333333;font-size: 14px;">
                                                        <strong>Setup:</strong> R$ {{ formatarValor(parseFloat(PlanosGarantias.filter(x=> x.itiId == parseInt(produto.pdiCampoExtra1))[0].itiReferencia)) }}
                                                    </p>
                                                    </div>
                                                </div>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </div>
                                <v-row>
                                  <v-col cols="12" md="12">
                                      <v-card style="padding: 15px;" color="#f7f7f7">
                                            <div>
                                                <h5 class="subtitulo" style="padding-bottom: 15px;">Forma de Pagamento</h5>
                                            </div>
                                            <v-row>
                                                <v-col cols="12" md="6">
                                                  <p class="texto-mensagem mb-0" style="color:#333333;">
                                                    <strong>Valor Total:</strong> {{ formatarValor(valorProd) }}
                                                  </p>
                                                  <p class="texto-mensagem mb-0" style="color:#333333;">
                                                    <strong>Selecione a forma de pagamento:</strong> 
                                                          <v-select v-model="formaPgtoSelected" label="Selecione" :items="FormasPagamento" clearable @update:model-value="MostraInformacoes()"
                                                      item-title="nome" item-value="valor" variant="outlined">
                                                  </v-select>
                                                </p>
                                                <p> {{ TextoInfo }}</p>
                                                <p>
                                                  <button type="button" class="btn btn-success"
                                                      @click="GerarLinkPagamento()">
                                                      Gerar Link de Pagamento
                                                  </button>
                                                </p>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                   <div v-if="LinkPagamento!=''" style="background-color: beige; padding: 20px;border: 1px solid #cccccc;border-radius:15px;">
                                                      <h5 class="subtitulo" style="padding-bottom: 15px;">Clique no link para ir para tela de pagamento</h5>
                                                     <p><a :href="LinkPagamento">{{LinkPagamento}}</a></p>
                                                   </div>
                                                </v-col>
                                            </v-row>
                                      </v-card>
                                  </v-col>
                                </v-row>
                              </v-container>
                            </div>
                            <div class="card-body" v-else>
                                <v-container>
                                    <v-row>
                                    <v-col cols="12" md="12" style="text-align: center;">
                                        <h5>Contratação {{ pedidoId }} - Não disponível para pagamento!</h5>
                                    </v-col>
                                    </v-row>
                                </v-container>
                            </div>
                        </div>
                    </div>
                </div>
           </div>
        </div>
        
      </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { useRoute } from 'vue-router';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';

export default {
  name: 'PgtoContratacaoClienteView',
  components: {
    moment
  },
  setup() {
        const route = useRoute();
        const clienteId = route.params.cliId;
        const imobiliariaId = route.params.imobId;
        const pedidoId = route.params.pedId;
        return {
          clienteId,
          imobiliariaId,
          pedidoId
        };
    },
  data: () => ({
    accordion: false,
    ListContratos:[],
    DadosGarantidora:[],
    ListaPedidoItem:[],
    ListaPedidoItemAdicional:[],
    valorProdAdicional:0,
    descricao: '',
    valorProd: 0,
    PlanosGarantias:[],
    ListEnderecoImob:[],
    EnderecoImob:{},
    formaPgtoSelected: null,
    FormasPagamento:[],
    DadosConfiguracao:[],
    TextoInfo: '',
    LinkPagamento: '',
    //imagem
    selectedFile: null,
  }),
  methods: {
    formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        formattedDate(value) {
            if (value != null || value != "") {
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else {
                return "";
            }

        },
        async UploadImg(dados) {
            const uploadResult = await axios.post(`/Process/UploadImg/uploadPdf/` + this.clienteId + "/" + this.imobiliariaId + "/" + this.pedidoId, dados);
            return uploadResult;
        },
        async EnviarDocumento(doc){
           // console.log('doc')
          //  console.log(doc)
            /*if (doc.status == 200) {
                let _pedidoMensagem = {
                    PmsId: 0,
                    GrtId: 7,
                    CdtId: 28,
                    IdiId: 1,
                    PmsUrlImagem: doc.data.nameFile,
                    PmsNomeDe: this.DocSelected.toString(),
                    PmsNomePara: this.TiposDocumentos.filter(x=>x.pmsNomePara == this.DocSelected)[0].pmsNomePara,
                    PmsTexto: "", 
                    PmsTelefoneRecebe: "",
                    PedId: this.PedidosCli.pedId,
                    };

                    console.log('_pedidoMensagem')
                    console.log(_pedidoMensagem)*/

                  /*  await axios.post(`/Process/PedPedidoMensagem/Add`, _pedidoMensagem)
                    .then((response) => {
                    if (response.data > 0) {
                        axios.get('/Process/PedPedidoMensagem/GetAllByPedId/' + this.PedidosCli.pedId)
                            .then(response => {
                                this.ListDocumentos = response.data;
                                let _nomeDoc = this.TiposDocumentos.filter(x=>x.pmsNomePara == this.DocSelected)[0].pmsNomePara;
                                let _pmsdel = this.ListDocumentos.filter(x=> x.pmsNomePara == _nomeDoc && (x.pmsUrlImagem == '' || x.pmsUrlImagem == null));
                                let _pmsId = _pmsdel[0].pmsId
                                        axios.delete(`/Process/PedPedidoMensagem/Delete/${_pmsId}`)
                                                .then(response => {
                                                    if (response.status == 200) {
                                                        //carrega documentos
                                                        axios.get('/Process/PedPedidoMensagem/GetAllByPedId/' + this.PedidosCli.pedId)
                                                        .then(response => {
                                                            this.ListDocumentos = response.data;
                                                            this.DocSelected = '';
                                                            this.selectedFile = '';
                                                        })
                                                        .catch(error => {
                                                            console.error(error);
                                                        });
                                                    }
                                                });
                            })
                            .catch(error => {
                                console.error(error);
                            });
                    }
                    });
            }
            else {
                this.$mensagemErro("Não foi possível carregar imagem.")
            }*/

        },
        MostraInformacoes(){
            if (this.formaPgtoSelected == "PIX"){
               if (parseInt(this.DadosConfiguracao[0].itiCampoExtra3) > 0)
               {
                let _valorTaxa = ((this.valorProdAdicional * 12) * this.ListaPedidoItem[0].pdiCampoExtra2)/100;
                let _percDesconto = parseInt(this.DadosConfiguracao[0].itiCampoExtra3)/100;
                let _valorSetup = parseFloat(this.PlanosGarantias.filter(x=> x.itiId == parseInt(this.ListaPedidoItem[0].pdiCampoExtra1))[0].itiReferencia);
                let _valorComDesconto = (_valorTaxa - (_valorTaxa *_percDesconto)) + _valorSetup;
                this.TextoInfo = "No Pagamento via pix você ganha " + parseInt(this.DadosConfiguracao[0].itiCampoExtra3) + "% de desconto no valor da taxa. Ficando valor Total de R$  " + this.formatarValor(_valorComDesconto) + ". Clique para gerar o link de pagamento." ;
               }
               else{
                 this.TextoInfo = "Clique para gerar o link de pagamento.";
               }
              
            }
            else if (this.formaPgtoSelected == "CREDIT_CARD"){
              this.TextoInfo = "No Pagamento via Cartão de crédito você pode parcelar em até " + this.DadosConfiguracao[0].itiCampoExtra2 + "x sem juros, clique para gerar o link de pagamento.";
            }
            else if (this.formaPgtoSelected == "RECURRENT"){
              this.TextoInfo = "No Pagamento via Cartão de crédito recorrente o valor do parcelamento será debitado mensalmente do seu cartão, clique para gerar o link de pagamento.";
            }
            else{
              this.TextoInfo = "";
            }
        },
       async GerarLinkPagamento() {
          if (this.formaPgtoSelected == "" || this.formaPgtoSelected == null){
            this.$mensagemAviso("Selecione a forma de pagamento.")
          }
          else{

            await axios.get(`/Process/PedPedidos/GetById/` + this.pedidoId)
                        .then(response => {
                            this.PedidosCli = response.data;
            });

                if (this.PedidosCli.pspId == 7)
                {
                //Limpar dados de pagamento antes de gerar novo
                await axios.get(`/Process/PedPedidoPgtoComprova/GetAllByPedId/` + this.pedidoId)
                        .then(response => {
                            if (response.data != "") {   
                                axios.delete(`/Process/PedPedidoPgtoComprova/Delete/` + response.data[0].ppcId)
                                        .then(responsedel => {
                                                axios.delete(`/Process/PgtoAsaas/delete/` + this.PedidosCli.pedCodigoRetornoPgto )
                                                .then(responseDelLink => {
                                                }); 
                                    });
                            }
                    });

                    await axios.get(`/Process/PedPedidoFoto/GetAllByPedId/` + this.pedidoId)
                        .then(response => {
                            if (response.data != "") {   
                                axios.delete(`/Process/PedPedidoFoto/Delete/` + response.data[0].pftId)
                                        .then(responsedel => {
                                    });
                            }
                    });

                //monta json dados da contratacao
                let dados = {
                    Multiplicador: '18x',
                    ValorContratado: this.formatarValor(this.valorProdAdicional * 18),
                    CustoSaida: '3x',
                    ValorLocaticio:  this.valorProdAdicional > 0 ? this.formatarValor(this.valorProdAdicional) : 0,
                    ValorTaxa: this.formatarValor(((this.valorProdAdicional * 12) * this.ListaPedidoItem[0].pdiCampoExtra2)/100),
                    Setup: this.formatarValor(parseFloat(this.PlanosGarantias.filter(x=> x.itiId == parseInt(this.ListaPedidoItem[0].pdiCampoExtra1))[0].itiReferencia))
                }
                var resultDoc = await this.UploadImg(dados);
                let _pedidoDocumento = {
                                        PftId: 0,
                                        GrtId: 7,
                                        CdtId: 28,
                                        IdiId: 1,
                                        PftUrlImagem:  resultDoc.data.nameFile,
                                        PedId: parseInt(this.pedidoId),
                                        PftDadosContrato: JSON.stringify(dados),
                                    }

                await axios.post(`/Process/PedPedidoFoto/Add`, _pedidoDocumento)
                        .then(response => {
                            if (response.data > 0) {   
                                   // console.log('_pedidoDocumento adicionado')
                                  //  console.log(_pedidoDocumento)        
                            }
                    });
               // console.log('resultDoc')
              //  console.log(resultDoc)

                if (this.formaPgtoSelected == "PIX"){
                    let _valorComDesconto = 0;

                    if (parseInt(this.DadosConfiguracao[0].itiCampoExtra3) > 0)
                    {
                        let _valorTaxa = ((this.valorProdAdicional * 12) * this.ListaPedidoItem[0].pdiCampoExtra2)/100;
                        let _percDesconto = parseInt(this.DadosConfiguracao[0].itiCampoExtra3)/100;
                        let _valorSetup = parseFloat(this.PlanosGarantias.filter(x=> x.itiId == parseInt(this.ListaPedidoItem[0].pdiCampoExtra1))[0].itiReferencia);
                        _valorComDesconto = (_valorTaxa - (_valorTaxa *_percDesconto)) + _valorSetup;
                    }
                    else{
                        _valorComDesconto = this.valorProd;
                    }

                    const linkPix = {
                            billingType: 'PIX',
                            chargeType: 'DETACHED',
                            name: 'Contratação de Serviços ' + this.DadosGarantidora[0].itiNome + ' - Nº: ' + this.pedidoId,
                            description: 'Contratação de Serviços ' + this.DadosGarantidora[0].itiNome + ' - Nº: ' + this.pedidoId,
                            endDate: moment().add(2,'days').format('YYYY-MM-DD'),
                            value: _valorComDesconto,
                            maxInstallmentCount: 1,
                            dueDateLimitDays: 3,
                            callback: {successUrl: 'https://www.aquigarante.com.br/successpgto'},
                        }

                      //  console.log('linkPix')
                      //  console.log(linkPix)

                        await axios.post(`/Process/PgtoAsaas/payament`, linkPix)
                            .then(response => {
                                   // console.log('pagamento pix'); 
                                  //  console.log(response.data); 

                                    let _dadosSituacaoPix = {
                                        PspId: 7,
                                        PedSituacaoPedido: "Aguardando Pagamento",
                                        PedObsCliente: JSON.stringify(response.data),
                                        PedCodigoRetornoPgto: response.data.id,
                                        PedQtdeParcelas: 1,
                                        PedValorParcelas: linkPix.value,
                                        PedId: parseInt(this.pedidoId),
                                    }

                                    axios.put(`/Process/PedPedidos/UpdateSituacaoAsaas`, _dadosSituacaoPix)
                                        .then(response => {
                                            if (response.status == 200) {
                                                console.log('Update Situacao')
                                            }
                                        });
                                                
                                        let _pedidoComprovante = {
                                                PpcId: 0,
                                                GrtId: 7,
                                                CdtId: 28,
                                                IdiId: 1,
                                                PpcUrlImagem:  response.data.url,
                                                PedId: parseInt(this.pedidoId),
                                            }

                                        axios.post(`/Process/PedPedidoPgtoComprova/Add`, _pedidoComprovante)
                                            .then(response => {
                                                if (response.data > 0) {   
                                                        console.log('_pedidoComprovante adicionado')
                                                       // console.log(_pedidoComprovante)        
                                                }
                                        });

                                        this.LinkPagamento = response.data.url;
                            });
                }
                else if (this.formaPgtoSelected == "CREDIT_CARD"){
                    let linkCartao =  {
                        billingType: 'CREDIT_CARD',
                        chargeType: 'INSTALLMENT',
                        name: 'Contratação de Serviços ' + this.DadosGarantidora[0].itiNome + ' - Nº: ' + this.pedidoId,
                        description: "Contratação de Garantia de locação contrato " + this.pedidoId,
                        endDate: moment().add(3,'days').format('YYYY-MM-DD'),
                        value: this.valorProd,
                        maxInstallmentCount: parseInt(this.DadosConfiguracao[0].itiCampoExtra2),
                        dueDateLimitDays: 3,
                        callback: {successUrl: 'https://www.aquigarante.com.br/successpgto'},
                    }

                    await axios.post(`/Process/PgtoAsaas/payament`, linkCartao)
                        .then(response => {
                               // console.log('pagamento pix'); 
                              //  console.log(response.data); 

                                let _dadosSituacaoPix = {
                                    PspId: 7,
                                    PedSituacaoPedido: "Aguardando Pagamento",
                                    PedObsCliente: JSON.stringify(response.data),
                                    PedCodigoRetornoPgto: response.data.id,
                                    PedQtdeParcelas: 0,
                                    PedValorParcelas: 0,
                                    PedId: parseInt(this.pedidoId),
                                }

                                axios.put(`/Process/PedPedidos/UpdateSituacaoAsaas`, _dadosSituacaoPix)
                                    .then(response => {
                                        if (response.status == 200) {
                                            console.log('Update Situacao')
                                        }
                                    });
                                        
                                    let _pedidoComprovante = {
                                            PpcId: 0,
                                            GrtId: 7,
                                            CdtId: 28,
                                            IdiId: 1,
                                            PpcUrlImagem:  response.data.url,
                                            PedId: parseInt(this.pedidoId),
                                        }

                                        axios.post(`/Process/PedPedidoPgtoComprova/Add`, _pedidoComprovante)
                                            .then(response => {
                                                if (response.data > 0) {   
                                                        console.log('_pedidoComprovante adicionado')
                                                       // console.log(_pedidoComprovante)          
                                                }
                                        });

                            this.LinkPagamento = response.data.url;
                    });

                } 
                else if (this.formaPgtoSelected == "RECURRENT"){

                const linkRecorrente = ({
                                billingType: 'CREDIT_CARD',
                                chargeType: 'RECURRENT',
                                callback: {successUrl: 'https://www.aquigarante.com.br/successpgto'},
                                name: 'Contratação de Serviços ' + this.DadosGarantidora[0].itiNome + ' - Nº: ' + this.pedidoId,
                                description: "Contratação de Garantia de locação contrato " + this.pedidoId,
                                endDate: moment().add(3,'days'),
                                value: this.valorProd/12,
                                subscriptionCycle: 'MONTHLY'
                            })

                        await axios.post(`/Process/PgtoAsaas/payament`, linkRecorrente)
                                .then(response => {
                                       // console.log('pagamento pix'); 
                                       // console.log(response.data); 

                                        let _dadosSituacaoPix = {
                                            PspId: 7,
                                            PedSituacaoPedido: "Aguardando Pagamento",
                                            PedObsCliente: JSON.stringify(response.data),
                                            PedCodigoRetornoPgto: response.data.id,
                                            PedQtdeParcelas: 12,
                                            PedValorParcelas: linkRecorrente.value,
                                            PedId: parseInt(this.pedidoId),
                                        }

                                        axios.put(`/Process/PedPedidos/UpdateSituacaoAsaas`, _dadosSituacaoPix)
                                            .then(response => {
                                                if (response.status == 200) {
                                                    console.log('Update Situacao')
                                                    
                                                }
                                            });
                                                
                                            let _pedidoComprovante = {
                                                    PpcId: 0,
                                                    GrtId: 7,
                                                    CdtId: 28,
                                                    IdiId: 1,
                                                    PpcUrlImagem:  response.data.url,
                                                    PedId: parseInt(this.pedidoId),
                                                }

                                            axios.post(`/Process/PedPedidoPgtoComprova/Add`, _pedidoComprovante)
                                                .then(response => {
                                                    if (response.data > 0) {   
                                                            console.log('_pedidoComprovante adicionado')
                                                          //  console.log(_pedidoComprovante)          
                                                    }
                                            });

                                            this.LinkPagamento = response.data.url;
                                });
                        
                } 
                }
                else{
                    this.$mensagemAviso("Essa contratação já esta vigente.") 
                }
          }
    },
  },
  async created() {
     this.TextoInfo = "";
      //verifica se tem algum contrato de aceite pendente
     await axios.get(`/Process/CliEntidadesContratos/GetAll`)
        .then(response => {
            this.ContEnts = response.data.filter(x=> x.cteEntidadeCod == 69);
            if (this.ContEnts!=''){
                  axios.get(`/Process/CliClientesContratos/GetContratosByCli/`+ this.clienteId + '/' + this.imobiliariaId)
                    .then(response => {
                        this.ListContratos = response.data; 
                       // console.log('this.ListContratos')    
                       // console.log(this.ListContratos)
                        if (this.ListContratos.length > 0){
                          let pendencia = false;
                          this.ContEnts.forEach(element => {
                            let result = this.ListContratos.filter(w=> w.ctrContratoCod == element.cteModeloContratoCod);
                            if (result.length > 0)
                            {
                              if (result.ctrAceiteIp == ''){
                                pendencia = true;
                              }
                            }
                            else{
                              pendencia = true;
                            }
                          });
                          if (pendencia){
                            this.$router.push('/AceiteCliente/' + this.clienteId + '/' + this.imobiliariaId + '/' + this.pedidoId);
                          }
                        } 
                        else{
                          this.$router.push('/AceiteCliente/' + this.clienteId + '/' + this.imobiliariaId + '/' + this.pedidoId)
                        }                      
                    });
            }
        });

      //dados da Garantidora
      await axios.get('/Process/InsInstitucional/institucional/34/informacoes')
                .then(response => {
                    this.DadosGarantidora = response.data;
                   // console.log('this.DadosGarantidora')
                            //console.log(this.DadosGarantidora)

                })
                .catch(error => {
                    console.error(error);
                });

            //dados da contratação
            await axios.get(`/Process/PedPedidos/GetById/` + this.pedidoId)
                        .then(response => {
                            this.PedidosCli = response.data;
                           // console.log('this.PedidosCli')
                           // console.log(this.PedidosCli)

                            axios.get(`/Process/CliClientes/GetById/` + this.clienteId)
                                .then(response => {
                                    this.Cliente = response.data;
                                   //console.log('this.Cliente')
                                   //console.log(this.Cliente)
                                });

                            axios.get(`/Process/CliClientes/GetById/` + this.imobiliariaId)
                                .then(response => {
                                    this.Imobiliaria = response.data;
                                   // console.log('this.Imobiliaria')
                                   // console.log(this.Imobiliaria)
                                });

                        });


         await axios.get('/Process/PedPedidoEndereco/GetAllByPedId/' + this.pedidoId)
                .then(response => {
                    this.PedidosEndereco = response.data;
                   // console.log('PedidosEndereco')
                   // console.log(this.PedidosEndereco)
                })
                .catch(error => {
                    console.error(error);
                });

            await axios.get('/Process/PedPedidoItem/GetAllByPedId/' + this.pedidoId)
              .then(response => {
                this.ListaPedidoItem = response.data;

                this.ListaPedidoItem = this.ListaPedidoItem.map(produto => ({
                    ...produto,
                    pdiDataVigenciaIni: produto.pdiDataVigenciaIni.split('T')[0],
                    pdiDataVigenciaFim: produto.pdiDataVigenciaFim.split('T')[0]
                }));

                console.log('ListaPedidoItem')
                console.log(this.ListaPedidoItem)

                this.ListaPedidoItem.forEach(element => {
                        if (element.proId == 161 || element.proId == 162){
                            this.valorProd += element.pdiValorTotalProduto;
                        }
                    })

                    axios.get('/Process/PedPedidoItemAdicional/GetAllByPedId/' + this.pedidoId)
                        .then(response => {
                            this.ListaPedidoItemAdicional = response.data;
                            console.log('ListaPedidoItemAdicional')
                            console.log(this.ListaPedidoItemAdicional)
                            this.ListaPedidoItemAdicional.forEach(element => {
                                      this.valorProdAdicional += element.piaValorProduto;
                              })
                        })

            //planos Garantias
            axios.get('/Process/InsInstitucional/institucional/32/planos')
                    .then(response => {
                        this.PlanosGarantias = response.data;
                        //console.log('this.PlanosGarantias')
                        //console.log(this.PlanosGarantias)
                    })
                    .catch(error => {
                        console.error(error);
                    });

                axios.get(`/Process/CliClientesEndereco/GetEnderecoByCli/`+ this.imobiliariaId + '/0')
                  .then(response => {
                      this.ListEnderecoImob = response.data;                             
                      if (this.ListEnderecoImob != ''){
                          this.EnderecoImob = this.ListEnderecoImob[0];
                          this.dialogImobiliaria = true;
                         // console.log('this.EnderecoImob')
                         // console.log(this.EnderecoImob)
                      }            
                  });

          });

        
          await axios.get('/Process/InsInstitucional/institucional/35/configuracoes')
              .then(response => {
                  this.DadosConfiguracao = response.data;
                  this.FormasPagamento = [];
                  if (this.PedidosCli.pedScore > parseInt(this.DadosConfiguracao[0].itiNome)) {
                      // cartao , pix ou recorrencia
                      if ((this.valorProd/parseInt(this.DadosConfiguracao[0].itiCampoExtra2)) > parseFloat(this.DadosConfiguracao[0].itiCampoExtra1) )
                      {
                        this.FormasPagamento.push({ nome: 'PIX', valor: 'PIX'})
                        this.FormasPagamento.push({ nome: 'Cartão de Crédito', valor: 'CREDIT_CARD'})
                      }
                      else{
                        this.FormasPagamento.push({ nome: 'PIX', valor: 'PIX'})
                        this.FormasPagamento.push({ nome: 'Cartão de Crédito', valor: 'CREDIT_CARD'})
                        this.FormasPagamento.push({ nome: 'Cartão de Crédito Recorrente', valor: 'RECURRENT'})
                      }

                  } else if (this.PedidosCli.pedScore > parseInt(this.DadosConfiguracao[0].itiReferencia) && this.PedidosCli.pedScore < parseInt(this.DadosConfiguracao[0].itiNome)) {
                      //cartao e pix
                      this.FormasPagamento.push({ nome: 'PIX', valor: 'PIX'})
                      this.FormasPagamento.push({ nome: 'Cartão de Crédito', valor: 'CREDIT_CARD'})
                  }
                  else{
                    this.FormasPagamento.push({ nome: 'PIX', valor: 'PIX'})
                    this.FormasPagamento.push({ nome: 'Cartão de Crédito', valor: 'CREDIT_CARD'})
                  }

                  //console.log('this.DadosConfiguracao')
                 //console.log(this.DadosConfiguracao)

                 // console.log('this.FormasPagamento')
                  //console.log(this.FormasPagamento)
              })
              .catch(error => {
                  console.error(error);
              });


  }
}
</script>

<style>

</style>
