<template>
       <div>
        <div class="pd-ltr-20">
           <div class="faq-wrap">
                <div id="accordion">
                    <div class="card-box pd-20 height-100-p mb-30">
                        <div id="Topo" class="collapse show">
                            <div class="card-body">
                                <div class="row align-items-center" v-if="DadosGarantidora!=''">
                                    <div class="col-md-12">
                                        <h4 class="font-20 weight-500 mb-10 text-capitalize">
                                            Olá, <div class="weight-600 font-30 text-blue">{{  User.nome }}</div>
                                        </h4>
                                        <p class="font-18 max-width-600">Termos Aceite Contrato Imobiliárias {{ DadosGarantidora[0].itiNome }}</p>

                                    <div style="overflow-y: auto; height:450px; border: 1px solid #ccc; padding: 10px;">
                                      <p v-html="Contrato.itiResumo1"></p>
                                      <p v-html="Contrato.itiResumo2"></p>
                                      <p v-html="Contrato.itiDescricao1"></p>
                                      <p v-html="Contrato.itiDescricao2"></p>
                                      <p v-html="Contrato.itiDescricao3"></p>
                                      <p v-html="Contrato.itiDescricao4"></p>
                                     </div>
                                     <div style="padding-top: 30px;">
                                       <a :loading="isLoading" class="btn btn-primary btn-lg btn-block" href="#" @click.prevent="AceiteContrato()">Aceitar</a>
                                    </div>
                                    <div style="padding-top: 30px;text-align: center;">
                                      <a href="#" @click="CancelarContrato()">Cancelar</a>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
           </div>
        </div>
        
      </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'AceiteImobiliariaView',
  components: {
    moment
  },
  data: () => ({
    accordion: false,
    User:{},
    ListContratos:[],
    DadosGarantidora:[],
    ContEnts:[],
    Contrato:{},
    //contratoscliente
    ContratoAceite:{
      ctrId: 0,
      ctrContratoCod: '',
      ctrContratoModelo: '',
      ctrContratoTexto: '',
      ctrDataCadastro: '',
      ctrUrlContrato: '',
      cliId: '',
      ctrAceiteIP: '',
      ctrAceiteData: '',
    }
  }),
  methods: {
    AceiteContrato(){
      this.ContratoAceite.ctrContratoCod = this.ContEnts[0].cteModeloContratoCod;
      this.ContratoAceite.ctrContratoModelo = this.ContEnts[0].cteTipoContratoCod;
      this.ContratoAceite.ctrContratoTexto = this.Contrato.itiResumo1 + "</br> " + this.Contrato.itiResumo2 + "</br> " + this.Contrato.itiDescricao1 + "</br> " + this.Contrato.itiDescricao2 + "</br> " + this.Contrato.itiDescricao3 + "</br> " + this.Contrato.itiDescricao4;
      this.ContratoAceite.ctrDataCadastro = moment().format('YYYY-MM-DD');
      this.ContratoAceite.ctrUrlContrato = '';
      this.ContratoAceite.cliId = this.User.cliId;
      this.ContratoAceite.ctrAceiteData = moment().format('YYYY-MM-DD');

      //console.log('this.ContratoAceite')
      //console.log(this.ContratoAceite)

      axios.post(`/Process/CliClientesContratos/Add`, this.ContratoAceite)
            .then(response => {
                if (response.data > 0) {
                    this.$mensagemAvisoSucesso("Aceite Registrado.")
                    this.$router.push('/Home')
                }
            });

    },
    CancelarContrato(){
      this.$Cookies.remove("_vusavImob");
      localStorage.removeItem('_useravImob');
      this.$router.push('/');
    },
  },
  async created() {
    const valorArmazenado = localStorage.getItem('_useravImob');
    if (valorArmazenado != '') {
      this.User = JSON.parse(valorArmazenado);
    }

    await axios.get('/Process/InsInstitucional/institucional/31/modeloscontratos')
            .then(response => {
                this.ListContratos = response.data
            })
            .catch(error => {
                console.error(error);
            });

        await axios.get(`/Process/CliEntidadesContratos/GetAll`)
              .then(response => {
                  this.ContEnts = response.data.filter(x=> x.cteEntidadeCod == 67);
                  if (this.ContEnts!=''){
                    this.Contrato = this.ListContratos.filter(x=> x.itiId == this.ContEnts[0].cteModeloContratoCod)[0]
                  }
              });

          //dados da Garantidora
      axios.get('/Process/InsInstitucional/institucional/34/informacoes')
                .then(response => {
                    this.DadosGarantidora = response.data;
                   // console.log('this.DadosGarantidora')
                   // console.log(this.DadosGarantidora)
                })
                .catch(error => {
                    console.error(error);
                });
  
  },
}
</script>

<style>

</style>
