<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                        <v-tab v-if="mostraClientesCupons" value="clientes" class="tabinfo">Por Clientes</v-tab>
                        <v-tab v-if="mostraSegmentosCupons" value="segmentos" class="tabinfo">Por Segmentos</v-tab>
                        <v-tab v-if="mostraProdutosCupons" value="produtos" class="tabinfo">Por Produtos</v-tab>
                     </v-tabs>
                     <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Cupons.cupId" label="Id" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Cupons.grtId" label="Grupo" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Cupons.cdtId" label="Tela" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="vshowidioma">
                                            <v-select v-model="Cupons.idiId" label="Idioma" :items="Idiomas" @update:modelValue="CarregaLista(Cupons.idiId)"
                                                item-title="idiNome" item-value="idiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="Cupons.cupCodigo"
                                                label="Código" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="Cupons.cupAtivo" label="Ativo" :items="StatusAtivo"
                                                item-title="nome" item-value="cupAtivo" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="Cupons.cupTipoValor" label="Tipo" :items="StatusTipoValor"
                                                item-title="nome" item-value="cupTipoValor" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Cupons.cupValor" type="number"
                                            @change="Cupons.cupValor != null || Cupons.cupValor != '' ? (Cupons.cupValor = parseFloat(Cupons.cupValor,10)) : (Cupons.cupValor = 0.0)"
                                                label="Valor" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="Cupons.cupDescFrete" label="Desc Frete" :items="StatusDescFrete"
                                                item-title="nome" item-value="cupDescFrete" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="Cupons.cupDescPromo" label="Desc Promoção" :items="StatusDescPromo"
                                                item-title="nome" item-value="cupDescPromo" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Cupons.cupDataInicial" type="date"
                                                label="Data Inicial" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Cupons.cupDataFinal" type="date"
                                                label="Data Final" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="Cupons.cupQtdeUso" label="Quantidade" type="number"
                                                variant="underlined"></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                         <!--Grids Por Cliente-->
                         <v-window-item value="clientes" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Por clientes</div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                    <v-col cols="12" md="4" v-show="false">
                                        <v-text-field v-model="CuponsCliente.uclId" label="Id" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="CuponsCliente.cupId" label="Id" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="9">
                                        <v-select v-model="CuponsCliente.cliId" label="Clientes" :items="ListaClientes"
                                            item-title="cliNomeEmpresa" item-value="cliId" variant="underlined">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <button type="button" class="btn btn-success" @click="AddClienteCupom()">
                                            Adicionar
                                        </button>
                                    </v-col>
                                    </v-row>
                                </v-container>
                                <v-form>
                                    <v-container>
                                        <v-row>
                                            <v-table>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Cód. Cliente
                                                    </th>
                                                    <th class="text-left">
                                                       Nome Cliente
                                                    </th>
                                                    <th class="text-center">
                                                       Actions
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in ClientesItens" :key="item.uclId">
                                                    <td>{{ item.cliId }}</td>
                                                    <td>{{ ListaClientes.filter(x=> x.cliId == item.cliId)[0].cliNomeEmpresa }}</td>
                                                    <td style="text-align:center;">  
                                                        <v-btn icon @click.prevent="DeleteClienteCupom(item)" variant="text">
                                                            <v-icon>mdi-delete-forever-outline</v-icon>
                                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </v-table>
                                        </v-row>
                                    </v-container>
                                </v-form>

                            </v-form>
                        </v-window-item>
                          <!--Grids Por Segmentos-->
                          <v-window-item value="segmentos" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Por segmentos</div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                    <v-col cols="12" md="4" v-show="false">
                                        <v-text-field v-model="CuponsSegmentos.useId" label="Id" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="CuponsSegmentos.cupId" label="Id" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="9">
                                        <v-select v-model="CuponsSegmentos.psgId" label="Segmentos" :items="ListaSegmentosPai"
                                            item-title="psgNomeSegmento" item-value="psgId" variant="underlined">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <button type="button" class="btn btn-success" @click="AddSegmentoCupom()">
                                            Adicionar
                                        </button>
                                    </v-col>
                                    </v-row>
                                </v-container>
                                <v-form>
                                    <v-container>
                                        <v-row>
                                            <v-table>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">
                                                       Cód. Segmento
                                                    </th>
                                                    <th class="text-left">
                                                       Nome Segmento
                                                    </th>
                                                    <th class="text-center">
                                                       Actions
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in SegmentosItens" :key="item.useId">
                                                    <td>{{ item.psgId }}</td>
                                                    <td>{{ ListaSegmentosPai.filter(x=> x.psgId == item.psgId)[0].psgNomeSegmento }}</td>
                                                    <td style="text-align:center;">  
                                                        <v-btn icon @click.prevent="DeleteSegmentoCupom(item)" variant="text">
                                                            <v-icon>mdi-delete-forever-outline</v-icon>
                                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </v-table>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-form>
                        </v-window-item>
                         <!--Grids Por Cliente-->
                         <v-window-item value="produtos" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Por produtos</div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                    <v-col cols="12" md="4" v-show="false">
                                        <v-text-field v-model="CuponsProdutos.uprId" label="Id" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="CuponsProdutos.cupId" label="Id" variant="underlined"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" md="9">
                                        <v-select v-model="CuponsProdutos.proId" label="Produtos" :items="ListaProdutos"
                                            item-title="proNomeProduto" item-value="proId" variant="underlined">
                                        </v-select>
                                    </v-col>
                                    <v-col cols="12" md="3">
                                        <button type="button" class="btn btn-success" @click="AddProdutosCupom()">
                                            Adicionar
                                        </button>
                                    </v-col>
                                    </v-row>
                                </v-container>
                                <v-form>
                                    <v-container>
                                        <v-row>
                                            <v-table>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">
                                                        Cód. Produto
                                                    </th>
                                                    <th class="text-left">
                                                       Nome Produto
                                                    </th>
                                                    <th class="text-center">
                                                       Actions
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in ProdutosItens" :key="item.uprId">
                                                    <td>{{ item.proId }}</td>
                                                    <td>{{ ListaProdutos.filter(x=> x.proId == item.proId)[0].proNomeProduto }}</td>
                                                    <td style="text-align:center;">  
                                                        <v-btn icon @click.prevent="DeleteProdutosCupom(item)" variant="text">
                                                            <v-icon>mdi-delete-forever-outline</v-icon>
                                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </v-table>
                                        </v-row>
                                    </v-container>
                                </v-form>

                            </v-form>
                        </v-window-item>
                    </v-window>
                
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="Cupons.cupId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="" v-if="MostraGrid && tab=='dados'">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="ListCupons" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-cupAtivo="item">
                            <span>{{ item.cupAtivo ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-cupValor="item">
                            {{ item.cupValor !== null ? formatarValor(item.cupValor) : 0 }}
                        </template>
                        <template #item-cupDataInicial="item">
                            <span>{{ item.cupDataInicial!=null? formattedDate(item.cupDataInicial) : null }}</span>
                        </template>
                        <template #item-cupDataFinal="item">
                            <span>{{ item.cupDataFinal!=null? formattedDate(item.cupDataFinal) : null }}</span>
                        </template>
                        <template #item-cupTipoValor="item">
                            <span>{{ item.cupTipoValor==1? 'Valor' : 'Percentual' }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'

export default {
    name: 'CupCuponsView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Cadastro Cupons de Descontos',
        InfoTela:'',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 18, cdtNome: "Cupons Desconto" }],
        //dados
        Cupons: {
            cupId: 0,
            grtId: 0,
            cdtId: 0,
            idiId: '',
            cupCodigo: '',
            cupAtivo: '',
            cupTipoValor: '',
            cupValor: '',
            cupDescFrete: '',
            cupDescPromo: '',
            cupDataInicial: '',
            cupDataFinal: '',
            cupQtdeUso: '',
        },
        ListCupons: [],
        StatusAtivo: [{ nome: "Sim", cupAtivo: true }, { nome: "Não", cupAtivo: false }],
        StatusTipoValor: [{ nome: "Percentual", cupTipoValor: 0 }, { nome: "Valor", cupTipoValor: 1 }],
        StatusDescFrete: [{ nome: "Sim", cupDescFrete: true }, { nome: "Não", cupDescFrete: false }],
        StatusDescPromo: [{ nome: "Sim", cupDescPromo: true }, { nome: "Não", cupDescPromo: false }],
        //dados
        //Grids Cliente
        CuponsCliente:{
            uclId:0,
            cupId:'',
            cliId:'',
        },
        ClientesItens:[],
        ListaClientes:[],
        mostraClientesCupons: false,
        //Grids Cliente
        //Grids Segmentos
         CuponsSegmentos:{
            useId:0,
            cupId:'',
            psgId:'',
        },
        SegmentosItens:[],
        mostraSegmentosCupons: false,
        ListSegmentosProdGeral:[],
        ListaSegmentosPai:[],
        ListSegmentosProd:[],
        //Grids Segmentos
         //Grids Produtos
         CuponsProdutos:{
            uprId:0,
            cupId:'',
            proId:'',
        },
        ProdutosItens:[],
        ListaProdutos:[],
        mostraProdutosCupons: false,
        //Grids Produtos
        ListCuponsGeral:[],
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListCupons = this.ListCuponsGeral.filter(x=> x.idiId == this.Cupons.idiId);
        },
        Limpar() {
            this.Cupons.cupId=0;
            this.Cupons.grtId=0;
            this.Cupons.cdtId=0;
            this.Cupons.cupCodigo="";
            this.Cupons.cupAtivo="";
            this.Cupons.cupTipoValor="";
            this.Cupons.cupValor="";
            this.Cupons.cupDescFrete="";
            this.Cupons.cupDescPromo="";
            this.Cupons.cupDataInicial="";
            this.Cupons.cupDataFinal="";
            this.Cupons.cupQtdeUso="";
            this.ClientesItens = [];
            this.mostraClientesCupons = false;  
            this.mostraSegmentosCupons = false;
            this.mostraProdutosCupons = false;
            this.tab = 'dados';
            this.Get();
        },
        Add() {
               if (this.Cupons.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.Cupons.cupCodigo == '') {
                    this.$mensagemAviso("Campo Código Obrigatório.")
                }
                else if (this.Cupons.cupAtivo === '') {
                    this.$mensagemAviso("Campo Ativo Obrigatório.")
                }
                else if (this.Cupons.cupTipoValor === '') {
                    this.$mensagemAviso("Campo Tipo Valor Obrigatório.")
                }
                else if (this.Cupons.cupValor === '') {
                    this.$mensagemAviso("Campo Valor Obrigatório.")
                }
                else if (this.Cupons.cupDataInicial === '') {
                    this.$mensagemAviso("Campo Data Inicial Obrigatório.")
                }
                else if (this.Cupons.cupDataFinal === '') {
                    this.$mensagemAviso("Campo Data Final Obrigatório.")
                }
                else if (this.Cupons.cupQtdeUso === '') {
                    this.$mensagemAviso("Campo Quantidade Obrigatório.")
                }
                else if (this.Cupons.cupDescFrete === '') {
                    this.$mensagemAviso("Campo Descontar Frete Obrigatório.")
                }
                else if (this.Cupons.cupDescPromo === '') {
                    this.$mensagemAviso("Campo Descontar Promoções Obrigatório.")
                }
                else {
                    if (this.Cupons.cupAtivo === "" || this.Cupons.cupAtivo === null) {
                        this.Cupons.cupAtivo = true;
                    }
                   
                    if (this.Cupons.cupValor === "" || this.Cupons.cupValor === null) {
                        this.Cupons.cupValor = 0;
                    }
                    else {
                        this.Cupons.cupValor = parseFloat(this.Cupons.cupValor);
                    }

                    if (this.Cupons.cupDataInicial === "" || this.Cupons.cupDataInicial === null ) {
                        this.Cupons.cupDataInicial = this.$moment().format('YYYY-MM-DD')
                    }
                    else{
                        this.Cupons.cupDataInicial = this.$moment(this.Cupons.cupDataInicial).format('YYYY-MM-DD')
                    }

                    if (this.Cupons.cupDataFinal === "" || this.Cupons.cupDataFinal === null ) {
                        this.Cupons.cupDataFinal = null;
                    }
                    else{
                        this.Cupons.cupDataFinal = this.$moment(this.Cupons.cupDataFinal).format('YYYY-MM-DD')
                    }

                    if (this.Cupons.cupQtdeUso === "" || this.Cupons.cupQtdeUso === null) {
                        this.Cupons.cupQtdeUso = 0;
                    }
                    else {
                        this.Cupons.cupQtdeUso = parseInt(this.Cupons.cupQtdeUso);
                    }

                    this.Cupons.admGrupoTelas = { GrtId: this.Cupons.grtId }
                    this.Cupons.admCadastroTelas = { CdtId: this.Cupons.cdtId }
                    this.Cupons.cadIdiomas = { IdiId: this.Cupons.idiId }
                 
                    axios.post(`/Process/CupCupons/Add`, this.Cupons)
                        .then(response => {
                            if (response.data > 0) {
                                    this.Cupons.cupId = response.data;
                                    this.Get();
                                    this.EditGrid(this.Cupons);
                                    this.$mensagemAvisoSucesso("Registro Incluído. abas detalhes liberadas.")

                                   //this.$mensagemAvisoSucesso("Registro Incluído.")
                                   //this.Get();
                                   //this.Limpar();
                                  // this.tab = 'dados';                        
                            }
                        });
            }
        },
        Put() {
            if (this.Cupons.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.Cupons.cupCodigo == '') {
                    this.$mensagemAviso("Campo Código Obrigatório.")
                }
                else if (this.Cupons.cupAtivo === '') {
                    this.$mensagemAviso("Campo Ativo Obrigatório.")
                }
                else if (this.Cupons.cupTipoValor === '') {
                    this.$mensagemAviso("Campo Tipo Valor Obrigatório.")
                }
                else if (this.Cupons.cupValor === '') {
                    this.$mensagemAviso("Campo Valor Obrigatório.")
                }
                else if (this.Cupons.cupDataInicial === '') {
                    this.$mensagemAviso("Campo Data Inicial Obrigatório.")
                }
                else if (this.Cupons.cupDataFinal === '') {
                    this.$mensagemAviso("Campo Data Final Obrigatório.")
                }
                else if (this.Cupons.cupQtdeUso === '') {
                    this.$mensagemAviso("Campo Quantidade Obrigatório.")
                }
                else if (this.Cupons.cupDescFrete === '') {
                    this.$mensagemAviso("Campo Descontar Frete Obrigatório.")
                }
                else if (this.Cupons.cupDescPromo === '') {
                    this.$mensagemAviso("Campo Descontar Promoções Obrigatório.")
                }
            else {
                if (this.Cupons.cupAtivo === "" || this.Cupons.cupAtivo === null) {
                        this.Cupons.cupAtivo = true;
                    }
                   
                    if (this.Cupons.cupValor === "" || this.Cupons.cupValor === null) {
                        this.Cupons.cupValor = 0;
                    }
                    else {
                        this.Cupons.cupValor = parseFloat(this.Cupons.cupValor);
                    }

                    if (this.Cupons.cupDataInicial === "" || this.Cupons.cupDataInicial === null ) {
                        this.Clientes.cupDataInicial = this.$moment().format('YYYY-MM-DD')
                    }
                    else{
                        this.Cupons.cupDataInicial = this.$moment(this.Cupons.cupDataInicial).format('YYYY-MM-DD')
                    }

                    if (this.Cupons.cupDataFinal === "" || this.Cupons.cupDataFinal === null ) {
                        this.Cupons.cupDataFinal = null;
                    }
                    else{
                        this.Cupons.cupDataFinal = this.$moment(this.Cupons.cupDataFinal).format('YYYY-MM-DD')
                    }
                    if (this.Cupons.cupQtdeUso === "" || this.Cupons.cupQtdeUso === null) {
                        this.Cupons.cupQtdeUso = 0;
                    }
                    else {
                        this.Cupons.cupQtdeUso = parseInt(this.Cupons.cupQtdeUso);
                    }

                    this.Cupons.admGrupoTelas = { GrtId: this.Cupons.grtId }
                    this.Cupons.admCadastroTelas = { CdtId: this.Cupons.cdtId }
                    this.Cupons.cadIdiomas = { IdiId: this.Cupons.idiId }

             
                axios.put(`/Process/CupCupons/Update`, this.Cupons)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                            this.tab = 'dados';
                        }
                    });
            }
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.cupId;
                axios.delete(`/Process/CupCupons/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                        this.tab = 'dados';
                    }
                });
            }
            });
        },
        EditGrid(item) {
            this.tab = 'dados';
            // Lógica para editar o item   
            if (item.cupDataInicial != "" && item.cupDataInicial != null) {
                item.cupDataInicial = this.$moment(item.cupDataInicial).format('YYYY-MM-DD')
            }
            else{
                item.cupDataInicial = '';
            }     
            if (item.cupDataFinal != "" && item.cupDataFinal != null) {
                item.cupDataFinal = this.$moment(item.cupDataFinal).format('YYYY-MM-DD')
            }
            else{
                item.cupDataFinal = '';
            }    

            this.Cupons = item;
           
            //carregar clientes
            axios.get(`/Process/CliClientes/GetAll`)
                .then(response => {
                    this.ListaClientes = response.data.filter(x=>x.cliVisivel);  
                    this.mostraClientesCupons = true;                      
                });

            axios.get(`/Process/CupCuponsClientes/GetAllByCupId/${this.Cupons.cupId}/0`)
                .then(response => {
                    this.ClientesItens = response.data;                   
                });
           //carregar clientes
           //carregar segmentos
           this.CarregaListaSegmentos();

           //carregar clientes por cupom
           axios.get(`/Process/CupCuponsSegmentos/GetAllByCupId/${this.Cupons.cupId}/0`)
                .then(response => {
                    this.SegmentosItens = response.data;                         
                });
           this.mostraSegmentosCupons = true;

             //carregar segmentos
              //carregar Produtos
            axios.get(`/Process/ProProdutos/GetAll`)
                .then(response => {
                    this.ListaProdutos = response.data.filter(x=>x.proVisivel && x.idiId == this.Cupons.idiId);  
                    this.mostraProdutosCupons = true;                      
                });

            axios.get(`/Process/CupCuponsProdutos/GetAllByCupId/${this.Cupons.cupId}/0`)
                .then(response => {
                    this.ProdutosItens = response.data;                   
                });
           //carregar Produtos

            this.$scrollToTop();
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "cupId", sortable: true, });
            this.headers.push({ text: "Código", value: "cupCodigo", sortable: true, });
            this.headers.push({ text: "Ativo", value: "cupAtivo", sortable: true, });
            this.headers.push({ text: "Tipo", value: "cupTipoValor", sortable: true, });
            this.headers.push({ text: "Valor", value: "cupValor", sortable: true, });
            this.headers.push({ text: "Inicial", value: "cupDataInicial", sortable: true, });
            this.headers.push({ text: "Final", value: "cupDataFinal", sortable: true, });     
            this.headers.push({ text: "Qtde", value: "cupQtdeUso", sortable: true, });     
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        AddClienteCupom(){
            if (this.CuponsCliente.cliId == '') {
                this.$mensagemAviso("Campo Cliente Obrigatório.")
            }
            else {
                var validexits = this.ClientesItens.filter(x=> x.cliId == this.CuponsCliente.cliId && x.cupId == this.Cupons.cupId);
                if (validexits.length == 0){
                this.CuponsCliente.cupId = this.Cupons.cupId
                axios.post(`/Process/CupCuponsClientes/Add`, this.CuponsCliente)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                //carregar clientes por cupom
                                axios.get(`/Process/CupCuponsClientes/GetAllByCupId/${this.Cupons.cupId}/0`)
                                    .then(response => {
                                        this.ClientesItens = response.data;                         
                                    });
                            }
                            else{
                                this.$mensagemAviso("Não foi possível incluir registro.")
                            }
                        });
                    }
                    else{
                        this.$mensagemAviso("Registro Existente.")
                    }
            }
        },
        DeleteClienteCupom(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.uclId;
                axios.delete(`/Process/CupCuponsClientes/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                       //carregar clientes por cupom
                        axios.get(`/Process/CupCuponsClientes/GetAllByCupId/${this.Cupons.cupId}/0`)
                        .then(response => {
                            this.ClientesItens = response.data;                         
                        });
                    }
                });
            }
            });
        },
        AddSegmentoCupom(){
            if (this.CuponsSegmentos.psgId == '') {
                this.$mensagemAviso("Campo Segmento Obrigatório.")
            }
            else {
                var validexits = this.SegmentosItens.filter(x=> x.psgId == this.CuponsSegmentos.psgId && x.cupId == this.Cupons.cupId);
                if (validexits.length == 0){
                    this.CuponsSegmentos.cupId = this.Cupons.cupId
                     axios.post(`/Process/CupCuponsSegmentos/Add`, this.CuponsSegmentos)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.CuponsSegmentos.psgId = "";
                                //carregar clientes por cupom
                                axios.get(`/Process/CupCuponsSegmentos/GetAllByCupId/${this.Cupons.cupId}/0`)
                                    .then(response => {
                                        this.SegmentosItens = response.data;                         
                                    });
                            }
                            else{
                                this.$mensagemAviso("Não foi possível incluir registro.")
                            }
                        });
                }
                else{
                    this.$mensagemAviso("Registro Existente.")
                }


              
            }
        },
        DeleteSegmentoCupom(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.useId;
                axios.delete(`/Process/CupCuponsSegmentos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                       //carregar clientes por cupom
                        axios.get(`/Process/CupCuponsSegmentos/GetAllByCupId/${this.Cupons.cupId}/0`)
                        .then(response => {
                            this.SegmentosItens = response.data;                         
                        });
                    }
                });
            }
            });
        },
        CarregaListaSegmentos(){
            axios.get(`/Process/ProSegmentos/GetAll`)
                                .then(response => {
                                    this.ListSegmentosProdGeral = response.data;
                                    this.ListaSegmentosPai = this.ListSegmentosProdGeral.filter(x=> x.psgVisivel && x.idiId == this.Cupons.idiId);
                                    this.ListSegmentosProd = this.ListSegmentosProdGeral.filter(x=> x.idiId == this.Cupons.idiId);
                                    //------ carrega select psgIdPai com hierarquia
                                    function buildHierarchy(segmentos, segmentoIdPai, caminhoAtual = '') {
                                        const segmentosFilhos = segmentos.filter(segmento => segmento.psgIdPai === segmentoIdPai);
                                        const segmentosComCaminho = [];

                                        for (const segmentoFilho of segmentosFilhos) {
                                            const caminhoCompleto = caminhoAtual === '' ? segmentoFilho.psgNomeSegmento : `${caminhoAtual} > ${segmentoFilho.psgNomeSegmento}`;

                                            segmentosComCaminho.push({ psgId: segmentoFilho.psgId, psgNomeSegmento: caminhoCompleto });
                                            
                                            const segmentosNetos = buildHierarchy(segmentos, segmentoFilho.psgId, caminhoCompleto);
                                            segmentosComCaminho.push(...segmentosNetos);
                                        }

                                        return segmentosComCaminho;
                                        }
                                    
                                        const segmentosComCaminho = [];
                                        for (const segmento of this.ListaSegmentosPai.filter(x=> x.psgIdPai== 0)) {
                                        segmentosComCaminho.push({ psgId: segmento.psgId, psgNomeSegmento: segmento.psgNomeSegmento });
                                        
                                        const segmentosFilhos = buildHierarchy(this.ListaSegmentosPai, segmento.psgId, segmento.psgNomeSegmento);
                                        segmentosComCaminho.push(...segmentosFilhos);
                                        }
                                        this.ListaSegmentosPai = segmentosComCaminho;   
                                        this.ListSegmentosProd =  this.itensComHierarquia(this.ListSegmentosProd);  
                                    //------
                                    });
        },
        itensComHierarquia(itens) {
        // Adicione a lógica para adicionar a coluna "Hierarquia" ao array de dados
        const itensComHierarquia = itens.map((item) => {
            const hierarquia = this.ListaSegmentosPai.filter(
            (x) => x.psgId === item.psgIdPai
            )[0]?.psgNomeSegmento || "";

            return { ...item, Hierarquia: hierarquia };
        });

          return itensComHierarquia;
        },
        AddProdutosCupom(){
            if (this.CuponsProdutos.proId == '') {
                this.$mensagemAviso("Campo Produto Obrigatório.")
            }
            else {
                var validexits = this.ProdutosItens.filter(x=> x.proId == this.CuponsProdutos.proId && x.cupId == this.Cupons.cupId);
                if (validexits.length == 0){
                this.CuponsProdutos.cupId = this.Cupons.cupId;
                axios.post(`/Process/CupCuponsProdutos/Add`, this.CuponsProdutos)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                //carregar clientes por cupom
                                axios.get(`/Process/CupCuponsProdutos/GetAllByCupId/${this.Cupons.cupId}/0`)
                                    .then(response => {
                                        this.ProdutosItens = response.data;                         
                                    });
                            }
                            else{
                                this.$mensagemAviso("Não foi possível incluir registro.")
                            }
                        });
                    }
                    else{
                        this.$mensagemAviso("Registro Existente.")
                    }
            }
        },
        DeleteProdutosCupom(value){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = value.uprId;
                axios.delete(`/Process/CupCuponsProdutos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                       //carregar clientes por cupom
                        axios.get(`/Process/CupCuponsProdutos/GetAllByCupId/${this.Cupons.cupId}/0`)
                        .then(response => {
                            this.ProdutosItens = response.data;                         
                        });
                    }
                });
            }
            });
        },
        Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.Cupons.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }
                    axios.get(`/Process/CupCupons/GetAll`)
                        .then(response => {
                            this.ListCuponsGeral = response.data; 
                            if (this.Cupons.idiId != ''){
                                this.CarregaLista();
                            }                           
                        });
                    this.montaGrid();
                    this.Cupons.cdtId = 18;
                    this.Cupons.grtId = 3;

                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
