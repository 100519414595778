<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;">
                                <h4 class="text-black h4" style="padding-right: 5px;">{{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#00203b">
                        <v-tab value="dados" class="tabinfo" style="color: #ffffff !important;">Detalhe Contratação</v-tab>
                        <v-tab value="documentos" class="tabinfo" style="color: #ffffff !important;" v-if="PedidosCli.pspId == 8">Documentos</v-tab>
                    </v-tabs>
                    <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container v-if="PedidosCli != '' && PedidosEndereco != '' && PlanosGarantias!=''">
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 15px;" color="#f7f7f7">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;font-size: 14px;">Endereço Contratação
                                                            </h5>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;">
                                                                <strong>Endereço:</strong> {{
                                                                                PedidosEndereco[0].pdcEndereco
                                                                            }}, {{ PedidosEndereco[0].pdcNumero }} - {{
                                                                                PedidosEndereco[0].pdcComplemento }}
                                                            </p>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;">
                                                                <strong>Cidade/UF:</strong> {{
                                                                                         PedidosEndereco[0].pdcCidade }} /
                                                                {{ PedidosEndereco[0].pdcEstado }}
                                                            </p>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;">
                                                                <strong>Bairro:</strong> {{ PedidosEndereco[0].pdcBairro
                                                                }}
                                                            </p>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;">
                                                                <strong>CEP:</strong> {{ PedidosEndereco[0].pdcCep }}
                                                            </p>
                                                            <p class="texto-mensagem mb-0" style="color:#333333;">
                                                                <strong>Tipo Imóvel:</strong> {{ PedidosCli.pedTipoFrete
                                                                }} {{ PedidosEndereco[0].pdcResidencialTipo }}
                                                            </p>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="12" md="8">
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 15px;" color="#f7f7f7">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;font-size: 14px;">
                                                                Informações Contratação</h5>
                                                            <h6 class="mb-0">Contratação: {{ PedidosCli.pedId }}</h6>
                                                            <div class="pedido_dados"><strong>Imobiliária:</strong> {{ Imobiliaria.cliNomeEmpresa }} - {{ Imobiliaria.cliEmail }} <v-icon @click="openDialogImobiliaria(Imobiliaria.cliId)">mdi-folder-open</v-icon> </div>
                                                            <div class="pedido_dados"><strong>Cliente:</strong> {{ Cliente.cliNomeEmpresa }} - {{ Cliente.cliEmail }}  <v-icon @click="openDialogCliente(Cliente.cliId)">mdi-folder-open</v-icon></div>
                                                            <div class="pedido_dados"><strong>Data :</strong> {{
                                    formattedDate(PedidosCli.pedDataPedido) }}</div>
                                                            <div class="pedido_dados"
                                                                v-if="PedidosCli.pedFormaPgto == 'payment_card'">
                                                                <strong>Pgto:</strong> Cartão Crédito
                                                            </div>
                                                            <div class="pedido_dados"
                                                                v-else-if="PedidosCli.pedFormaPgto == 'bank_transfer'">
                                                                <strong>Pgto:</strong> PIX
                                                            </div>
                                                            <div class="pedido_dados"
                                                                v-else-if="PedidosCli.pedFormaPgto == 'recorrent_card'">
                                                                <strong>Pgto:</strong> Recorrente
                                                            </div>
                                                            <div class="pedido_dados" v-else><strong>Pgto:</strong> {{
                                    PedidosCli.pedFormaPgto }}</div>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="12">
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 25px;" color="#f9f9f9">
                                                        <div>
                                                            <h5 class="subtitulo" style="padding-bottom: 15px;">Produtos</h5>
                                                            <div v-for="produto in ListaPedidoItem" :key="produto.pdiId"
                                                                class="descricao-pedido border-top py-3">
                                                                <div class="row">
                                                                    <div
                                                                        class="col-3 col-md-2 d-flex align-items-center justify-content-center">
                                                                        <div class="descricao-img">
                                                                            <img v-if="produto.proProdutos.proCampoExtra10 != ''"
                                                                                class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                                :src="$imgURL + produto.proProdutos.proCampoExtra10"
                                                                                alt="">
                                                                            <img v-else
                                                                                class="img-fluid border border-rounded mb-3 mb-md-0"
                                                                                :src="$imgURL + '/imagens/indisponivel.jpg'"
                                                                                alt="">
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        class="col-6 col-md-5 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo">{{produto.pdiNomeProduto}}</h6>
                                                                            <p class="texto-mensagem" style="margin: 0 0 0px"> Código: {{produto.proId }}</p>
                                                                            <p class="texto-mensagem" style="margin: 0 0 0px; display: inline-flex;"> 
                                                                                {{ formattedDate(produto.pdiDataVigenciaIni) }} até {{ formattedDate(produto.pdiDataVigenciaFim) }} 
                                                                            </p>
                                                                            <p class="texto-mensagem" style="margin: 0 0 0px"> Setup: R$ {{ formatarValor(parseFloat(PlanosGarantias.filter(x=> x.itiId == parseInt(produto.pdiCampoExtra1))[0].itiReferencia)) }}</p>
                                                                            <p class="texto-mensagem" style="margin: 0 0 0px"> Taxa: {{produto.pdiCampoExtra2 }}%</p>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-6 col-md-3 d-flex align-items-center">
                                                                        <ul>
                                                                            <li v-for="(sub,index) in ListaPedidoItemAdicional" :key="index" style="font-size: 13px;"> 
                                                                               {{ sub.piaNomeProduto }} - R$ {{ formatarValor(sub.piaValorTotalProduto) }}
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div
                                                                        class="col-6 col-md-2 d-flex align-items-center">
                                                                        <div class="descricao-content">
                                                                            <h6 class="subtitulo"
                                                                                style="font-size: 15px;">
                                                                                R$ {{
                                                                                parseFloat(produto.pdiValorTotalProduto).toFixed(2).toString().replace('.',
                                                                                ',')
                                                                                }}</h6>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="12">
                                                    <v-card style="padding: 25px;" color="#f7f7f7">
                                                        <div>
                                                            <div
                                                                class="d-flex align-items-center justify-content-between">
                                                                <h5 class="subtitulo">Total</h5>
                                                                <h6 class="subtitulo">R$ {{
                                                                    parseFloat(TotalPedido).toFixed(2).toString().replace('.',
                                                                    ',')
                                                                    }}</h6>
                                                            </div>
                                                        </div>
                                                    </v-card>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="5" style="text-align:center;">
                                            <v-card style="padding: 25px;" color="#f7f7f7" v-show="false">
                                                <div>
                                                    <h5 class="subtitulo" style="padding-bottom: 15px;">
                                                        Score Serasa
                                                    </h5>
                                                    <v-row style="padding-top: 40px;justify-content: center;">
                                                        <VueSpeedometer
                                                            :value="score"
                                                            :minValue="0"
                                                            :maxValue="1000"
                                                            segments="10"
                                                            needleColor="steelblue"
                                                            style="height: 200px"
                                                            />
                                                    </v-row>
                                                    <button type="button" class="btn btn-success" @click="dialogSerasa=!dialogSerasa;">
                                                        Informações Complementares
                                                    </button>
                                                </div>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" md="7">
                                            <v-card style="padding: 25px;" color="#f7f7f7">
                                                <div>
                                                    <h5 class="subtitulo" style="font-size:14px;">Situação </h5>
                                                    <v-row style="padding-top: 30px;">
                                                        <v-select v-model="PedidosCli.pspId" label="Situação Atual" disabled="true"
                                                            :items="Situacoes" item-title="pspSituacao"
                                                            item-value="pspId" variant="outlined">
                                                        </v-select>
                                                    </v-row>
                                                    <v-row style="padding-top: 20px">
                                                        <v-textarea cols="12" rows="5" v-model="PedidosCli.pedObsLoja" variant="outlined" disabled clearable label="Observações"></v-textarea>
                                                    </v-row>
                                                    <v-row style="justify-content: center;padding-top: 15px;" v-show="false">
                                                        <button type="button" class="btn btn-warning"
                                                            @click="PutSituacao()">
                                                            Alterar Situação
                                                        </button>
                                                    </v-row>
                                                    <v-row style="justify-content: center;padding-top: 15px;" v-if="PedidosCli.pedCodigoRetornoPgto == '' || PedidosCli.pedCodigoRetornoPgto == null">
                                                        <button type="button" class="btn btn-danger" style="color:#ffffff;"
                                                            @click="CancelarContratacao()">
                                                            Cancelar Contratação
                                                        </button>
                                                    </v-row>
                                                </div>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                </v-container>
                            </v-form>
                        </v-window-item>
                        <v-window-item value="documentos" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Selecione os documento necessários</div>
                            <v-form>
                                <v-form>
                                    <v-container>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="DocSelected" label="Nome" :items="TiposDocumentos" clearable
                                                item-title="pmsNomePara" item-value="pmsNomede" variant="outlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="5">
                                                <v-file-input v-model="selectedFile" label="Selecione Documento" variant="outlined"
                                                   prepend-icon="mdi-image-outline" accept="image/jpeg, image/png, image/jpg, application/pdf"></v-file-input>
                                            </v-col>
                                        <v-col cols="12" md="3">
                                            <button type="button" class="btn btn-info" @click.prevent="EnviarDocumento()" style="color:#ffffff;">
                                                Inserir
                                            </button>
                                        </v-col>
                                    </v-row>
                                        <v-row>
                                            <v-table>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">
                                                       Documento
                                                    </th>
                                                    <th class="text-left">
                                                       Visualização
                                                    </th>
                                                    <th>
                                                        Ações
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody >
                                                <tr v-for="item in ListDocumentos" :key="item.pmsId">
                                                    <td>{{ item.pmsNomePara }}</td>
                                                    <td style="text-align: center;">
                                                         <a v-if="item.pmsUrlImagem!= '' && item.pmsUrlImagem!=null" :href="$imgURL + item.pmsUrlImagem" target="_blank">
                                                            <v-icon style="font-size:35px;">mdi-file-eye</v-icon>
                                                        </a>
                                                    </td>
                                                    <!--td>
                                                        <v-btn icon @click.prevent="DeleteDocumento(item)" variant="text">
                                                            <v-icon>mdi-delete-forever-outline</v-icon>
                                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                        </v-btn>
                                                    </!--td-->
                                                </tr>
                                                </tbody>
                                            </v-table>
                                        </v-row>
                                        <v-row style="padding-top: 40px;justify-content:center;">
                                            <button type="button" class="btn btn-success"
                                                @click="ReenviarAnalise()">
                                                Reenviar para Análise
                                            </button>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-form>
                        </v-window-item>
                    </v-window>
                </div>
            </div>
        </div>
    <!--modal Cliente-->
    <v-row justify="center">
        <v-dialog v-model="dialogCliente" class="controlcelEnd" transition="dialog-bottom-transition"
          width="auto">
            <v-card>
                <v-card-title style="background-color: #cccccc;">
                <span class="text-h5" style="font-size: 14px !important;" >Informações do Cliente</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px">
                    <v-form style="font-size:13px;">
                        <v-container>
                            <v-row>
                                <v-col  cols="12" md="6" style="border-right: 1px solid #cccccc;">
                                    <v-row v-if="Cliente!=''">
                                        <v-col cols="12" md="3">
                                            <span style="font-weight: 500;">Nome: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                            <span>{{ Cliente.cliNomeEmpresa }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">CPF/CNPJ: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliCpf == "" ? Cliente.cliCnpj : Cliente.cliCpf  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="Cliente.cliCnpj!=''">
                                             <span style="font-weight: 500;">Razão Social: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;" v-if="Cliente.cliCnpj!=''">
                                             <span>{{ Cliente.cliRazaoSocial  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="Cliente.cliCnpj!=''">
                                             <span style="font-weight: 500;">Responsável: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;" v-if="Cliente.cliCnpj!=''">
                                             <span>{{ Cliente.cliResponsavel  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <span style="font-weight: 500;">Telefone: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliTelefone }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Whatsapp: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliWhatsapp }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">E-mail: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Cliente.cliEmail }}</span>
                                        </v-col>
                                       
                                    </v-row>
                                </v-col>
                                <v-col  cols="12" md="6">
                                    <v-row v-if="Endereco!= ''" >
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Endereço: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Endereco.cliEndereco }} - {{ Endereco.cliNumero }} - {{  Endereco.cliComplemento }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Cidade/UF: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Endereco.cliCidade }} / {{ Endereco.cliEstado }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Bairro: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Endereco.cliBairro }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">CEP: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Endereco.cliCep }}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                     <v-btn @click.prevent="dialogCliente=false" class="btn btn-danger" style="color:#ffffff;">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

     <!--modal imobiliaria-->
     <v-row justify="center">
        <v-dialog v-model="dialogImobiliaria" class="controlcelEnd" transition="dialog-bottom-transition"
          width="auto">
            <v-card>
                <v-card-title style="background-color: #cccccc;">
                <span class="text-h5" style="font-size: 14px !important;">Informações do Imobiliária</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px">
                    <v-form style="font-size:13px;">
                        <v-container>
                            <v-row>
                                <v-col  cols="12" md="6" style="border-right: 1px solid #cccccc;">
                                    <v-row v-if="Imobiliaria!=''">
                                        <v-col cols="12" md="3">
                                            <span style="font-weight: 500;">Nome: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                            <span>{{ Imobiliaria.cliNomeEmpresa }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">CPF/CNPJ: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Imobiliaria.cliCpf == "" ? Imobiliaria.cliCnpj : Imobiliaria.cliCpf  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="Imobiliaria.cliCnpj!=''">
                                             <span style="font-weight: 500;">Razão Social: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;" v-if="Imobiliaria.cliCnpj!=''">
                                             <span>{{ Imobiliaria.cliRazaoSocial  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="Imobiliaria.cliCnpj!=''">
                                             <span style="font-weight: 500;">Responsável: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;" v-if="Imobiliaria.cliCnpj!=''">
                                             <span>{{ Imobiliaria.cliResponsavel  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <span style="font-weight: 500;">Telefone: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Imobiliaria.cliTelefone }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Whatsapp: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Imobiliaria.cliWhatsapp }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">E-mail: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ Imobiliaria.cliEmail }}</span>
                                        </v-col>
                                       
                                    </v-row>
                                </v-col>
                                <v-col  cols="12" md="6">
                                    <v-row v-if="EnderecoImob!= ''">
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Endereço: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ EnderecoImob.cliEndereco }} - {{ EnderecoImob.cliNumero }} - {{  EnderecoImob.cliComplemento }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Cidade/UF: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ EnderecoImob.cliCidade }} / {{ EnderecoImob.cliEstado }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">Bairro: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ EnderecoImob.cliBairro }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                             <span style="font-weight: 500;">CEP: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;">
                                             <span>{{ EnderecoImob.cliCep }}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                     <v-btn @click.prevent="dialogImobiliaria=false" class="btn btn-danger" style="color:#ffffff;">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

        <!--modal Serasa-->
        <v-row justify="center">
        <v-dialog v-model="dialogSerasa" class="controlcelEnd" transition="dialog-bottom-transition"
          width="auto">
            <v-card>
                <v-card-title style="background-color: #cccccc;">
                <span class="text-h5" style="font-size: 19px !important;">Informações Serasa</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px" v-if="ListaSerasa != '' && ListaSerasa != null">
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col  cols="12" md="12" style="border-right: 1px solid #cccccc;">
                                    <v-row v-if="ListaSerasa.reports[0].reportName == 'RELATORIO_BASICO_PF_PME'">
                                        <v-col cols="12" md="12">
                                           Dados Cadastrais
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Relatório: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaSerasa.reports[0].reportName }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Score: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaSerasa.reports[0].score.score }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Probabilidade de Inadimplência: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaSerasa.reports[0].score.defaultRate }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Situação Documento: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaSerasa.reports[0].registration.statusRegistration }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Cliente:</span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{ ListaSerasa.reports[0].registration.consumerName  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Nome da mãe: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{  ListaSerasa.reports[0].registration.motherName  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Data Nascimento: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{ formattedDate(ListaSerasa.reports[0].registration.birthDate)  }}</span>
                                        </v-col>                                      
                                    </v-row>
                                    <v-row v-if="ListaSerasa.reports[0].reportName == 'RELATORIO_BASICO_PJ_PME'">
                                        <v-col cols="12" md="12">
                                           Dados Cadastrais
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Relatório: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaSerasa.reports[0].reportName }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Score: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaSerasa.reports[0].score.score }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Probabilidade de Inadimplência: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ (ListaSerasa.reports[0].score.defaultRate/100) }} %</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                            <span style="font-weight: 500;">Situação Documento: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                            <span>{{ ListaSerasa.reports[0].registration.statusRegistration }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Documento:</span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{ ListaSerasa.reports[0].registration.companyDocument  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Cliente:</span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{ ListaSerasa.reports[0].registration.companyName  }}</span>
                                        </v-col>
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Fundação: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{  formattedDate(ListaSerasa.reports[0].registration.foundationDate)  }}</span>
                                        </v-col>      
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Cidade: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{  ListaSerasa.reports[0].registration.address.city  }}</span>
                                        </v-col>    
                                        <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                             <span style="font-weight: 500;">Estado: </span>
                                        </v-col>
                                        <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                             <span>{{  ListaSerasa.reports[0].registration.address.state  }}</span>
                                        </v-col>                             
                                    </v-row>
                                    <v-row v-if="ListaSerasa.reports[0].negativeData && 
                                                 ListaSerasa.reports[0].negativeData.pefin && 
                                                 ListaSerasa.reports[0].negativeData.pefin.pefinResponse && 
                                                 ListaSerasa.reports[0].negativeData.pefin.pefinResponse.length > 0">
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                            Pendências Financeiras (PEFIN)
                                        </v-col>
                                        <v-col cols="12" md="12" v-for="(pefin,index) in ListaSerasa.reports[0].negativeData.pefin.pefinResponse" :key="index" :class="index % 2 === 0 ? 'bg-custom-white' : 'bg-custom-light-blue'" >
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Data: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formattedDate(pefin.occurrenceDate) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Natureza da dívida: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ pefin.legalNature }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Identificador do contrato: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ pefin.contractId }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Nome do credor: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ pefin.creditorName }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Valor da dívida: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formatarValor(pefin.amount) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Estado: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ pefin.federalUnit }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                            Pendências Financeiras (PEFIN)
                                        </v-col>
                                        <v-row>
                                            <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:30px;">
                                                <span style="font-weight: 500;">Nada Consta </span>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    <v-row v-if="ListaSerasa.reports[0].negativeData && 
                                                 ListaSerasa.reports[0].negativeData.refin != '' && 
                                                 ListaSerasa.reports[0].negativeData.refin.refinResponse &&
                                                 ListaSerasa.reports[0].negativeData.refin.refinResponse.length > 0">
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;">
                                            Restrições Financeiras (REFIN)
                                        </v-col>
                                        <v-col cols="12" md="12" v-for="(refin,index) in ListaSerasa.reports[0].negativeData.refin.refinResponse" :key="index" :class="index % 2 === 0 ? 'bg-custom-white' : 'bg-custom-light-blue'" >
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Data: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formattedDate(refin.occurrenceDate) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Natureza da dívida: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ refin.legalNature }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Identificador do contrato: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ refin.contractId }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Nome do credor: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ refin.creditorName }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3">
                                                    <span style="font-weight: 500;">Valor da dívida: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formatarValor(refin.amount) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Estado: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ refin.federalUnit }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                            Restrições Financeiras (REFIN)
                                        </v-col>
                                        <v-row>
                                            <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:30px;">
                                                <span style="font-weight: 500;">Nada Consta </span>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    <v-row v-if="ListaSerasa.reports[0].negativeData && 
                                                 ListaSerasa.reports[0].negativeData.notary &&
                                                 ListaSerasa.reports[0].negativeData.notary.notaryResponse &&
                                                 ListaSerasa.reports[0].negativeData.notary.notaryResponse.length > 0">
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;">
                                            Anotações Negativas – Protestos
                                        </v-col>
                                        <v-col cols="12" md="12" v-for="(prot,index) in ListaSerasa.reports[0].negativeData.notary.notaryResponse" :key="index" :class="index % 2 === 0 ? 'bg-custom-white' : 'bg-custom-light-blue'" >
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Data: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formattedDate(prot.occurrenceDate) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Valor: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formatarValor(prot.amount) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Número do Cartório: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ prot.officeNumber }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Cartório: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ prot.officeName }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Cidade: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ prot.city }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Estado: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ prot.federalUnit }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                            Anotações Negativas – Protestos
                                        </v-col>
                                        <v-row>
                                            <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:30px;">
                                                <span style="font-weight: 500;">Nada Consta </span>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    <v-row v-if="ListaSerasa.reports[0].negativeData && 
                                                ListaSerasa.reports[0].negativeData.check && 
                                                ListaSerasa.reports[0].negativeData.check.checkResponse &&
                                                ListaSerasa.reports[0].negativeData.check.checkResponse.length > 0">
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;">
                                            Anotações Negativas - Cheques Sem Fundo (CCF)
                                        </v-col>
                                        <v-col cols="12" md="12" v-for="(cheq,index) in ListaSerasa.reports[0].negativeData.check.checkResponse" :key="index"  :class="index % 2 === 0 ? 'bg-custom-white' : 'bg-custom-light-blue'">
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Data: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formattedDate(cheq.occurrenceDate) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Identificador do banco: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ cheq.bankId }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Nome do banco: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ cheq.bankName }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Número da agência: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ cheq.bankAgencyId }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Quantidade de cheques: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ cheq.checkCount }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Cidade: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ cheq.city }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Estado: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ cheq.federalUnit }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                            Anotações Negativas - Cheques Sem Fundo (CCF)
                                        </v-col>
                                        <v-row>
                                            <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:30px;">
                                                <span style="font-weight: 500;">Nada Consta </span>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    <v-row v-if="ListaSerasa.reports[0].negativeData && 
                                                 ListaSerasa.reports[0].negativeData.collectionRecords && 
                                                 ListaSerasa.reports[0].negativeData.collectionRecords.collectionRecordsResponse &&
                                                 ListaSerasa.reports[0].negativeData.collectionRecords.collectionRecordsResponse.length > 0">
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;">
                                            Anotações Negativas - Dívidas Vencidas (Convem)
                                        </v-col>
                                        <v-col cols="12" md="12" v-for="(convem,index) in ListaSerasa.reports[0].negativeData.collectionRecords.collectionRecordsResponse" :key="index" :class="index % 2 === 0 ? 'bg-custom-white' : 'bg-custom-light-blue'">
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Data: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formattedDate(convem.occurrenceDate) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Natureza da dívida: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ convem.legalNature }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Identificador do contrato: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ convem.contractId }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Nome do credor: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ convem.creditorName }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Valor da dívida: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ formatarValor(convem.amount) }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Cidade: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ convem.city }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Estado: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ convem.federalUnit }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row v-else>
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;" >
                                            Anotações Negativas - Dívidas Vencidas (Convem)
                                        </v-col>
                                        <v-row>
                                            <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:30px;">
                                                <span style="font-weight: 500;">Nada Consta </span>
                                            </v-col>
                                        </v-row>
                                    </v-row>
                                    <v-row v-if="ListaSerasa.reports[0].reportName == 'RELATORIO_BASICO_PJ_PME' && 
                                                 ListaSerasa.reports[0].director && 
                                                 ListaSerasa.reports[0].director.DirectorResponse &&
                                                 ListaSerasa.reports[0].director.DirectorResponse.results.length > 0">
                                        <v-col cols="12" md="12" style="background-color: #cccccc;margin-top:30px;">
                                            Administradores
                                        </v-col>
                                        <v-col cols="12" md="12" v-for="(dire,index) in ListaSerasa.reports[0].director.DirectorResponse.results" :key="index" :class="index % 2 === 0 ? 'bg-custom-white' : 'bg-custom-light-blue'">
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Tipo Documento: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ dire.documentType }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Documento: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ dire.documentId }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Nome: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ dire.name }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Cargo: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ dire.role }}</span>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col cols="12" md="3" style="padding-bottom: 2px;padding-top:3px;">
                                                    <span style="font-weight: 500;">Possui restrição: </span>
                                                </v-col>
                                                <v-col cols="12" md="9" style="text-align: left;padding-bottom: 2px;padding-top:3px;">
                                                    <span>{{ dire.hasNegative ? "Sim" : "Não" }}</span>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                <v-spacer></v-spacer>
                     <v-btn @click.prevent="dialogSerasa=false">Fechar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'
import { useRoute } from 'vue-router';
import VueSpeedometer from "vue-speedometer";

import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';


export default {
    name: 'ContratacaoDetalheView',
    components: {
        EasyDataTable,
        moment,
        VueSpeedometer
    },
    setup() {
        const route = useRoute();
        const id = route.params.idPed;

        return {
            id,
        };
    },
    data: () => ({
        tab: null,
        NomeTela: 'Contratações',
        InfoTela: 'Detalhe Contratação',
        MostraGrid: true,
        Cliente: [],
        Imobiliaria: [],
        PlanosGarantias:[],
        headers: [],
        Idiomas: [],
        vshowidioma: true,
        CadastroTelas: [{ cdtId: 28, cdtNome: "Detelhe Contratação" }],
        PedidosCli: [],
        Pedidos: [],
        TotalPedido: 0,
        validacomprova: [],
        PedidosComprova: [],
        showModal_comprova: false,
        ListaPedidoItem: [],
        ListaPedidoItemAdicional: [],
        PedidosMensagem: [],
        PedidosAgendamento: [],
        PedidosEndereco: [],
        PedidosComprova:[],  
        Estados: [],
        Cidades: [],
        Bairros: [],
        selectedFile: '',
        urlComprovante: '',
        PedidoComprovante: [],
        Situacoes: [],
        dialogCliente:false,
        dialogImobiliaria:false,
        ListEndereco:[],
        EnderecoImob:{},
        ListEnderecoImob:[],
        Endereco:{},
        ValidaCustomer:{},
        DadosGarantidora:[],
         //Documentos
         ListDocumentos:[],
        Documentos: {
            cdoId:0,
            cdoDocumento:'', 
            cdoNomeDocumento:'', 
            cdoUrlDocumento:'',
            cliId:'',
        },
        DocSelected:'',
        ListaDocs:[],
        TiposDocumentos:[],
        ListEntidadesDocumentos:[],
        //serasa
        score: 0,
        dialogSerasa:false,
        ListaSerasa: null,
    }),
    methods: {
        onReady(editor) {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value != null || value != "") {
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else {
                return "";
            }

        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        calculatotal() {
            var valorProdutos = 0;

            if (this.ListaPedidoItem != '') {
                this.ListaPedidoItem.forEach(item => {
                    valorProdutos += item.pdiValorTotalProduto;
                });
            }

            this.TotalPedido = (valorProdutos);
        },
        async ReenviarAnalise(){
            let _valida = await this.ListDocumentos.filter(x=> x.pmsUrlImagem == '' || x.pmsUrlImagem == null)
           // console.log('_valida')
           // console.log(_valida)
            if (_valida.length > 0){
                this.$mensagemAviso("É necessário enviar todos os arquivos solicitados.")
            }
            else{
                this.PedidosCli.pspId = 2;
                this.PutSituacao();
            }
        },
        async PutSituacao() {
            //alterar data vigência
            let _pedidosItems = this.ListaPedidoItem.map(produto => ({
                ...produto,
                pdiDataVigenciaIni: moment(produto.pdiDataVigenciaIni).format("YYYY-MM-DD"),
                pdiDataVigenciaFim: moment(produto.pdiDataVigenciaFim).format("YYYY-MM-DD")
            }));

            _pedidosItems.forEach((itemprod) => {
                   axios.put(`/Process/PedPedidoItem/Update`, itemprod)
             });         
            
            //validar se foi solicitado documentos
            let _validaSolicDocs = this.ListDocumentos.filter(x=> x.pmsNomeDe != '' && x.pmsNomeDe != null && (x.pmsUrlImagem == '' || x.pmsUrlImagem == null)).length;
            
            //seguir avisos e proxima etapa
            if (this.PedidosCli.pspId == 2){
                this.PedidosCli.pedSituacaoPedido = 'Analise Manual';
                await axios.put(`/Process/PedPedidos/Update`, this.PedidosCli)
                this.$router.push('/listacontratacoes');
            }
            else if (this.PedidosCli.pspId == 8){ 
                if (_validaSolicDocs > 0){
                   this.PedidosCli.pedSituacaoPedido = 'Aguardando Documentos';
                    await axios.put(`/Process/PedPedidos/Update`, this.PedidosCli)
                    this.$mensagemAviso("Dados Salvos, foi enviado aviso via e-mail para imobiliária.")
                    var _texto = "Solicitamos documentação complementar para análise da contratação. ";
                    this.EnviaAvisoImobiliariaDocs(_texto);
                    this.$router.push('/listacontratacoes');
                }
                else{
                    this.$mensagemAviso("É necessários inserir documentos necessários na aba Documentos.")
                }
            }
            else if (this.PedidosCli.pspId == 4){ 
                this.PedidosCli.pedSituacaoPedido = 'Não Aprovado';
                await axios.put(`/Process/PedPedidos/Update`, this.PedidosCli)
                this.$mensagemAviso("Dados Salvos, foi enviado aviso via e-mail para imobiliária.")
                var _texto = "Não foi aprovado. ";
                this.EnviaAvisoImobiliariaDocs(_texto);
                this.$router.push('/listacontratacoes');
            }
            else if (this.PedidosCli.pspId == 5){
                this.PedidosCli.pedSituacaoPedido = 'Aprovado Gerar Cobrança';
                await axios.put(`/Process/PedPedidos/Update`, this.PedidosCli)
                this.$mensagemAviso("Dados Salvos, foi enviado link de pagamento via e-mail para imobiliária e Cliente.")
                let _descricao = "";
                let _valorProd = 0;

                _descricao = "Referente a contratação: "
                this.ListaPedidoItem.forEach(element => {
                if (element.proId == 161 || element.proId == 162){
                    _descricao += element.pdiNomeProduto + " ";
                    _valorProd += element.pdiValorTotalProduto;
                }
                });
                var _texto = _descricao + " no valor de R$ " + this.formatarValor(_valorProd) + " foi aprovada.";
                var _dadoslink = "https://aquigarante.net.br/pgtocontratacaocliente/" + this.Cliente.cliId + "/" + this.Imobiliaria.cliId + "/" + this.PedidosCli.pedId
                this.EnviaAvisoPgtoCliente(_dadoslink,_texto);
                this.EnviaAvisoPgtoImobiliaria(_dadoslink,_texto);
                this.PedidosCli.pspId = 7;
                this.PedidosCli.pedSituacaoPedido = 'Aguardando Pagamento';
                await axios.put(`/Process/PedPedidos/Update`, this.PedidosCli)
                this.$router.push('/listacontratacoes');
            }
        },
        EnviaAvisoPgtoCliente(dadoslink, texto) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x => x.mpdId == 4);

                    let _dadosEmail = {
                        nomeDe: this.DadosGarantidora[0].itiNome,
                        nomePara: this.Cliente.cliNomeEmpresa,
                        assunto: 'Andamento Contratação: ' + this.PedidosCli.pedId,
                        destinatario: this.Cliente.cliEmail,
                        emailResposta: this.DadosGarantidora[0].itiReferencia,
                        textoEmail: this.templateEmail[0].mpdTexto.replace('[NomeCliente]', this.Cliente.cliNomeEmpresa)
                                                                  .replace('[NumeroPedido]', this.PedidosCli.pedId)
                                                                  .replace('[imobiliaria]', this.Imobiliaria.cliNomeEmpresa)
                                                                  .replace('[descricao]',texto)
                                                                  .replace('[LinkPgto]',dadoslink),
                        nomeAnexo: '',
                        anexo: '',
                        tipoAnexo: ''
                    }
                    axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                        .then(response => {
                            if (response.data == 1) {
                                console.log('email enviado')
                            }
                        });
                });
        },
        EnviaAvisoPgtoImobiliaria(dadoslink, texto) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x => x.mpdId == 4);

                    let _dadosEmail = {
                        nomeDe: this.DadosGarantidora[0].itiNome,
                        nomePara:  this.Imobiliaria.cliNomeEmpresa + ' - ' + this.Cliente.cliNomeEmpresa,
                        assunto: 'Andamento Contratação: ' + this.PedidosCli.pedId,
                        destinatario: this.Imobiliaria.cliEmail,
                        emailResposta: this.DadosGarantidora[0].itiReferencia,
                        textoEmail: this.templateEmail[0].mpdTexto.replace('[NomeCliente]', this.Cliente.cliNomeEmpresa)
                                                                  .replace('[NumeroPedido]', this.PedidosCli.pedId)
                                                                  .replace('[imobiliaria]', this.Imobiliaria.cliNomeEmpresa)
                                                                  .replace('[descricao]',texto)
                                                                  .replace('[LinkPgto]',dadoslink),
                        nomeAnexo: '',
                        anexo: '',
                        tipoAnexo: ''
                    }
                    axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                        .then(response => {
                            if (response.data == 1) {
                                console.log('email enviado')
                            }
                        });
                });
        },
        EnviaAvisoImobiliariaDocs(texto) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x => x.mpdId == 7);

                    let _dadosEmail = {
                        nomeDe: this.DadosGarantidora[0].itiNome,
                        nomePara: this.Imobiliaria.cliNomeEmpresa + ' - ' + this.Cliente.cliNomeEmpresa,
                        assunto: 'Andamento Contratação: ' + this.PedidosCli.pedId,
                        destinatario: this.Imobiliaria.cliEmail,
                        emailResposta: this.DadosGarantidora[0].itiReferencia,
                        textoEmail: this.templateEmail[0].mpdTexto.replace('[NomeCliente]', this.Cliente.cliNomeEmpresa)
                                                                  .replace('[NumeroPedido]', this.PedidosCli.pedId)
                                                                  .replace('[Imobiliaria]', this.Imobiliaria.cliNomeEmpresa)
                                                                  .replace('[Descricao]',texto),
                        nomeAnexo: '',
                        anexo: '',
                        tipoAnexo: ''
                    }
                    axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                        .then(response => {
                            if (response.data == 1) {
                                console.log('email enviado')
                            }
                        });
                });
        },
        async openDialogCliente(value){
         //carrega endereco
         await axios.get(`/Process/CliClientesEndereco/GetEnderecoByCli/`+ value + '/0')
                        .then(response => {
                            this.ListEndereco = response.data;                             
                            if (this.ListEndereco != ''){
                                this.Endereco = this.ListEndereco[0];
                            }  
                            console.log('this.Endereco')
                            console.log(this.Endereco)  
                            this.dialogCliente = true;        
                        });
        },
        async openDialogImobiliaria(value){
         //carrega endereco
         await axios.get(`/Process/CliClientesEndereco/GetEnderecoByCli/`+ value + '/0')
                        .then(response => {
                            this.ListEnderecoImob = response.data;                             
                            if (this.ListEnderecoImob != ''){
                                this.EnderecoImob = this.ListEnderecoImob[0];
                                console.log('this.EnderecoImob')
                                console.log(this.EnderecoImob)
                            }   
                            this.dialogImobiliaria = true;         
                        });
        },
        async EnviarDocumento(){
            if (this.DocSelected == '') {
                this.$mensagemAviso("Selecione o Nome do Documento a ser enviado.")
            }
            else if (this.selectedFile == '' || this.selectedFile == null){
                this.$mensagemAviso("Selecione um Documento a ser enviado.")
            }
            else{
                if (this.selectedFile != '' && this.selectedFile != null) {
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        let _pedidoMensagem = {
                            PmsId: 0,
                            GrtId: 7,
                            CdtId: 28,
                            IdiId: 1,
                            PmsUrlImagem: uploadSuccess.data.nameFile,
                            PmsNomeDe: this.DocSelected.toString(),
                            PmsNomePara: this.TiposDocumentos.filter(x=>x.pmsNomePara == this.DocSelected)[0].pmsNomePara,
                            PmsTexto: "", 
                            PmsTelefoneRecebe: "",
                            PedId: this.PedidosCli.pedId,
                         };
 
                         await axios.post(`/Process/PedPedidoMensagem/Add`, _pedidoMensagem)
                            .then((response) => {
                            if (response.data > 0) {
                                axios.get('/Process/PedPedidoMensagem/GetAllByPedId/' + this.PedidosCli.pedId)
                                    .then(response => {
                                        this.ListDocumentos = response.data;
                                        let _nomeDoc = this.TiposDocumentos.filter(x=>x.pmsNomePara == this.DocSelected)[0].pmsNomePara;
                                        let _pmsdel = this.ListDocumentos.filter(x=> x.pmsNomePara == _nomeDoc && (x.pmsUrlImagem == '' || x.pmsUrlImagem == null));
                                        let _pmsId = _pmsdel[0].pmsId
                                                axios.delete(`/Process/PedPedidoMensagem/Delete/${_pmsId}`)
                                                        .then(response => {
                                                            if (response.status == 200) {
                                                                //carrega documentos
                                                                axios.get('/Process/PedPedidoMensagem/GetAllByPedId/' + this.PedidosCli.pedId)
                                                                .then(response => {
                                                                    this.ListDocumentos = response.data;
                                                                    this.DocSelected = '';
                                                                    this.selectedFile = '';
                                                                })
                                                                .catch(error => {
                                                                    console.error(error);
                                                                });
                                                            }
                                                        });
                                    })
                                    .catch(error => {
                                        console.error(error);
                                    });
                            }
                            });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
              }
         }
        },
        DeleteDocumento(item){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.pmsId;
                axios.delete(`/Process/PedPedidoMensagem/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                         //carrega documentos
                         axios.get('/Process/PedPedidoMensagem/GetAllByPedId/' + this.PedidosCli.pedId)
                        .then(response => {
                            this.ListDocumentos = response.data;
                        })
                        .catch(error => {
                            console.error(error);
                        });
                    }
                });
            }
            });
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        async CancelarContratacao(){
            this.$mensagemConfirma('Ao cancelar a contratação, links de pagamento emitidos serão cancelados.')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                this.PedidosCli.pspId = 6;
                this.PedidosCli.pedSituacaoPedido = 'Cancelado';
                axios.put(`/Process/PedPedidos/Update`, this.PedidosCli).then(response => {
                    if (this.PedidosCli.pedCodigoRetornoPgto !='')
                    {
                        axios.delete(`/Process/PgtoAsaas/delete/` + this.PedidosCli.pedCodigoRetornoPgto )
                            .then(responseDelLink => {
                            });
                    } 
                    this.$router.push('/listacontratacoes');
                });
               }
            });
        },
        async Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.Pedidos.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }
                    axios.get(`/Process/PedPedidos/GetById/` + this.id)
                        .then(response => {
                            this.PedidosCli = response.data;
                            console.log('this.PedidosCli')
                            console.log(this.PedidosCli)
                           if (this.PedidosCli.pedSerasa != '' && this.PedidosCli.pedSerasa != null){
                            this.score = this.PedidosCli.pedScore;
                            this.ListaSerasa = JSON.parse(this.PedidosCli.pedSerasa);
                           }


                            axios.get(`/Process/CliClientes/GetById/` + this.PedidosCli.cliId)
                                .then(response => {
                                    this.Cliente = response.data;
                                    //console.log('this.Cliente')
                                    //console.log(this.Cliente)
                                });

                            axios.get(`/Process/CliClientes/GetById/` + this.PedidosCli.imobId)
                                .then(response => {
                                    this.Imobiliaria = response.data;
                                   // console.log('this.Imobiliaria')
                                   // console.log(this.Imobiliaria)
                                });

                        });
                });

            await axios.get('/Process/PedPedidoItem/GetAllByPedId/' + this.id)
                .then(response => {
                    this.ListaPedidoItem = response.data;

                    this.ListaPedidoItem = this.ListaPedidoItem.map(produto => ({
                        ...produto,
                        pdiDataVigenciaIni: produto.pdiDataVigenciaIni.split('T')[0],
                        pdiDataVigenciaFim: produto.pdiDataVigenciaFim.split('T')[0]
                    }));

                   // console.log('ListaPedidoItem')
                   // console.log(this.ListaPedidoItem)
                })
                .catch(error => {
                    console.error(error);
                });

            await axios.get('/Process/PedPedidoItemAdicional/GetAllByPedId/' + this.id)
                .then(response => {
                    this.ListaPedidoItemAdicional = response.data;
                   // console.log('ListaPedidoItemAdicional')
                   // console.log(this.ListaPedidoItemAdicional)
                })
                .catch(error => {
                    console.error(error);
                });


            axios.get('/Process/PedPedidoEndereco/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosEndereco = response.data;
                   // console.log('PedidosEndereco')
                   // console.log(this.PedidosEndereco)
                })
                .catch(error => {
                    console.error(error);
                });

                axios.get('/Process/PedPedidoPgtoComprova/GetAllByPedId/' + this.id)
                .then(response => {
                    this.PedidosComprova = response.data;
                   // console.log('PedidosComprova')
                   // console.log(this.PedidosComprova)
                })
                .catch(error => {
                    console.error(error);
                });


            axios.get(`/Process/PedCadSituacaoPedido/GetAll`)
                .then(response => {
                    this.Situacoes = response.data.filter(x=> x.pspId != 3 &&  x.pspId != 6);
                   // console.log('this.Situacoes')
                   // console.log(this.Situacoes)
                });

            //planos Garantias
            axios.get('/Process/InsInstitucional/institucional/32/planos')
                    .then(response => {
                        this.PlanosGarantias = response.data;
                    })
                    .catch(error => {
                        console.error(error);
                    });

            //dados da Garantidora
            axios.get('/Process/InsInstitucional/institucional/34/informacoes')
            .then(response => {
                this.DadosGarantidora = response.data;
                //console.log('this.DadosGarantidora')
               // console.log(this.DadosGarantidora)
            })
            .catch(error => {
                console.error(error);
            });

            axios.get('/Process/PedPedidoMensagem/GetAllByPedId/' + this.PedidosCli.pedId)
            .then(response => {
                this.ListDocumentos = response.data;
                this.TiposDocumentos = [...new Map(response.data.map(item => [item.pmsNomePara, item])).values()];
            })
            .catch(error => {
                console.error(error);
            });
                    

            this.calculatotal();
        }
    },
    created() {
        this.Get();
    }
}
</script>

<style scoped>
.tabinfo {
    font-size: 14px;
    text-transform: capitalize;
}


td {
    /*border: 1px solid #333333;*/
    padding: 3px;
}

@media (min-width:1200px) {
    .controlcelEnd {
    height: 600px;
        width: calc(100% - 248px) !important;
        display: flex;
        flex-direction: column;
    }

}

.bg-custom-white {
    background-color: white;
}

.bg-custom-light-blue {
    background-color: #c5dce7; /* Customize conforme necessário */
}

</style>
