<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Produtos Textos Gerais</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="TextosGerais.ptgId" label="Id" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-text-field v-model="TextosGerais.ptgTexto1"
                                        label="Texto 1" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-text-field v-model="TextosGerais.ptgTexto2"
                                        label="Texto 2" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <v-text-field v-model="TextosGerais.ptgTexto3"
                                        label="Texto 3" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <small style="padding-bottom:5px;"><strong>Descrição 1</strong></small>
                                     <ckeditor :editor="editor" @ready="onReady" v-model="TextosGerais.ptgDescricao1" :config="$ckconfig"></ckeditor>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <small style="padding-bottom:5px;"><strong>Descrição 2</strong></small>
                                     <ckeditor :editor="editor" @ready="onReady" v-model="TextosGerais.ptgDescricao2" :config="$ckconfig"></ckeditor>
                                </v-col>
                                <v-col cols="12" md="12">
                                    <small style="padding-bottom:5px;"><strong>Descrição 3</strong></small>
                                     <ckeditor :editor="editor" @ready="onReady" v-model="TextosGerais.ptgDescricao3" :config="$ckconfig"></ckeditor>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-file-input v-model="selectedFile" label="Imagem" variant="outlined"
                                        prepend-icon="mdi-image" accept="image/*"></v-file-input>
                                </v-col>
                                <v-col cols="12" md="3" v-if="TextosGerais.ptgUrlImagem !== '' && TextosGerais.ptgUrlImagem !== null">
                                    <img :src="$imgURL + TextosGerais.ptgUrlImagem" style="width: 100px" />
                                    <v-btn icon density="compact" color="red" class="delete-icon" variant="text">
                                        <v-icon @click="DeleteImagem()" style="font-size:22px;">mdi-alpha-x-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="TextosGerais.ptgId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="outlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="ListaTextos" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

export default {
    name: 'ProTextosGeraisView',
    components: {
        EasyDataTable,
    },
    data: () => ({
        editor: DecoupledEditor,
        TextosGerais: {
            ptgId: 0,
            ptgTexto1: '',
            ptgTexto2: '',
            ptgTexto3: '',
            ptgDescricao1: '',
            ptgDescricao2: '',
            ptgDescricao3: '',
            ptgUrlImagem: '',
        },
        ListaTextos: [],
        headers: [
            { text: "Id", value: "ptgId", sortable: true, },
            { text: "Texto 1", value: "ptgTexto1" },
            { text: "Texto 2", value: "ptgTexto2" },
            { text: "Texto 3", value: "ptgTexto3" },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
        selectedFile: null,
    }),
    methods: {
       onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        Limpar() {
            this.TextosGerais.ptgId = 0;
            this.TextosGerais.ptgTexto1 = '';
            this.TextosGerais.ptgTexto2 = '';
            this.TextosGerais.ptgTexto3 = '';
            this.TextosGerais.ptgDescricao1 = '';
            this.TextosGerais.ptgDescricao2 = '';
            this.TextosGerais.ptgDescricao3 = '';
            this.TextosGerais.ptgUrlImagem = '';
            this.selectedFile = null;
            this.Get();
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        async Add() {
                if (this.selectedFile != '' && this.selectedFile != null) {
                   // console.log(this.selectedFile[0].size)
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.TextosGerais.ptgUrlImagem = uploadSuccess.data.nameFile;
                        axios.post(`/Process/ProProdutosTextoGeral/Add`, this.TextosGerais)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.Get();
                                this.Limpar();
                            }
                        });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else {
                    this.TextosGerais.ptgUrlImagem = "";
                        axios.post(`/Process/ProProdutosTextoGeral/Add`, this.TextosGerais)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.Get();
                                this.Limpar();
                            }
                        });
                }

        },
        async Put() {
                if (this.selectedFile != '' && this.selectedFile != null) {
                    //console.log(this.selectedFile[0].size)
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.TextosGerais.ptgUrlImagem = uploadSuccess.data.nameFile;
                        axios.put(`/Process/ProProdutosTextoGeral/Update`, this.TextosGerais)
                        .then(response => {
                            if (response.status == 200) {
                                this.$mensagemAvisoSucesso("Registro Alterado.")
                                this.Get();
                                this.Limpar();
                            }
                        });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else{
                    if (this.TextosGerais.ptgUrlImagem != "") {
                        this.TextosGerais.ptgUrlImagem = "0";
                    }
                    axios.put(`/Process/ProProdutosTextoGeral/Update`, this.TextosGerais)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                        }
                    });
                }              
        },
        EditGrid(item) {
            // Lógica para editar o item
            this.TextosGerais = item;
            this.$scrollToTop();
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.ptgId;
                axios.delete(`/Process/ProProdutosTextoGeral/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                    }
                });
            }
            });
        },
        DeleteImagem(){
            this.TextosGerais.ptgUrlImagem = "";
            /*this.$mensagemConfirma('Tem certeza que deseja excluir?')
                .then((result) => {
                    if (result.isConfirmed) {
                        // Lógica para excluir o item
                        axios.post(`/Process/UploadImg/delete/${value}`)
                            .then(response => {
                                if (response.status == 200) {
                                    this.$mensagemAvisoSucesso("Imagem Excluida.")
                                    
                                }
                            });
                    }
                });*/
        },
        Get() {
            axios.get(`/Process/ProProdutosTextoGeral/GetAll`)
                .then(response => {
                    this.ListaTextos = response.data;
                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
