
import { createRouter, createWebHistory } from 'vue-router'
import Login from '../views/LoginView.vue'
import ResetSenha from '../views/ResetSenhaView.vue'
import Home from '../views/HomeView.vue'
import AceiteImobiliaria from '../views/AceiteImobiliariaView.vue'
import AceiteCliente from '../views/AceiteClienteView.vue'
import PgtoContratacaoCliente from '../views/PgtoContratacaoClienteView.vue'
import NovaImobiliaria from '../views/NovaImobiliariaView.vue'

import GrupoTelas from '../views/Adm/GrupoTelasView.vue'
import GrupoTelasInstitucional from '../views/Adm/GrupoTelasInstitucionalView.vue'
import CadastroTelas from '../views/Adm/CadastroTelasView.vue'
import ConfigListaImagensTelas from '../views/Adm/ConfigListaImagensTelasView.vue'
import ConfigListaArquivosTelas from '../views/Adm/ConfigListaArquivosTelasView.vue'
import ConfigListaGridsTelas from '../views/Adm/ConfigListaGridsTelasView.vue'

import CadastroTelasInstitucional from '../views/Adm/CadastroTelasInstitucionalView.vue'
import CadastroTipoUsuario from '../views/Adm/CadastroTipoUsuarioView.vue'
import ControleSmtp from '../views/Adm/ControleSmtpView.vue'
import ConfigInstitucional from '../views/Adm/ConfigInstitucionalView.vue'
import ConfigListaImagens from '../views/Adm/ConfigListaImagensView.vue'
import ConfigListaArquivos from '../views/Adm/ConfigListaArquivosView.vue'
import ConfigListaGrids from '../views/Adm/ConfigListaGridsView.vue'
import CadastroPais from '../views/Cadastros/CadastroPaisView.vue'
import DocumentosEntidades from '../views/Cadastros/DocumentosEntidadesView.vue'
import ContratosEntidades from '../views/Cadastros/ContratosEntidadesView.vue'
import CadastroEstados from '../views/Cadastros/CadastroEstadosView.vue'
import CadastroCidades from '../views/Cadastros/CadastroCidadesView.vue'
import CadastroBairros from '../views/Cadastros/CadastroBairrosView.vue'
import CadastroIdiomas from '../views/Cadastros/CadastroIdiomasView.vue'
import CadastroFormasPagamento from '../views/Cadastros/CadastroFormasPagamentoView.vue'
import CadastroMoedas from '../views/Cadastros/CadastroMoedasView.vue'
import CadastroCotacoes from '../views/Cadastros/CadastroCotacoesView.vue'
import MensagensPadrao from '../views/Cadastros/CadastroMensagensPadraoView.vue'
import CadastroUsuarios from '../views/Usuarios/CadastroUsuariosView.vue'
import ControlePermissoes from '../views/Usuarios/ControlePermissoesView.vue'
import Institucional from '../views/Institucional/InstitucionalView.vue'
import SegmentosInst from '../views/Institucional/SegmentosInstView.vue'

import SegmentosProd from '../views/Produtos/SegmentosProdView.vue'
import ProdutosAvulsos from '../views/Produtos/ProProdutosAvulsosView.vue'
import ProdutosTabelaPreco from '../views/Produtos/ProProdutosTabelaPrecoView.vue'
import Produtos from '../views/Produtos/ProProdutosView.vue'
import ProTextosGerais from '../views/Produtos/ProTextosGeraisView.vue'
import ProAvulsosporCestas from '../views/Produtos/ProAvulsosporCestasView.vue'

import Clientes from '../views/Clientes/CliClientesView.vue'
import Imobiliarias from '../views/Imobiliarias/ImobiliariasView.vue'
import Parceiros from '../views/Parceiros/ParceirosView.vue'

import Cupons from '../views/Cupom/CupCuponsView.vue'

import EntregasCorreio from '../views/Entregas/EntregasCorreioView.vue'
import EntregasFrete from '../views/Entregas/EntregasFreteView.vue'
import EntregasHorarios from '../views/Entregas/EntregasHorariosView.vue'
import EntregasExcecao from '../views/Entregas/EntregasExcecaoView.vue'

import ConfigFormasPagamento from '../views/ConfigPgto/ConConfigFormasPagamentoView.vue'

import ListaContratacoes from '../views/Pedidos/ListaContratacoesView.vue'
import ListaContratacoesVigentes from '../views/Pedidos/ListaContratacoesVigentesView.vue'
import ContratacaoDetalhe from '../views/Pedidos/ContratacaoDetalheView.vue'
import NovaContratacao from '../views/Pedidos/NovaContratacaoView.vue'
import ContratacoesNegadoCancelado from '../views/Pedidos/ContratacoesNegadoCanceladoView.vue'
import ContratacaoNegadaDetalhe from '../views/Pedidos/ContratacaoNegadaDetalheView.vue'
import ContratacaoVigenteDetalhe from '../views/Pedidos/ContratacaoVigenteDetalheView.vue'

import TiposBlocos from '../views/Home/HomTiposBlocosView.vue'
import BlocosHome from '../views/Home/HomBlocosHomeView.vue'
import BlocosHomeMobile from '../views/Home/HomBlocosHomeMobileView.vue'

const routes = [
  { path: '/', name: 'login', component: Login },
  { path: '/Home', name: 'Home', component: Home },
  { path: '/AceiteImobiliaria', name: 'AceiteImobiliaria', component: AceiteImobiliaria },
  { path: '/AceiteCliente/:cliId/:imobId/:pedId', name: 'AceiteCliente', component: AceiteCliente },
  { path: '/PgtoContratacaoCliente/:cliId/:imobId/:pedId', name: 'PgtoContratacaoCliente', component: PgtoContratacaoCliente },
  { path: '/NovaImobiliaria/:parId?/:nome?', name: 'NovaImobiliaria', component: NovaImobiliaria },
  { path: '/ResetSenha/:id/:key', name: 'ResetSenha', component: ResetSenha },
  { path: '/GrupoTelas', name: 'GrupoTelas', component: GrupoTelas },
  { path: '/GrupoTelasInstitucional', name: 'GrupoTelasInstitucional', component: GrupoTelasInstitucional },
  { path: '/CadastroTelas', name: 'CadastroTelas', component: CadastroTelas },
  { path: '/ConfigListaImagensTelas', name: 'ConfigListaImagensTelas', component: ConfigListaImagensTelas },
  { path: '/ConfigListaArquivosTelas', name: 'ConfigListaArquivosTelas', component: ConfigListaArquivosTelas },
  { path: '/ConfigListaGridsTelas', name: 'ConfigListaGridsTelas', component: ConfigListaGridsTelas },
  { path: '/CadastroTelasInstitucional', name: 'CadastroTelasInstitucional', component: CadastroTelasInstitucional },
  { path: '/CadastroTipoUsuario', name: 'CadastroTipoUsuario', component: CadastroTipoUsuario },
  { path: '/ControleSmtp', name: 'ControleSmtp', component: ControleSmtp },
  { path: '/ConfigInstitucional', name: 'ConfigInstitucional', component: ConfigInstitucional },
  { path: '/ConfigListaImagens', name: 'ConfigListaImagens', component: ConfigListaImagens },
  { path: '/ConfigListaArquivos', name: 'ConfigListaArquivos', component: ConfigListaArquivos },
  { path: '/ConfigListaGrids', name: 'ConfigListaGrids', component: ConfigListaGrids },
  { path: '/CadastroPais', name: 'CadastroPais', component: CadastroPais },
  { path: '/DocumentosEntidades', name: 'DocumentosEntidades', component: DocumentosEntidades },
  { path: '/ContratosEntidades', name: 'ContratosEntidades', component: ContratosEntidades },
  { path: '/CadastroEstados', name: 'CadastroEstados', component: CadastroEstados },
  { path: '/CadastroCidades', name: 'CadastroCidades', component: CadastroCidades },
  { path: '/CadastroBairros', name: 'CadastroBairros', component: CadastroBairros },
  { path: '/CadastroIdiomas', name: 'CadastroIdiomas', component: CadastroIdiomas },
  { path: '/CadastroFormasPagamento', name: 'CadastroFormasPagamento', component: CadastroFormasPagamento },
  { path: '/CadastroMoedas', name: 'CadastroMoedas', component: CadastroMoedas },
  { path: '/CadastroCotacoes', name: 'CadastroCotacoes', component: CadastroCotacoes },
  { path: '/MensagensPadrao', name: 'MensagensPadrao', component: MensagensPadrao },
  { path: '/CadastroUsuarios', name: 'CadastroUsuarios', component: CadastroUsuarios },
  { path: '/ControlePermissoes', name: 'ControlePermissoes', component: ControlePermissoes },
  { path: '/Institucional/:id/:nome', name: 'Institucional', component: Institucional },
  { path: '/SegmentosInst', name: 'SegmentosInst', component: SegmentosInst },
  { path: '/SegmentosProdutos', name: 'SegmentosProd', component: SegmentosProd },
  { path: '/ProdutosAvulsos', name: 'ProdutosAvulsos', component: ProdutosAvulsos },
  { path: '/ProdutosTabelaPreco', name: 'ProdutosTabelaPreco', component: ProdutosTabelaPreco },
  { path: '/Produtos', name: 'Produtos', component: Produtos },
  { path: '/ProdutosTextosGerais', name: 'ProTextosGerais', component: ProTextosGerais },
  { path: '/avulsosporcestas', name: 'ProAvulsosporCestas', component: ProAvulsosporCestas },

  { path: '/Clientes', name: 'Clientes', component: Clientes },
  { path: '/Imobiliarias', name: 'Imobiliarias', component: Imobiliarias },
  { path: '/Parceiros', name: 'Parceiros', component: Parceiros },
  { path: '/Cupons', name: 'Cupons', component: Cupons },
  { path: '/EntregasCorreio', name: 'EntregasCorreio', component: EntregasCorreio },
  { path: '/EntregasFrete', name: 'EntregasFrete', component: EntregasFrete },
  { path: '/EntregasHorarios', name: 'EntregasHorarios', component: EntregasHorarios },
  { path: '/EntregasExcecao', name: 'EntregasExcecao', component: EntregasExcecao },
  { path: '/ConfigFormasPagamento', name: 'ConfigFormasPagamento', component: ConfigFormasPagamento },
 
  { path: '/ListaContratacoes', name: 'ListaContratacoes', component: ListaContratacoes },
  { path: '/ListaContratacoesVigentes', name: 'ListaContratacoesVigentes', component: ListaContratacoesVigentes },
  { path: '/ContratacaoDetalhe/:idPed', name: 'ContratacaoDetalhe', component: ContratacaoDetalhe },
  { path: '/NovaContratacao/:idCli?', name: 'NovaContratacao', component: NovaContratacao },
  { path: '/ContratacoesNegadoCancelado', name: 'ContratacoesNegadoCancelado', component: ContratacoesNegadoCancelado },
  { path: '/ContratacaoNegadaDetalhe/:idPed', name: 'ContratacaoNegadaDetalhe', component: ContratacaoNegadaDetalhe },
  { path: '/ContratacaoVigenteDetalhe/:idPed', name: 'ContratacaoVigenteDetalhe', component: ContratacaoVigenteDetalhe },

  { path: '/TiposBlocos', name: 'TiposBlocos', component: TiposBlocos },
  { path: '/BlocosHome', name: 'BlocosHome', component: BlocosHome },
  { path: '/BlocosHomeMobile', name: 'BlocosHomeMobile', component: BlocosHomeMobile },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
  next()
})

export default router
