<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Cadastro de cidades</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="Cidade.cidId" label="Id" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" v-if="Paises.length > 0">
                                    <v-select v-model="Cidade.cpaId" label="Paises" :items="Paises" @update:modelValue="CarregaEstados(Cidade.cpaId)"
                                        item-title="cpaNome" item-value="cpaId" variant="outlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select v-model="Cidade.cetId" label="Estados" :items="Estados"
                                        item-title="cetNome" item-value="cetId" variant="outlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field v-model="Cidade.cidNome"
                                        label="Nome" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2">
                                    <v-select v-model="Cidade.cidAtivo" label="Ativo" :items="StatusAtivo"
                                        item-title="nome" item-value="cidAtivo" variant="outlined">
                                    </v-select>
                                </v-col>
                              
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="Cidade.cidId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="outlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="Cidades" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-cpaId="item">
                            <span>{{ Paises.filter(x=x.cpaId == item.cpaId) }}</span>
                        </template>
                        <template #item-cidAtivo="item">
                            <span>{{ item.cidAtivo ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'CadastroCidadesView',
    components: {
        EasyDataTable
    },
    data: () => ({
        Cidade: {
            cidId: 0,
            cidNome: '',
            cidAtivo: '',
            cetId: '',
            cpaId: '',
        },
        Cidades: [],
        Estados: [],
        Paises: [],
        StatusAtivo: [{ nome: "Sim", cidAtivo: true }, { nome: "Não", cidAtivo: false }],
        headers: [
            { text: "Id", value: "cidId", sortable: true, },
            { text: "Nome", value: "cidNome", sortable: true, },
            { text: "Ativo", value: "cidAtivo", sortable: true, },
            { text: "Estado", value: "cadEstados.cetNome", sortable: true, },
            { text: "Pais", value: "cadEstados.cadPaises.cpaNome", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        selectedPaises:null,
        selectedEstados:null,
        searchValue: '',
    }),
    methods: {
        Limpar() {
            this.Cidade.cidId = 0;
            this.Cidade.cidNome = '';
            this.Cidade.cidAtivo = '';
            this.Cidade.cetId = '';
            this.Cidade.cpaId = '';
            this.selectedPaises = null;
            this.selectedEstados = null,
            this.Get();
        },
        Add() {
            if (this.Cidade.cidNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.Cidade.cpaId == ''){
                this.$mensagemAviso("País Obrigatório.")
            }
            else if (this.Cidade.cetId == ''){
                this.$mensagemAviso("Estado Obrigatório.")
            }
            else {
                this.Cidade.CadEstados = { cetId: this.Cidade.cetId};
                if (this.Cidade.cidAtivo === "" || this.Cidade.cidAtivo === null){
                    this.Cidade.cidAtivo = true;
                }
                axios.post(`/Process/CadCidades/Add`, this.Cidade)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        Put() {
            if (this.Cidade.cidNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.Cidade.cpaId == ''){
                this.$mensagemAviso("País Obrigatório.")
            }
            else if (this.Cidade.cetId == ''){
                this.$mensagemAviso("Estado Obrigatório.")
            }
            else {
                this.Cidade.CadEstados = { cetId: this.Cidade.cetId};
                if (this.Cidade.cidAtivo === "" || this.Cidade.cidAtivo === null){
                    this.Cidade.cidAtivo = true;
                }
                axios.put(`/Process/CadCidades/Update`, this.Cidade)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        async EditGrid(item) {
            // Lógica para editar o item
            this.Cidade = item;
            this.Cidade.cpaId = item.cadEstados.cadPaises.cpaId
            await this.CarregaEstados(item.cadEstados.cadPaises.cpaId)
            this.Cidade.cetId = item.cadEstados.cetId
            this.$scrollToTop();
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.cidId;
                axios.delete(`/Process/CadCidades/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                    }
                });
            }
            });
        },
        CarregaEstados(value) {
            const param1 = value;
            axios.get(`/Process/CadEstados/GetByPaisId/${param1}`)
                .then(response => {
                    this.Estados = response.data.filter(x => x.cetAtivo).sort((a, b) => {
                                                                                    const nomeA = a.cetNome || ""; 
                                                                                    const nomeB = b.cetNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });

                });
        },
        Get() {
            axios.get(`/Process/CadCidades/GetAll`)
                .then(response => {
                    this.Cidades = response.data;
                });
        },
    },
    created() {
        this.Get();
        axios.get(`/Process/CadPaises/GetAll`)
                .then(response => {
                    this.Paises = response.data.filter(x => x.cpaVisivel).sort((a, b) => {
                                                                                    const nomeA = a.cpaNome || ""; 
                                                                                    const nomeB = b.cpaNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });
                });
    },
}
</script>

<style></style>
