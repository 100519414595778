<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Cadastro de cotações</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="Cotacao.cotId" label="Id" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="Cotacao.cotDataDia" type="date"
                                        label="Data Cotação" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="Cotacao.cotValorCotacao" type="number" prefix="R$"
                                    @change="Cotacao.cotValorCotacao != null ? (Cotacao.cotValorCotacao = parseFloat(Cotacao.cotValorCotacao,10)) : (Cotacao.cotValorCotacao = 0.0)"
                                        label="Valor Cotação em Reais" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-select v-model="Cotacao.cotAtivo" label="Ativo" :items="StatusAtivo"
                                        item-title="nome" item-value="cotAtivo" variant="outlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="3" v-if="Moedas!=''">
                                    <v-select v-model="Cotacao.moeId" label="Moeda" :items="Moedas"
                                        item-title="moeNome" item-value="moeId" variant="outlined">
                                    </v-select>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="Cotacao.cotId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="outlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="Cotacoes" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-cotAtivo="item">
                            <span>{{ item.cotAtivo ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-cotDataDia="item">
                            <span>{{ formattedDate(item.cotDataDia) }}</span>
                        </template>
                        <template #item-cotValorCotacao="item">
                            {{ item.cotValorCotacao !== null ? formatarValor(item.cotValorCotacao) : 0 }}
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'

export default {
    name: 'CadastroCotacoesView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        Cotacao: {
            cotId: 0,
            cotDataDia: '',
            cotValorCotacao: '',
            cotAtivo: '',
            moeId: '',
        },
        Cotacoes: [],
        StatusAtivo: [{ nome: "Sim", cotAtivo: true }, { nome: "Não", cotAtivo: false }],
        headers: [
            { text: "Id", value: "cotId", sortable: true, },
            { text: "Moeda", value: "cadMoedas.moeNome", sortable: true, },
            { text: "Data", value: "cotDataDia", sortable: true, },
            { text: "Cotação", value: "cotValorCotacao"},
            { text: "Ativo", value: "cotAtivo", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        selectedMoedas:null,
        Moedas:[],
        searchValue: '',
    }),
    methods: {
        formattedDate(value) {
            return this.$moment(value).format('DD/MM/YYYY')
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        Limpar() {
            this.Cotacao.cotId = 0;
            this.Cotacao.cotDataDia = '';
            this.Cotacao.cotValorCotacao = '';
            this.Cotacao.cotAtivo = '';
            this.Cotacao.moeId = '';
            this.selectedMoedas = null;
            this.Get();
        },
        Add() {
            if (this.Cotacao.cotDataDia == '') {
                this.$mensagemAviso("Data Cotação Obrigatório.")
            }
            else if (this.Cotacao.cotValorCotacao == ''){
                this.$mensagemAviso("Valor da Cotação Obrigatório.")
            }
            else if (this.Cotacao.moeId == ''){
                this.$mensagemAviso("Moeda Obrigatório.")
            }
            else {
                this.Cotacao.CadMoedas = { moeId: this.Cotacao.moeId};
                if (this.Cotacao.cotAtivo === "" || this.Cotacao.cotAtivo === null){
                    this.Cotacao.cotAtivo = true;
                }
                axios.post(`/Process/CadCotacoes/Add`, this.Cotacao)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        Put() {
            if (this.Cotacao.cotDataDia == '') {
                this.$mensagemAviso("Cotação Obrigatório.")
            }
            else if (this.Cotacao.cotValorCotacao == ''){
                this.$mensagemAviso("Valor da Cotação Obrigatório.")
            }
            else if (this.selectedMoedas == ''){
                this.$mensagemAviso("Moeda Obrigatório.")
            }
            else {
                this.Cotacao.CadMoedas = { moeId: this.Cotacao.moeId};
                if (this.Cotacao.cotAtivo === "" || this.Cotacao.cotAtivo === null){
                    this.Cotacao.cotAtivo = true;
                }
                axios.put(`/Process/CadCotacoes/Update`, this.Cotacao)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        EditGrid(item) {
            // Lógica para editar o item
            this.Cotacao = item;
            this.Cotacao.moeId = item.cadMoedas.moeId
            this.Cotacao.cotDataDia = this.$moment(this.Cotacao.cotDataDia).format('YYYY-MM-DD')
            this.$scrollToTop();
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.cotId;
                axios.delete(`/Process/CadCotacoes/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                    }
                });
            }
            });
        },
        Get() {
            axios.get(`/Process/CadCotacoes/GetAll`)
                .then(response => {
                    this.Cotacoes = response.data;
                });
        }
    },
    created() {
        this.Get();

        axios.get(`/Process/CadMoedas/GetAll`)
                .then(response => {
                    this.Moedas = response.data.filter(x => x.moeAtivo).sort((a, b) => {
                                                                                    const nomeA = a.moeNome || ""; 
                                                                                    const nomeB = b.moeNome || ""; 
                                                                                    return nomeA.localeCompare(nomeB);
                                                                                    });
                });
    },
}
</script>

<style></style>
