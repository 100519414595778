<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                     </v-tabs>
                     <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Entregas.ecoId" label="Id" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Entregas.grtId" label="Grupo" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Entregas.cdtId" label="Tela" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="vshowidioma">
                                            <v-select v-model="Entregas.idiId" label="Idioma" :items="Idiomas" @update:modelValue="CarregaLista(Entregas.idiId)"
                                                item-title="idiNome" item-value="idiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Entregas.ecoContrato"
                                                label="Contrato" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="Entregas.ecoStatus" label="Ativo" :items="StatusAtivo"
                                                item-title="nome" item-value="ecoStatus" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Entregas.ecoCep" 
                                                label="CEP" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="Entregas.ecoAviso" 
                                                label="Aviso" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="Entregas.ecoSenha"
                                                label="Senha" variant="underlined" required></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                    </v-window>
                
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="Entregas.ecoId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="" v-if="MostraGrid && tab=='dados'">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="ListEntregas" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-ecoStatus="item">
                            <span>{{ item.ecoStatus ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'

export default {
    name: 'EntregasCorreioView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Entregas Correio',
        InfoTela:'',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 21, cdtNome: "Entregas Correio" }],
        //dados
        Entregas: {
            ecoId: 0,
            grtId: 0,
            cdtId: 0,
            idiId: '',
            ecoContrato: '',
            ecoCep: '',
            ecoSenha: '',
            ecoAviso: '',
            ecoStatus: '',
        },
        ListEntregas: [],
        StatusAtivo: [{ nome: "Sim", ecoStatus: true }, { nome: "Não", ecoStatus: false }],
        //dados
        ListEntregasGeral:[],
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListEntregas = this.ListEntregasGeral.filter(x=> x.idiId == this.Entregas.idiId);
        },
        Limpar() {
            this.Entregas.cupId=0;
            this.Entregas.grtId=0;
            this.Entregas.cdtId=0;
            this.Entregas.ecoContrato="";
            this.Entregas.ecoCep="";
            this.Entregas.ecoSenha="";
            this.Entregas.ecoAviso="";
            this.Entregas.ecoStatus="";
            this.tab = 'dados';
            this.Get();
        },
        Add() {
               if (this.Entregas.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.Entregas.ecoCep === '') {
                    this.$mensagemAviso("Campo CEP Obrigatório.")
                }
                else if (this.Entregas.ecoStatus === '') {
                    this.$mensagemAviso("Campo Status Obrigatório.")
                }
                else {
                    if (this.Entregas.ecoStatus === "" || this.Entregas.ecoStatus === null) {
                        this.Entregas.ecoStatus = true;
                    }
                                                 
                    axios.post(`/Process/EntEntregaCorreio/Add`, this.Entregas)
                        .then(response => {
                            if (response.data > 0) {
                                    this.Entregas.ecoId = response.data;
                                    this.$mensagemAvisoSucesso("Registro Incluído.")
                                    this.Get();
                                    this.Limpar();
                                    this.tab = 'dados';                        
                            }
                        });
            }
        },
        Put() {
            if (this.Entregas.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.Entregas.ecoCep === '') {
                    this.$mensagemAviso("Campo CEP Obrigatório.")
                }
                else if (this.Entregas.ecoStatus === '') {
                    this.$mensagemAviso("Campo Status Obrigatório.")
                }
                else {
                    if (this.Entregas.ecoStatus === "" || this.Entregas.ecoStatus === null) {
                        this.Entregas.ecoStatus = true;
                    }
             
                axios.put(`/Process/EntEntregaCorreio/Update`, this.Entregas)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                            this.tab = 'dados';
                        }
                    });
            }
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.ecoId;
                axios.delete(`/Process/EntEntregaCorreio/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                        this.tab = 'dados';
                    }
                });
            }
            });
        },
        EditGrid(item) {
            this.tab = 'dados';
            this.Entregas = item;
            this.$scrollToTop();
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "ecoId" });
            this.headers.push({ text: "Contrato", value: "ecoContrato" });
            this.headers.push({ text: "CEP", value: "ecoCep" });
            this.headers.push({ text: "Senha", value: "ecoSenha" });
            this.headers.push({ text: "Aviso", value: "ecoAviso" });
            this.headers.push({ text: "Status", value: "ecoStatus" }); 
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.Entregas.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }
                    axios.get(`/Process/EntEntregaCorreio/GetAll`)
                        .then(response => {
                            this.ListEntregasGeral = response.data; 
                            if (this.Entregas.idiId != ''){
                                this.CarregaLista();
                            }                           
                        });
                    this.montaGrid();
                    this.Entregas.cdtId = 21;
                    this.Entregas.grtId = 5;

                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
