<template>
    <div>
    <div class="login-header box-shadow" v-if="DadosGarantidora!=''" style="height: 90px;">
      <div class="container-fluid d-flex justify-content-between align-items-center">
        <div class="brand-logo">
          <router-link to="/">
            <img :src="$imgURL + DadosGarantidora[0].insInstitucionalImagens[0].itgUrlImagem" alt="" style="width:auto;height:90px;padding-top: 14px;">
            <span style="color:#000000;font-size: 16px;padding-top: 25px;padding-left: 25px;"> Sistema de aluguel de imóveis, garantias e seguros</span>
          </router-link>
        </div>
      </div>
    </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;">
                                <h4 class="text-black h4" style="padding-right: 5px;">{{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#00203b" >
                        <v-tab value="dados" class="tabinfo" style="color: #ffffff !important;">Dados</v-tab>
                        <v-tab value="endereco" class="tabinfo" style="color: #ffffff !important;" v-if="mostraEndereco" >Endereço</v-tab>
                        <v-tab value="contas" class="tabinfo" style="color: #ffffff !important;" v-if="mostraEndereco && mostraconta" >Dados Bancários</v-tab>
                        <v-tab value="contratos" class="tabinfo" style="color: #ffffff !important;" v-if="mostraEndereco && mostraconta && mostraaceite" >Termo Aceite</v-tab>

                     </v-tabs>
                     <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Clientes.cliId" label="Id" variant="outlined"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="Clientes.cctId" label="Tipo" :items="TipoClientes" @update:modelValue="LimpaCampo(Clientes.cctId)"
                                                item-title="cctNome" item-value="cctId" variant="outlined" disabled>
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="5">
                                            <v-text-field v-model="Clientes.cliNomeEmpresa"
                                                label="Nome" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2" style="display:none;">
                                            <v-select v-model="Clientes.cliVisivel" label="Visível" :items="StatusVisivel"
                                                item-title="nome" item-value="cliVisivel" variant="outlined">
                                            </v-select>
                                        </v-col>                                     
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="Clientes.cliTelefone" v-mask="'(##) ####-#####'"
                                                label="Telefone" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Clientes.cliWhatsapp" v-mask="'(##) ####-#####'"
                                                label="Whatsapp" variant="outlined" required></v-text-field>
                                        </v-col>
                                     
                                        <v-col cols="12" md="5">
                                            <v-text-field v-model="Clientes.cliEmail" label="E-mail" 
                                            variant="outlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" style="display: none;">
                                            <v-text-field v-model="Clientes.cliSenha" :type="senhaVisivel ? 'text' : 'password'"
                                                label="Senha" variant="outlined"
                                                :append-icon="senhaVisivel ? 'mdi-eye-off' : 'mdi-eye'"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="Clientes.cctId == 1" style="display: none;"> 
                                            <v-text-field v-model="Clientes.cliDataNascimento" type="date"
                                                label="Data Nascimento" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-if="Clientes.cctId == 1">
                                            <v-text-field v-model="Clientes.cliCpf" v-mask="'###.###.###-##'" 
                                                label="CPF" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-if="Clientes.cctId == 2">
                                            <v-text-field v-model="Clientes.cliCnpj" v-mask="'##.###.###/####-##'" 
                                                label="CNPJ" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6" v-if="Clientes.cctId == 2">
                                            <v-text-field v-model="Clientes.cliRazaoSocial"
                                                label="Razão Social" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-if="Clientes.cctId == 2">
                                            <v-text-field v-model="Clientes.cliResponsavel" 
                                                label="Responsável" variant="outlined" required></v-text-field>
                                        </v-col>

                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="Clientes.cliCampoExtra1" :disabled="bloqueiaCampos"
                                                label="CRECI" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2" v-if="Clientes.cliDataCadastro!=''">
                                            <v-text-field v-model="Clientes.cliDataCadastro" type="date"
                                                label="Cadastro" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" style="background-color:beige;" v-show="false">
                                            <v-select v-model="Clientes.cliAtivo" label="Ativado" :items="StatusAtivo" disabled="true"
                                                item-title="nome" item-value="cliAtivo" variant="outlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="5" style="background-color:beige;" v-show="false">
                                            <v-combobox v-model="ParcSelected" label="Parceiros" :items="ListParceiros" disabled="true"
                                            searchable
                                            clearable
                                            auto-select-first="exact"
                                              item-title="cliNomeEmpresaConcat" item-value="cliId" variant="outlined">
                                        </v-combobox>
                                          </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliCampoExtra2"
                                                label="Onde Conheceu" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliCampoExtra3"
                                                label="Campo Extra 3" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliCampoExtra4"
                                                label="Campo Extra 4" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliCampoExtra5"
                                                label="Campo Extra 5" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliData1" type="date"
                                                label="Data 1" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliData2" type="date"
                                                label="Data 2" variant="outlined" required></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                         <v-col cols="12" md="12" style="text-align:right;">
                                            <button type="button" class="btn btn-success" @click="AddDados()">
                                                Avançar
                                            </button>
                                         </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                        <v-window-item value="endereco" >
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Endereco.cceId" label="Id" variant="outlined"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Endereco.cliCep" v-mask="'#####-###'"
                                                label="CEP" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <button type="button" class="btn btn-info" @click.prevent="Buscacep(Endereco.cliCep)" style="color:#ffffff;">
                                                Verificar CEP
                                            </button>
                                        </v-col>
                                        <v-col cols="12" md="7" v-if="showend">
                                            <v-text-field v-model="Endereco.cliEndereco"
                                                label="Endereço" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2" v-if="showend">
                                            <v-text-field v-model="Endereco.cliNumero" type="number"
                                                label="Número" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="showend">
                                            <v-text-field v-model="Endereco.cliComplemento" type="number"
                                                label="Complemento" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="showend" style="display:none;">
                                            <v-text-field v-model="Endereco.cliPais" 
                                                label="País" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="showend">
                                            <v-text-field v-model="Endereco.cliEstado" maxlength="2"
                                                label="Sigla Estado" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="showend">
                                            <v-text-field v-model="Endereco.cliCidade" 
                                                label="Cidade" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="showend">
                                            <v-text-field v-model="Endereco.cliBairro" 
                                                label="Bairro" variant="outlined" required></v-text-field>
                                        </v-col>                                  
                                    </v-row>
                                    <v-row>
                                         <v-col cols="12" md="12" style="text-align:right;">
                                            <button type="button" class="btn btn-success" @click="AddEndereco()">
                                                Avançar
                                            </button>
                                         </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                        <v-window-item value="contas" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Adicione Conta Bancária</div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Contas.cdcId" label="Id" variant="outlined"></v-text-field>
                                        </v-col>
                                    </v-row>
                                   <v-row>
                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="Contas.cdcBanco" :disabled="bloqueiaCampos"
                                                label="Cód. Banco" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="Contas.cdcBancoNome" :disabled="bloqueiaCampos"
                                                label="Nome Banco" variant="outlined" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="Contas.cdcAgencia" :disabled="bloqueiaCampos"
                                                label="Agência" variant="outlined" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="Contas.cdcConta" :disabled="bloqueiaCampos"
                                                label="Conta" variant="outlined" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="Contas.cdcTipoConta" :disabled="bloqueiaCampos"
                                                label="Tipo Conta" variant="outlined" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="Contas.cdcContaTitular" :disabled="bloqueiaCampos"
                                                label="Nome Titular Conta" variant="outlined" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="Contas.cdcTitularDocumento" :disabled="bloqueiaCampos"
                                                label="Documento Titular Conta" variant="outlined" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="Contas.cdcChavePix" :disabled="bloqueiaCampos"
                                                label="Chave Pix" variant="outlined" required>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                         <v-col cols="12" md="12" style="text-align:right;">
                                            <button type="button" class="btn btn-success" @click="AddContas()">
                                                Avançar
                                            </button>
                                         </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                         <!--Grids-->
                         <v-window-item value="contratos" v-if="Contrato != ''">
                            <v-row>
                                <v-col cols="12" md="12" style="padding-top: 30px;text-align: center;">
                                     <p class="font-18">Termos Aceite Imobiliárias {{ DadosGarantidora[0].itiNome }}</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <div style="overflow-y: auto; height:450px; border: 1px solid #ccc; padding: 10px;">
                                        <p v-html="Contrato.itiResumo1"></p>
                                        <p v-html="Contrato.itiResumo2"></p>
                                        <p v-html="Contrato.itiDescricao1"></p>
                                        <p v-html="Contrato.itiDescricao2"></p>
                                        <p v-html="Contrato.itiDescricao3"></p>
                                        <p v-html="Contrato.itiDescricao4"></p>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row style="padding-top: 30px;">
                                <v-col cols="12" md="12" style="text-align:right;">
                                    <router-link to="/login" class="btn btn-danger" style="margin-right: 15px;color:#ffffff;">
                                        Não concordo
                                    </router-link>
                                    <button type="button" class="btn btn-success" @click="AddImobiliaria()">
                                        Aceito Termos
                                    </button>
                                </v-col>
                            </v-row>
                         </v-window-item>
                    </v-window>
                 </div>
            </div>
        </div>
      </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { useRoute } from 'vue-router';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import html2pdf from 'html2pdf.js';

export default {
  name: 'PgtoContratacaoClienteView',
  components: {
    moment
  },
  setup() {
        const route = useRoute();
        const parceiroId = route.params.parId;
        const nome = route.params.nome;
        return {
          parceiroId,
        };
    },
  data: () => ({
    accordion: false,
    NomeTela: 'Cadastro de Nova Imobiliária',
    bloqueiaCampos:false,
    tab: null,
    mostraEndereco: false,
    mostraconta: false,
    mostraaceite:false,
    ListParceiros:[],
    ParcSelected:[],
     //dados
     Clientes: {
            cliId: 0,
            cctId: "",
            cliVisivel: '',
            cliDataCadastro: '',
            cliNomeEmpresa: '',
            cliRazaoSocial: '',
            cliCnpj: '',
            cliCpf: '',
            cliTelefone: '',
            cliWhatsapp: '',
            cliResponsavel: '',
            cliEmail: '',
            cliSenha: '',
            cliAtivo: '',
            cliDataNascimento: '',
            cliCampoExtra1: '',
            cliCampoExtra2: '',
            cliCampoExtra3: '',
            cliCampoExtra4: '',
            cliCampoExtra5: '',
            cliTexto1: '',
            cliTexto2: '',
            cliTexto3: '',
            cliData1: '',
            cliData2: '',
        },
        ListClientes: [],
        StatusVisivel: [{ nome: "Sim", cliVisivel: true }, { nome: "Não", cliVisivel: false }],
        StatusAtivo: [{ nome: "Sim", cliAtivo: true }, { nome: "Não", cliAtivo: false }],
        //dados
        TipoClientes:[],
        senhaVisivel: false,
        isEmailValid:false,
        emailRules: [
        (v) => !!v || "E-mail é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail deve ser válido",
      ],
        //endereco
        ListEndereco:[],
        showend:false,
        Endereco: {
            cceId: 0,
            grtId: '',
            cdtId:'',
            idiId:1,
            cliEndereco: '',
            cliNumero:'',
            cliComplemento:'',
            cliObsEndereco:'',
            cliCep:'',
            cliPais:'Brasil',
            cliEstado:'',
            cliCidade:'',
            cliBairro:'',
            cliId:'',
        },
        //contas
        ListContas:[],
        showconta:false,
        Contas: {
            cdcId:0,
            cliId:'',
            cdcBanco:'',
            cdcBancoNome:'',
            cdcAgencia:'',
            cdcConta:'',
            cdcContaTitular:'',
            cdcTitularDocumento:'',
            cdcChavePix:'',
            cdcTipoConta:'',
        },
        //Usuarios
        Usuarios:[],
        Usuario: {
            cusId: 0,
            cusNome: '',
            cusEmail: '',
            cusTelefone: '',
            cusWhatsapp: '',
            cusSenha: '',
            cusDtCadastro: null,
            tusId: '',
            cusAtivo: '',
            cusUrlImagem: '',
            cliId:''
        },
        TelasImobAcesso:[
            {
                "cdtId": 1,
                "tusId": 0,
                "cusTipo": 1
            }, 
            {
                "cdtId": 34,
                "tusId": 0,
                "cusTipo": 1
            },
            {
                "cdtId": 17,
                "tusId": 0,
                "cusTipo": 1
            },
            {
                "cdtId": 28,
                "tusId": 0,
                "cusTipo": 1
            },
            {
                "cdtId": 32,
                "tusId": 0,
                "cusTipo": 1
            },
            {
                "cdtId": 35,
                "tusId": 0,
                "cusTipo": 1
            },
            {
                "cdtId": 36,
                "tusId": 0,
                "cusTipo": 1
            },
        ],
    DadosGarantidora:[],
    ListContratos:[],
    ContEnts:[],
    Contrato:{},
    //contratoscliente
    ContratoAceite:{
      ctrId: 0,
      ctrContratoCod: '',
      ctrContratoModelo: '',
      ctrContratoTexto: '',
      ctrDataCadastro: '',
      ctrUrlContrato: '',
      cliId: '',
      ctrAceiteIP: '',
      ctrAceiteData: '',
    }
  }),
  methods: {
    formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
    },
    formattedDate(value) {
        if (value != null || value != "") {
            return this.$moment(value).format('DD/MM/YYYY')
        }
        else {
            return "";
        }

    },
    LimpaCampo(value){
        if (value == 1){
        this.Clientes.cliCnpj = '';
        }
        else{
        this.Clientes.cliCpf = '';
        }
    },
    AddDados(){  

           if (this.Clientes.cctId == '') {
                this.$mensagemAviso("Campo Tipo Obrigatório.")
            }
            else if (this.Clientes.cliNomeEmpresa == '' && this.Clientes.cctId == 1) {
                this.$mensagemAviso("Campo Nome Imobiliária Obrigatório.")
            }
            else if (this.Clientes.cliCpf == '' && this.Clientes.cctId == 1) {
                this.$mensagemAviso("Campo CPF Obrigatório.")
            }
            else if (this.Clientes.cliNomeEmpresa == '' && this.Clientes.cctId == 2) {
                this.$mensagemAviso("Campo Nome Empresa Obrigatório.")
            }
            else if (this.Clientes.cliCnpj == '' && this.Clientes.cctId == 2) {
                this.$mensagemAviso("Campo CNPJ Obrigatório.")
            }
            else if (this.Clientes.cliRazaoSocial == '' && this.Clientes.cctId == 2) {
                this.$mensagemAviso("Campo Razão Social Obrigatório.")
            }
            else if (this.Clientes.cliWhatsapp === '' || this.cliWhatsapp === null) {
                this.$mensagemAviso("Campo Whatsapp Obrigatório.")
            }
            else if (this.Clientes.cliEmail === '' || this.cliEmail === null) {
                this.$mensagemAviso("Campo E-mail Obrigatório.")
            }
            else if (this.Clientes.cliCampoExtra1 === '' || this.cliCampoExtra1 === null){
                    this.$mensagemAviso("Campo CRECI Obrigatório.")
            }
            else {

              let valida = this.ListClientes.filter(x=> ((x.cliClienteTipo.cctId == 2 && x.cliCnpj == this.Clientes.cliCnpj) || (x.cliClienteTipo.cctId == 1 && x.cliCpf == this.Clientes.cliCpf)) && x.cliId != this.Clientes.cliId)
              let validaUser = this.Usuarios.filter(x=> x.cusEmail.toLowerCase() == this.Clientes.cliEmail.toLowerCase() && x.admTipoUsuario.tusId == 3) 

            if (valida.length > 0){
                this.$mensagemAviso("Imobiliária já cadastrada com esse documento.")
            }
            else if (validaUser.length > 0) {
                this.$mensagemAviso("Já existe cadastro com esse e-mail no sistema.")
            } 
            else{
                if (this.Clientes.cliVisivel === "" || this.Clientes.cliVisivel === null) {
                        this.Clientes.cliVisivel = true;
                    }
                    if (this.Clientes.cliAtivo === "" || this.Clientes.cliAtivo === null) {
                        this.Clientes.cliAtivo = true;
                    }
                    this.Clientes.cliClienteTipo = { CctId: this.Clientes.cctId }
                    if (this.Clientes.cliDataNascimento === "" || this.Clientes.cliDataNascimento === null ) {
                        this.Clientes.cliDataNascimento = null;
                    }
                    else{
                        this.Clientes.cliDataNascimento = this.$moment(this.Clientes.cliDataNascimento).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliData1 === "" || this.Clientes.cliData1 === null) {
                        this.Clientes.cliData1 = null;
                    }
                    else{
                        this.Clientes.cliData1 = this.$moment(this.Clientes.cliData1).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliData2 === "" || this.Clientes.cliData2 === null) {
                        this.Clientes.cliData2 = null;
                    }
                    else{
                        this.Clientes.cliData2 = this.$moment(this.Clientes.cliData2).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliDataCadastro === "" || this.Clientes.cliDataCadastro === null ) {
                        this.Clientes.cliDataCadastro = this.$moment().format('YYYY-MM-DD')
                    }
                    else{
                        this.Clientes.cliDataCadastro = this.$moment(this.Clientes.cliDataCadastro).format('YYYY-MM-DD')
                    }

                    if (this.parceiroId != ''){
                        this.Clientes.cliUserPar = parseInt(this.parceiroId)
                    }
                    else{
                        this.Clientes.cliUserPar = 0;
                    }

                    this.Clientes.cliTipoCli = 67;
                    this.mostraEndereco = true;
                    this.tab = 'endereco';
               }
            }
    },
    AddEndereco(){
        if (this.Endereco.cliCep == '') {
                this.$mensagemAviso("Digite um CEP válido.")
            }
            else if (this.Endereco.cliEndereco == '' || this.Endereco.cliEndereco == null) {
                this.$mensagemAviso("Campo Endereço Obrigatório.")
            }
            else if (this.Endereco.cliNumero == '' || this.Endereco.cliNumero == null) {
                this.$mensagemAviso("Campo Número Obrigatório.")
            }
            else if (this.Endereco.cliEstado == '' || this.Endereco.cliEstado == null) {
                this.$mensagemAviso("Campo Sigla Estado Obrigatório.")
            }
            else if (this.Endereco.cliCidade === '' || this.Endereco.cliCidade === null) {
            this.$mensagemAviso("Campo Cidade Obrigatório.")
            }
            else if (this.Endereco.cliBairro === '' || this.Endereco.cliBairro === null) {
                this.$mensagemAviso("Campo Bairro Obrigatório.")
            }
            else{
                this.Endereco.cliEstado = this.Endereco.cliEstado.toUpperCase();
                this.Endereco.grtId = 0;
                this.Endereco.cdtId = 0;
                this.Endereco.cliNumero = parseInt(this.Endereco.cliNumero);
                this.mostraEndereco = true;
                this.mostraconta = true;
                this.tab = 'contas';
            
            }
    },
    AddContas(){
           if (this.Contas.cdcBancoNome == '') {
                this.$mensagemAviso("Digite Nome do Banco válido.")
            }
            else if (this.Contas.cdcAgencia == '' || this.Contas.cdcAgencia == null) {
                this.$mensagemAviso("Campo Agência Obrigatório.")
            }
            else if (this.Contas.cdcConta == '' || this.Contas.cdcConta == null) {
                this.$mensagemAviso("Campo Conta Obrigatório.")
            }
            else if (this.Contas.cdcContaTitular == '' || this.Contas.cdcContaTitular == null) {
                this.$mensagemAviso("Campo Nome Titular da conta Obrigatório.")
            }
            else if (this.Contas.cdcTitularDocumento == '' || this.Contas.cdcTitularDocumento == null) {
                this.$mensagemAviso("Campo Documento (CPF/CNPJ) do Titular da conta Obrigatório.")
            }
            else{
                this.mostraEndereco = true;
                this.mostraconta = true;
                this.mostraconta = true;
                this.mostraaceite = true;
                this.tab = 'contratos';
            }
    },
    AddImobiliaria(){

         //adiciona imobiliaria
          axios.post(`/Process/CliClientes/Add`, this.Clientes)
            .then(response => {
                if (response.data > 0) {
                        this.AddUsuario(response.data);  
                        this.Clientes.cliId =  response.data;     
                        this.Endereco.cliId = this.Clientes.cliId;
                        this.Contas.cliId = this.Clientes.cliId;

                           //adiciona endereço imobiliaria
                            axios.post(`/Process/CliClientesEndereco/Add`, this.Endereco)
                                .then(response => {
                                    if (response.data > 0) {
                                            this.Endereco.cceId = response.data;                 
                                    }
                                });

                            //adiciona dados bancarios imobiliaria
                            axios.post(`/Process/CliClientesContas/Add`, this.Contas)
                            .then(response => {
                                if (response.data > 0) {
                                        this.Contas.cdcId = response.data;                 
                                }
                            });

                            //adiciona aceite imobiliaria
                            this.ContratoAceite.ctrContratoCod = this.ContEnts[0].cteModeloContratoCod;
                            this.ContratoAceite.ctrContratoModelo = this.ContEnts[0].cteTipoContratoCod;
                            this.ContratoAceite.ctrContratoTexto = this.Contrato.itiResumo1 + "</br> " + this.Contrato.itiResumo2 + "</br> " + this.Contrato.itiDescricao1 + "</br> " + this.Contrato.itiDescricao2 + "</br> " + this.Contrato.itiDescricao3 + "</br> " + this.Contrato.itiDescricao4;
                            this.ContratoAceite.ctrDataCadastro = moment().format('YYYY-MM-DD');
                            this.ContratoAceite.ctrUrlContrato = '';
                            this.ContratoAceite.cliId = this.Clientes.cliId;
                            this.ContratoAceite.ctrAceiteData = moment().format('YYYY-MM-DD');

                            axios.post(`/Process/CliClientesContratos/Add`, this.ContratoAceite)
                            .then(response => {
                                if (response.data > 0) {
                                    setTimeout(() => {
                                        this.$router.push('/login');
                                    }, 6000);
                                }
                            });
                    }
                });
          
    },
    async AddUsuario(value) {
        this.Usuario.cusNome = this.Clientes.cliNomeEmpresa;
        this.Usuario.cusEmail = this.Clientes.cliEmail;
        this.Usuario.cusSenha = this.Clientes.cliWhatsapp.slice(-4);
        this.Usuario.cusWhatsapp = this.Clientes.cliWhatsapp;
        this.Usuario.tusId = 3;
        this.Usuario.cusAtivo = true;

        this.Usuario.admTipoUsuario = { tusId: 3 };
        this.Usuario.cliId = value;
        
        this.Usuario.cusDtCadastro = moment();
        this.Usuario.cusUrlImagem = "";

        axios.post(`/Process/UsrCadastroUsuarios/Add`, this.Usuario)
            .then(response => {
                if (response.data > 0) {
                    this.CarregaPermissao(response.data);
                    this.EnviaEmailImob(this.Usuario.cusSenha);
                }
            });
    },
    CarregaPermissao(value){
        this.TelasImobAcesso.map(element => {
                        const Permissoes2 = {
                            cusId: value,
                            cdtId: parseInt(element.cdtId),
                            cusTipo: parseInt(element.cusTipo)
                        };
                        return axios.post(`/Process/UsrControlePermissoes/Add`, Permissoes2)
                            .then(response => {
                                if (response.data > 0) {
                                    return null;
                                }
                            });
                        });
    },
    EnviaEmailImob(senha) {
        axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x=> x.mpdId == 6);
                        let _dadosEmail = {
                                        nomeDe: this.DadosGarantidora[0].itiNome,
                                        nomePara: this.Clientes.cliNomeEmpresa,
                                        assunto: 'Cadastro Sistema Aqui Garante Imobiliárias',
                                        destinatario: this.Clientes.cliEmail,
                                        emailResposta: this.DadosGarantidora[0].itiReferencia,
                                        textoEmail: this.templateEmail[0].mpdTexto
                                        .replace('[Email]',this.Clientes.cliEmail)
                                        .replace('[SenhaInicial]', senha)
                                        .replace('[LinkSistema]', 'https://aquigarante.net.br')
                                        .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>')
                                        .replace('<table>', '<table style="background-color: #F6F6F6;padding: 30px;">'),
                                        nomeAnexo: '',
                                        anexo: '',
                                        tipoAnexo: ''
                                    }
                            axios.post(`/Process/AdmControleSmtp/Enviamail`,_dadosEmail)
                            .then(response => {
                                if (response.data == 1){
                                this.$mensagemSucesso("Cadastro Efetuado, verifique seu email com dados de acesso. ");     
                                }
                            });

        });
    },
    Buscacep(value) {
        if (value == ""){
            this.$mensagemAviso("Digite um CEP válido.")
        }
        else{
            fetch("https://viacep.com.br/ws/" + value + "/json/")
            .then(response => {
                if (!response.ok) {
                    throw new Error("CEP inexistente");
                }
                return response.json();
            })
            .then(data => {
                this.Retornocep = data;
                // console.log('this.Retornocep')
                // console.log(this.Retornocep)
                if (this.Retornocep != null) {
                    this.Endereco.cliEndereco = this.Retornocep.logradouro;
                    this.Endereco.cliNumero = null;
                    this.Endereco.cliComplemento = null;
                    this.Endereco.cliEstado = this.Retornocep.uf;
                    this.Endereco.cliCidade = this.Retornocep.localidade;
                    this.Endereco.cliBairro = this.Retornocep.bairro;
                    this.showend = true;
                } else {
                    this.$mensagemAviso("CEP inexistente");
                }
            })
            .catch(error => {
                console.error(error);
            });
        }
    },
  },
  created() {

    axios.get(`/Process/CliClienteTipo/GetAll`)
        .then(response => {
            this.TipoClientes = response.data;   
            this.Clientes.cctId = 2;
            this.LimpaCampo(2);

        
    });

    //dados da Garantidora
    axios.get('/Process/InsInstitucional/institucional/34/informacoes')
    .then(response => {
        this.DadosGarantidora = response.data;
    // console.log('this.DadosGarantidora')
    // console.log(this.DadosGarantidora)
    })
    .catch(error => {
        console.error(error);
    });

    axios.get(`/Process/CliClientes/GetAll`)
        .then(response => {
        
            this.ListClientes = response.data.filter(x=> x.cliTipoCli == 67);  
            //console.log('ListClientes')
            //console.log(this.ListClientes)
        });


    axios.get('/Process/InsInstitucional/institucional/31/modeloscontratos')
        .then(response => {
            this.ListContratos = response.data;
            console.log('this.ListContratos')
            console.log(this.ListContratos)

            axios.get(`/Process/CliEntidadesContratos/GetAll`)
            .then(response => {
                this.ContEnts = response.data.filter(x=> x.cteEntidadeCod == 67);
                console.log('this.ContEnts')
                console.log(this.ContEnts)
                if (this.ContEnts!=''){
                  this.Contrato = this.ListContratos.filter(x=> x.itiId == this.ContEnts[0].cteModeloContratoCod)[0]
                  console.log('this.Contrato')
                  console.log(this.Contrato)
                }
            });
        })
        .catch(error => {
            console.error(error);
        });

 

       axios.get(`/Process/UsrCadastroUsuarios/GetAll`)
        .then(response => {
            this.Usuarios = response.data.filter(x=> x.cusId != 2);
        });

  }
}
</script>

<style>

</style>
