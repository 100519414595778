<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                     </v-tabs>
                     <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="TiposBlocos.htbId" label="Id" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="TiposBlocos.grtId" label="Grupo" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="TiposBlocos.cdtId" label="Tela" variant="underlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="vshowidioma">
                                            <v-select v-model="TiposBlocos.idiId" label="Idioma" :items="Idiomas" @update:modelValue="CarregaLista(TiposBlocos.idiId)"
                                                item-title="idiNome" item-value="idiId" variant="underlined">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="5">
                                            <v-text-field v-model="TiposBlocos.htbNomeBloco"
                                                label="Nome Bloco" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="TiposBlocos.htbVisivel" label="Visível" :items="StatusAtivo"
                                                item-title="nome" item-value="htbVisivel" variant="underlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="TiposBlocos.htbColBloco" 
                                                label="Colunas" variant="underlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="TiposBlocos.htbOrdem" 
                                                label="Ordem" variant="underlined" required></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                    </v-window>
                
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="TiposBlocos.htbId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="" v-if="MostraGrid && tab=='dados'">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="ListTiposBlocos" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-htbVisivel="item">
                            <span>{{ item.htbVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'

export default {
    name: 'HomTiposBlocosView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Tipos Blocos',
        InfoTela:'',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 29, cdtNome: "Tipos Blocos" }],
        //dados
        TiposBlocos: {
            htbId: 0,
            grtId: 0,
            cdtId: 0,
            idiId: '',
            htbNomeBloco: '',
            htbColBloco: '',
            htbVisivel: '',
            htbOrdem: '',
        },
        ListTiposBlocos: [],
        StatusAtivo: [{ nome: "Sim", htbVisivel: true }, { nome: "Não", htbVisivel: false }],
        //dados
        ListTiposBlocosGeral:[],
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListTiposBlocos = this.ListTiposBlocosGeral.filter(x=> x.idiId == this.TiposBlocos.idiId);
        },
        Limpar() {
            this.TiposBlocos.htbId=0;
            this.TiposBlocos.grtId=0;
            this.TiposBlocos.cdtId=0;
            this.TiposBlocos.htbNomeBloco="";
            this.TiposBlocos.htbColBloco="";
            this.TiposBlocos.htbVisivel="";
            this.TiposBlocos.htbOrdem="";
            this.tab = 'dados';
            this.Get();
        },
        Add() {
               if (this.TiposBlocos.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.TiposBlocos.htbNomeBloco === '') {
                    this.$mensagemAviso("Campo Nome Bloco Obrigatório.")
                }
                else if (this.TiposBlocos.htbColBloco === '') {
                    this.$mensagemAviso("Campo Coluna Obrigatório.")
                }
                else {
                    if (this.TiposBlocos.htbVisivel === "" || this.TiposBlocos.htbVisivel === null) {
                        this.TiposBlocos.htbVisivel = true;
                    }

                    if (this.TiposBlocos.htbOrdem === "" || this.TiposBlocos.htbOrdem === null) {
                        this.TiposBlocos.htbOrdem = 0;
                    }
                    else{
                        this.TiposBlocos.htbOrdem = parseInt(this.TiposBlocos.htbOrdem,10);
                    }

                    if (this.TiposBlocos.htbColBloco === "" || this.TiposBlocos.htbColBloco === null) {
                        this.TiposBlocos.htbColBloco = 0;
                    }
                    else{
                        this.TiposBlocos.htbColBloco = parseInt(this.TiposBlocos.htbColBloco,10);
                    }

                    axios.post(`/Process/HomTipoBloco/Add`, this.TiposBlocos)
                        .then(response => {
                            if (response.data > 0) {
                                    this.TiposBlocos.ecoId = response.data;
                                    this.$mensagemAvisoSucesso("Registro Incluído.")
                                    this.Get();
                                    this.Limpar();
                                    this.tab = 'dados';                        
                            }
                        });
            }
        },
        Put() {
            if (this.TiposBlocos.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.TiposBlocos.htbNomeBloco === '') {
                    this.$mensagemAviso("Campo Nome Bloco Obrigatório.")
                }
                else if (this.TiposBlocos.htbColBloco === '') {
                    this.$mensagemAviso("Campo Coluna Obrigatório.")
                }
                else {
                    if (this.TiposBlocos.htbVisivel === "" || this.TiposBlocos.htbVisivel === null) {
                        this.TiposBlocos.htbVisivel = true;
                    }

                    if (this.TiposBlocos.htbOrdem === "" || this.TiposBlocos.htbOrdem === null) {
                        this.TiposBlocos.htbOrdem = 0;
                    }
                    else{
                        this.TiposBlocos.htbOrdem = parseInt(this.TiposBlocos.htbOrdem,10);
                    }

                    if (this.TiposBlocos.htbColBloco === "" || this.TiposBlocos.htbColBloco === null) {
                        this.TiposBlocos.htbColBloco = 0;
                    }
                    else{
                        this.TiposBlocos.htbColBloco = parseInt(this.TiposBlocos.htbColBloco,10);
                    }
             
                axios.put(`/Process/HomTipoBloco/Update`, this.TiposBlocos)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                            this.tab = 'dados';
                        }
                    });
            }
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.htbId;
                axios.delete(`/Process/HomTipoBloco/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                        this.tab = 'dados';
                    }
                });
            }
            });
        },
        EditGrid(item) {
            this.tab = 'dados';
            this.TiposBlocos = item;
            this.$scrollToTop();
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "htbId" });
            this.headers.push({ text: "Nome Bloco", value: "htbNomeBloco" });
            this.headers.push({ text: "Colunas", value: "htbColBloco" });
            this.headers.push({ text: "Visivel", value: "htbVisivel" });
            this.headers.push({ text: "Ordem", value: "htbOrdem" });
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.TiposBlocos.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }
                    axios.get(`/Process/HomTipoBloco/GetAll`)
                        .then(response => {
                            this.ListTiposBlocosGeral = response.data; 
                            if (this.TiposBlocos.idiId != ''){
                                this.CarregaLista();
                            }                           
                        });
                    this.montaGrid();
                    this.TiposBlocos.cdtId = 29;
                    this.TiposBlocos.grtId = 8;

                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
