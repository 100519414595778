<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Configura galerias de Arquivos por tela</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="ConfigArq.plaId" label="Id" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" v-if="ListaTelas!=''">
                                    <v-select v-model="ConfigArq.cdtId" label="Telas" :items="ListaTelas"
                                        item-title="cdtNome" item-value="cdtId" variant="outlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="ConfigArq.plaNome" :rules="nameRules" :counter="200"
                                        label="Nome Galeria" variant="outlined" required></v-text-field>
                                </v-col>

                                <v-col cols="12" md="2">
                                    <v-text-field v-model="ConfigArq.plaOrdem" label="Ordem" type="number"
                                        variant="outlined"></v-text-field>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-select v-model="ConfigArq.plaVisivel" label="Visível" :items="Status"
                                        item-title="nome" item-value="plaVisivel" variant="outlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field v-model="ConfigArq.plaPesoMb" label="Peso em MB" type="number"
                                        variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-text-field v-model="ConfigArq.plaQtd" label="Quantidade" type="number"
                                        variant="outlined"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="ConfigArq.plaId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="outlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="preprocessData(ConfigArqs)" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-plaVisivel="item">
                            <span>{{ item.plaVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-plaOrdem="item">
                            <span>{{ parseInt(item.plaOrdem) }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'ConfigListaArquivosTelasView',
    components: {
        EasyDataTable
    },
    data: () => ({
        ConfigArq: {
            plaId: 0,
            cdtId: '',
            plaNome: '',
            plaOrdem: '',
            plaVisivel: '',
            plaQtd: '',
            plaPesoMb: '',
        },
        ConfigArqs: [],
        ListaTelas:[{ cdtId: 14, cdtNome: "Produtos" }],
        selectedVisivel: null,
        Status: [{ nome: "Sim", plaVisivel: true }, { nome: "Não", plaVisivel: false }],
        nameRules: [
            value => {
                if (value?.length <= 200) return true
                return 'Nome pode conter até 200 caracteres.'
            },
        ],

        headers: [
            { text: "Id", value: "plaId", sortable: true, },
            { text: "Tela", value: "admCadastroTelas.cdtNome", sortable: true, },
            { text: "Nome", value: "plaNome", sortable: true, },
            { text: "Ordem", value: "plaOrdem", sortable: true, },
            { text: "Visivel", value: "plaVisivel" },
            { text: "Qtde", value: "plaQtd" },
            { text: "Peso Max", value: "plaPesoMb" },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
    }),
    methods: {
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                plaOrdem: parseInt(item.plaOrdem),
            };
            });
        },
        Limpar() {
            this.ConfigArq.plaId = 0;
            this.ConfigArq.cdtId = "";
            this.ConfigArq.plaNome = "";
            this.ConfigArq.plaOrdem = "";
            this.ConfigArq.plaVisivel = "";
            this.ConfigArq.plaPesoMb = "";
            this.ConfigArq.plaQtd = "";
            this.selectedVisivel = "";
            this.Get();
        },
        Add() {
            if (this.ConfigArq.plaNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.ConfigArq.plaOrdem === ''){
                this.$mensagemAviso("Ordem Obrigatória.")
            }
            else if (this.ConfigArq.plaVisivel === ''){
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else if (this.ConfigArq.cdtId == ''){
                this.$mensagemAviso("Tela obrigatória.")
            }
            else if (this.ConfigArq.plaPesoMb == ''){
                this.$mensagemAviso("Peso Obrigatório.")
            }
            else if (this.ConfigArq.plaQtd === ''){
                this.$mensagemAviso("Quantidade Obrigatório.")
            }
            else {
                this.ConfigArq.plaOrdem = parseInt(this.ConfigArq.plaOrdem)
                this.ConfigArq.plaPesoMb = parseInt(this.ConfigArq.plaPesoMb)
                this.ConfigArq.plaQtd = parseInt(this.ConfigArq.plaQtd)
                this.ConfigArq.AdmCadastroTelas = { cdtId: this.ConfigArq.cdtId};
                axios.post(`/Process/ProListaGaleriaArquivos/Add`, this.ConfigArq)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        Put() {
            if (this.ConfigArq.plaNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.ConfigArq.plaOrdem === ''){
                this.$mensagemAviso("Ordem Obrigatória.")
            }
            else if (this.ConfigArq.plaVisivel === ''){
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else if (this.ConfigArq.cdtId == ''){
                this.$mensagemAviso("Tela obrigatória.")
            }
            else if (this.ConfigArq.plaPesoMb == ''){
                this.$mensagemAviso("Largura Obrigatório.")
            }
            else if (this.ConfigArq.plaQtd === ''){
                this.$mensagemAviso("Quantidade Obrigatório.")
            }
            else {
                this.ConfigArq.plaOrdem = parseInt(this.ConfigArq.plaOrdem)
                this.ConfigArq.plaPesoMb = parseInt(this.ConfigArq.plaPesoMb)
                this.ConfigArq.plaQtd = parseInt(this.ConfigArq.plaQtd)
                this.ConfigArq.AdmCadastroTelas = { cdtId: this.ConfigArq.cdtId};
                axios.put(`/Process/ProListaGaleriaArquivos/Update`, this.ConfigArq)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        EditGrid(item) {
            // Lógica para editar o item
            this.ConfigArq = item;
            this.ConfigArq.cdtId = item.admCadastroTelas.cdtId
            this.$scrollToTop();
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.plaId;
                axios.delete(`/Process/ProListaGaleriaArquivos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                    }
                });
            }
            });
        },
        Get() {
                axios.get(`/Process/ProListaGaleriaArquivos/GetAll`)
                .then(response => {
                    this.ConfigArqs = response.data;
                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
