<template>
    <div>
        <Loader v-if="isLoading"></Loader>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;">
                                <h4 class="text-black h4" style="padding-right: 5px;">{{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#00203b">
                        <v-tab value="dados" class="tabinfo" style="color: #ffffff !important;">Nova Contratação</v-tab>
                    </v-tabs>
                    <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="6">
                                            <v-card color="#f7f7f7" style="padding: 15px;">
                                                <v-row style="padding-bottom:20px;">
                                                    <v-col cols="12">
                                                        <v-row>
                                                            <v-col cols="12" md="12">
                                                                <v-combobox v-model="selectedImobiliaria" label="Selecione Imobiliária" :items="ListImobiliarias" disabled="true"
                                                                    searchable
                                                                    clearable
                                                                    auto-select-first="exact"
                                                                    hide-selected
                                                                    @update:model-value="SelecionarConsumidor()"
                                                                    item-title="nomeConcatenado" item-value="cliId" variant="outlined">
                                                                </v-combobox>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="12" md="12" v-if="selectedImobiliaria!='' && selectedImobiliaria != null">
                                                                <v-combobox v-model="selectedConsumidor" label="Selecione Cliente já cadastrado" :items="ListConsumidor"
                                                                    searchable
                                                                    clearable
                                                                    auto-select-first="exact"
                                                                    hide-selected
                                                                    item-title="nomeConcatenado" item-value="cliId" variant="outlined">
                                                                </v-combobox>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row>
                                                            <v-col cols="12" md="12" v-if="selectedConsumidor=='' || selectedConsumidor == null">
                                                                <a href="" @click.prevent="dialogCliente=true" class="btn btn-success">
                                                                    Cadastrar Cliente
                                                                </a>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-card>
                                            <v-card color="#f7f7f7" style="padding: 15px;margin-top: 20px;" v-if="endereco!='' && tipoImovel !=''">
                                                <v-item-group v-model="ProdutoSelected">
                                                        <v-container>
                                                            <v-row>
                                                                <h5 class="subtitulo" style="padding-bottom: 15px;padding-left:10px;">Selecione o produto</h5>
                                                            </v-row>
                                                        <v-row>
                                                            <v-col v-for="mens in ListProdutos" :key="mens.proId" cols="12" md="6">
                                                            <v-item>
                                                                <v-card 
                                                                class="d-flex align-center" 
                                                                dark height="230" 
                                                                @click="toggle(mens)"
                                                                :class="{ 'border-green': ProdutoSelected === mens }"
                                                                >
                                                                <div class="flex-grow-1 text-center" style="justify-content: center;">
                                                                    <img :src="$imgURL + mens.proProdutos.proCampoExtra10" style="height: 150px;" class="mx-auto" />
                                                                    {{ mens.nomeConcatenado }}
                                                                </div>
                                                                <v-icon v-if="ProdutoSelected === mens" class="check-icon">mdi-check</v-icon>
                                                                </v-card>
                                                            </v-item>
                                                            </v-col>
                                                        </v-row>
                                                        </v-container>
                                                    </v-item-group>
                                            </v-card>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                              <v-card color="#f7f7f7" style="padding: 15px;">
                                                <v-row style="line-height: 0;">
                                                    <v-col cols="12" v-if="endereco=='' || endereco==null">
                                                        <br><button class="btn btn-info" @click.prevent="dialogEndereco = true">Adicionar Endereço da Contratação</button>
                                                    </v-col>
                                                    <v-col cols="12" v-else>
                                                        <br><button class="btn btn-info" @click.prevent="dialogEndereco = true">Editar Endereço da Contratação</button>
                                                    </v-col>
                                                </v-row>
                                                <v-row v-if="endereco!='' && endereco!=null">
                                                    <v-col cols="12" style="line-height: 0;">
                                                       <small><strong>Endereço:</strong> {{ endereco }} - {{  numeroend }} - {{ complemento }}</small> 
                                                    </v-col>
                                                    <v-col cols="12" style="line-height: 0;">
                                                        <small><strong>CEP: </strong>{{ numCep }}</small>
                                                    </v-col>
                                                    <v-col cols="12" style="line-height: 0;">
                                                        <small><strong>Cidade:</strong> {{ nomeCidade }} / {{  siglaEstado }} - {{  nomeBairro }}</small>
                                                    </v-col>
                                                    <v-col cols="12" style="line-height: 0;">
                                                        <small><strong>Tipo Imóvel:</strong> {{ tipoImovel }} {{ residencialTipo }}</small>
                                                    </v-col>
                                               </v-row>
                                              </v-card>
                                              <v-card color="#f7f7f7" style="padding: 15px;margin-top: 20px;" v-if="ListaPedidoItem!=''" >
                                                  <v-row style="padding: 10px;">
                                                       <div style="padding-top: 20px;min-height: 350px;">
                                                        <h5 class="subtitulo" style="padding-bottom: 15px;padding-left:10px;">Itens da contratação</h5>
                                                        <div v-for="(produto,index) in ListaPedidoItem" :key="produto.pdiId"
                                                            class="descricao-pedido border-top py-3">
                                                            <div class="row">
                                                                <div class="col-6 col-md-3 d-flex align-items-center">
                                                                    <div class="descricao-content">
                                                                        <h6 class="subtitulo">{{ produto.nomeConcatenado}}</h6>
                                                                        <p class="texto-mensagem"> Código: {{ produto.proProdutos.proId }}</p>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-5 d-flex align-items-center">
                                                                 <small>Vigência de {{ formattedDate(produto.vigenciaini) }} até {{ formattedDate(produto.vigenciafim) }} </small>
                                                                </div>
                                                                <div class="col-6 col-md-3 d-flex align-items-center">
                                                                    <div class="descricao-content">
                                                                        <h6 class="subtitulo" style="font-size: 15px;">
                                                                            R$ {{
                                                                                parseFloat(produto.valorTotalProduto).toFixed(2).toString().replace('.', ',')
                                                                            }}</h6>
                                                                    </div>
                                                                </div>
                                                                <div class="col-6 col-md-1 d-flex align-items-center">
                                                                    <a href="#" data-toggle="tooltip" data-placement="top" title="Excluir" @click.prevent="ExcluiProduto(index)">
                                                                        <i class="fa fa-window-close" style="color: black;font-size: 13px;"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        </div>
                                                  </v-row>
                                               </v-card>
                                               <v-card color="#f7f7f7" style="padding: 15px;margin-top: 20px;" v-if="ListaPedidoItem!=''">
                                                  <v-row>
                                                    <v-col cols="12" md="12">
                                                            <div class="d-flex align-items-center justify-content-between">
                                                                <h5 class="subtitulo">Total</h5>
                                                                <h6 class="subtitulo">R$ {{
                                                                    parseFloat(TotalPedido).toFixed(2).toString().replace('.', ',')
                                                                }}</h6>
                                                            </div>
                                                    </v-col>
                                                  </v-row>
                                                </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="6" style="text-align: right;">
                                            <button type="button" @click="LimparMemoria(1)" class="btn btn-link">
                                                Limpar
                                            </button>
                                        </v-col>
                                        <v-col cols="12" md="6" style="text-align: left;" v-if="ListaPedidoItem !=''">
                                            <button type="button" class="btn btn-success" @click="GerarPedido()">
                                                Enviar Contratação para Análise
                                            </button>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>

                        </v-window-item>
                    </v-window>
                </div>
            </div>
        </div>
    <!--modal endereco-->
    <v-row justify="center">
        <v-dialog v-model="dialogEndereco" class="controlcelEnd" transition="dialog-bottom-transition"
          >
            <v-card>
                <v-card-title style="background-color: #cccccc;">
                <span class="text-h5" style="font-size: 19px !important;">Cadastrar Endereço do imóvel</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px">
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="5">
                                    <v-text-field class="input" v-model="numCep" v-mask="'#####-###'"
                                        type="text" variant="outlined" placeholder="CEP"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-btn @click.prevent="Buscacep(numCep)"
                                        class="btn px-md-5 mt-0 mb-3">Verificar</v-btn>
                                </v-col>
                                <v-col cols="12" md="5" v-show="showend">
                                    <v-text-field class="input" v-model="endereco" type="text"
                                        variant="outlined" placeholder="Endereço"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2" v-show="showend">
                                    <v-text-field class="input" v-model="numeroend" type="text"
                                        variant="outlined" placeholder="Número"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2" v-show="showend">
                                    <v-text-field class="input" v-model="complemento" type="text"
                                        variant="outlined" placeholder="Complemento"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" v-show="showend">
                                    <v-text-field class="input" v-model="nomeCidade" type="text"
                                        variant="outlined" placeholder="Cidade"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2" v-show="showend">
                                    <v-text-field class="input" v-model="siglaEstado" v-mask="'AA'"
                                        type="text" variant="outlined" placeholder="UF"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" v-show="showend">
                                    <v-text-field class="input" v-model="nomeBairro" type="text"
                                        variant="outlined" placeholder="Bairro"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" v-show="showend">
                                    <strong>Tipo do Imóvel </strong>
                                    <v-radio-group v-model="tipoImovel"
                                        style="display: inline-flex;flex-direction: row;">
                                        <v-radio :label="'Comercial'" value="Comercial" style="width: 280px;"></v-radio>
                                        <v-radio :label="'Residencial'" value="Residencial"
                                            style="width: 280px;"></v-radio>
                                    </v-radio-group>
                                </v-col>
                                <v-col cols="12" md="3" v-show="showend && tipoImovel == 'Residencial'">
                                    <strong>Tipo do Imóvel Residencial </strong>
                                    <v-radio-group v-model="residencialTipo"
                                        style="display: inline-flex;flex-direction: row;">
                                        <v-radio :label="'Casa'" value="Casa" style="width: 280px;"></v-radio>
                                        <v-radio :label="'Apartamento'" value="Apartamento"
                                            style="width: 280px;"></v-radio>
                                    </v-radio-group>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions v-show="showend">
                <v-spacer></v-spacer>
                     <v-btn class="btn btn-success" @click.prevent="ValidaEndereco()">Confirmar Endereço</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

    <!--modal produto garantia-->
     <v-row justify="center">
        <v-dialog v-model="dialogProdutoGarantia" class="controlcelEnd" transition="dialog-bottom-transition"
          >
            <v-card>
                <v-card-title style="background-color: #cccccc;">
                <span class="text-h5" style="font-size: 19px !important;" >Produto Garantia {{  tipoImovel }}</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px">
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="6">
                                  <v-row v-for="(grids,index) in ProdutosGrid" :key="index" style="line-height:0 !important">
                                     <v-col cols="12" md="4" class="d-flex align-center">
                                        {{grids.pgsNome}}
                                     </v-col>
                                     <v-col cols="12" md="6">
                                        <v-text-field class="input" v-model="grids.pgsCampoExtra1" type="text"
                                           variant="outlined" placeholder="Valor R$ "></v-text-field>
                                     </v-col>
                                  </v-row>
                                </v-col>
                                <v-col cols="12" md="6">
                                  <v-row>
                                     <v-col cols="12" md="12">
                                        <small>Selecione o plano de Setup da Imobiliária:</small>
                                        <v-radio-group v-model="PlanosGarantiasSelected"
                                            style="display: inline-flex;flex-direction: cols;">
                                            <v-radio v-for="plan in PlanosGarantias" :key="plan.itiId" :label="plan.itiNome + ' - R$ ' + formatarValor(parseFloat(plan.itiReferencia))" :value="plan.itiId" style="width: 280px;"></v-radio>
                                        </v-radio-group>
                                     </v-col>
                                     <v-col cols="12" md="12">
                                        <small v-if="ProdutosTaxas!='' && PlanosGarantiasSelected != ''">Resultado</small>
                                        <v-card color="#f7f7f7" style="padding: 15px;margin-top: 20px;" v-if="ProdutosTaxas!='' && PlanosGarantiasSelected != ''">
                                            Pacote {{  DadosGarantidora[0].itiNome }} : R$ {{ formatarValor(ValorTotalComputed * (ProdutosTaxas[0].ppcValorDe/100)) }}<br>
                                            Taxa Setup: R$ {{ formatarValor(parseFloat(PlanosGarantias.filter(x=> x.itiId == PlanosGarantiasSelected)[0].itiReferencia)) }}<br>
                                            <strong>Valor Total: R$ {{ formatarValor(parseFloat(ValorTotalComputed * (ProdutosTaxas[0].ppcValorDe/100)) + parseFloat(PlanosGarantias.filter(x=> x.itiId == PlanosGarantiasSelected)[0].itiReferencia)) }}  </strong>
                                        </v-card>
                                        <small>* Todas os valores informados são de responsabilidade da imobiliária parceira.</small>
                                    </v-col>
                                  </v-row>
                                </v-col> 
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions v-show="showend">
                <v-spacer></v-spacer>
                     <v-btn class="btn btn-success" @click.prevent="AdicionarGarantia()">Adicionar Garantia</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>

      <!--modal Cliente-->
      <v-row justify="center">
        <v-dialog v-model="dialogCliente" persistent class="controlcelEnd" transition="dialog-bottom-transition"
          >
            <v-card>
                <v-card-title style="background-color: #cccccc;">
                <span class="text-h5" style="font-size: 19px !important;" >Cadastrar Cliente</span>
                </v-card-title>
                <v-card-text style="padding: 16px 0px 10px">
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="Clientes.cliId" label="Id" variant="outlined"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" md="3">
                                    <v-select v-model="Clientes.cctId" label="Tipo" :items="TipoClientes" @update:modelValue="LimpaCampo(Clientes.cctId)"
                                        item-title="cctNome" item-value="cctId" variant="outlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="Clientes.cliNomeEmpresa"
                                        label="Nome" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" v-if="Clientes.cctId == 1">
                                    <v-text-field v-model="Clientes.cliCpf" v-mask="'###.###.###-##'" 
                                        label="CPF" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="2" v-show=false>
                                    <v-select v-model="Clientes.cliVisivel" label="Visível" :items="StatusVisivel" 
                                        item-title="nome" item-value="cliVisivel" variant="outlined">
                                    </v-select>
                                </v-col>                                     
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="Clientes.cliTelefone" v-mask="'(##) ####-#####'"
                                        label="Telefone" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="Clientes.cliWhatsapp" v-mask="'(##) ####-#####'"
                                        label="Whatsapp" variant="outlined" required></v-text-field>
                                </v-col>
                                
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="Clientes.cliEmail" label="E-mail" 
                                    variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" style="display: none;">
                                    <v-text-field v-model="Clientes.cliSenha" :type="senhaVisivel ? 'text' : 'password'"
                                        label="Senha" variant="outlined"
                                        :append-icon="senhaVisivel ? 'mdi-eye-off' : 'mdi-eye'"
                                        @click:append="toggleSenhaVisivel"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" v-if="Clientes.cctId == 1" style="display: none;"> 
                                    <v-text-field v-model="Clientes.cliDataNascimento" type="date"
                                        label="Data Nascimento" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3" v-if="Clientes.cctId == 2">
                                    <v-text-field v-model="Clientes.cliCnpj" v-mask="'##.###.###/####-##'"
                                        label="CNPJ" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="5" v-if="Clientes.cctId == 2">
                                    <v-text-field v-model="Clientes.cliRazaoSocial"
                                        label="Razão Social" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" v-if="Clientes.cctId == 2">
                                    <v-text-field v-model="Clientes.cliResponsavel" 
                                        label="Responsável" variant="outlined" required></v-text-field>
                                </v-col>

                                <v-col cols="12" md="2" style="display: none;">
                                    <v-text-field v-model="Clientes.cliCampoExtra1"
                                        label="CRECI" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="Clientes.cliDataCadastro" type="date" :disabled="Clientes.cliId !== 0" v-show=false
                                        label="Cadastro" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" style="display: none;">
                                    <v-text-field v-model="Clientes.cliCampoExtra2"
                                        label="Onde Conheceu" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" style="display: none;">
                                    <v-text-field v-model="Clientes.cliCampoExtra3"
                                        label="Campo Extra 3" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" style="display: none;">
                                    <v-text-field v-model="Clientes.cliCampoExtra4"
                                        label="Campo Extra 4" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" style="display: none;">
                                    <v-text-field v-model="Clientes.cliCampoExtra5"
                                        label="Campo Extra 5" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" style="display: none;">
                                    <v-text-field v-model="Clientes.cliData1" type="date"
                                        label="Data 1" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" style="display: none;">
                                    <v-text-field v-model="Clientes.cliData2" type="date"
                                        label="Data 2" variant="outlined" required></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-card-text>
                <v-card-actions style="text-align:right;">
                <v-spacer></v-spacer>
                <button type="button" @click="dialogCliente=-false" class="btn btn-danger" style="margin-right: 15px;color:#ffffff;">
                    Cancelar
                </button>
                <button type="button" class="btn btn-success" @click="AddCliente()">
                    Salvar
                </button>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'
import Loader from '../../components/PageLoader.vue'
import { useRoute } from 'vue-router';
import { SHA256 } from 'crypto-js';


export default {
    name: 'NovaContratacaoView',
    setup() {
    const route = useRoute();
    const id = route.params.idCli;
    return {
      id
    };
  },
    components: {
        EasyDataTable,
        moment,
        Loader
    },
    data: () => ({
        User:{},
        Usuarios:[],
        tab: null,
        NomeTela: 'Contratação',
        InfoTela: 'Nova Contratação',
        CadastroTelas: [{ cdtId: 28, cdtNome: "Contratação" }],
        //cli geral
        ListCliGeral: [],
        //Imobiliarias
        selectedImobiliaria: null,
        ListImobiliarias: [],
        //Imobliarias
        //consumidor
        selectedConsumidor:null,
        ListConsumidor:[],
        Cliente:{},
        //consumidor
        //endereco
        dialogEndereco: false,
        endereco: '',
        numeroend: '',
        complemento: '',
        siglaEstado: '',
        nomeCidade: '',
        nomeBairro: '',
        numCep: '',
        Observacoes: '',
        Retornocep: {},
        showend: false,
        tipoImovel:'',
        residencialTipo:'',
        //endereco
        //pedido
          Pedidos: [],
          PedidosCli: [],
          TotalPedido: 0,
          ValorDesconto:0,
          ValorFrete:0,
          ListaPedidoItem: [],
          ListaPedidoItemAdicional: [],
          Situacoes: [],
        //pedido
        //produtos
        ProdutoSelected: '',
        ListProdutosGeral:[],
        ListProdutos:[],
        selectedItemProduto: null,
        dialogProdutoGarantia:false,
        dialogProdutoSeguro:false,
        ProdutosGrid:[],
        PlanosGarantiasSelected:'',
        PlanosGarantias:[],
        ProdutosTaxas:[],
        ValorTotal:0,
        //produtos
        DadosCompra: {
            imobiliaria:[],
            consumidor:[],
            produtos: [],
            mensagem: [],
            agenda: [],
            foto: [],
            avulsos: [],
            baloes: [],
            informacao: [],
            pagamento: [],
            cupom: [],
        },
        linkPagamento: null,
        //pagamentos
        //cliente
        dialogCliente: false,
        Clientes: {
            cliId: 0,
            cctId: "",
            cliVisivel: '',
            cliDataCadastro: '',
            cliNomeEmpresa: '',
            cliRazaoSocial: '',
            cliCnpj: '',
            cliCpf: '',
            cliTelefone: '',
            cliWhatsapp: '',
            cliResponsavel: '',
            cliEmail: '',
            cliSenha: '',
            cliAtivo: '',
            cliDataNascimento: '',
            cliCampoExtra1: '',
            cliCampoExtra2: '',
            cliCampoExtra3: '',
            cliCampoExtra4: '',
            cliCampoExtra5: '',
            cliTexto1: '',
            cliTexto2: '',
            cliTexto3: '',
            cliData1: '',
            cliData2: '',
            cliIdPai: '',
            cliTipoCli:69,
            cliUserPar:0,
        },
        ListClientes: [],
        StatusVisivel: [{ nome: "Sim", cliVisivel: true }, { nome: "Não", cliVisivel: false }],
        StatusAtivo: [{ nome: "Sim", cliAtivo: true }, { nome: "Não", cliAtivo: false }],
        TipoClientes:[],
        //cliente
       DadosGarantidora:[],
       DadosConfiguracao:[],
       isLoading: false,
       RetornoSerasaAntigo:null,
       PedidosCli:[],
       PropostaAndamento:[],
       PropostaVigMenor:[],
    }),
    methods: {
        startLoading() {
            this.isLoading = true;
        },
        stopLoading() {
            this.isLoading = false;
        },
        //consumidor
        SelecionarConsumidor(){
            if (this.selectedImobiliaria != '' && this.selectedImobiliaria != null){
                this.selectedConsumidor = null;
                this.ListConsumidor = this.ListCliGeral.filter(x=> x.cliTipoCli == 69 && x.cliIdPai == this.selectedImobiliaria.cliId);  //consumidor
                        this.ListConsumidor = this.ListConsumidor.map(item => {
                            return {
                                ...item,
                                nomeConcatenado: `${item.cliId} - ${item.cliNomeEmpresa} - ${item.cliCpf == "" ? item.cliCnpj : item.cliCpf}`
                            };
                        });
                if (this.id != null && this.id != '')
                {
                     this.selectedConsumidor = this.ListConsumidor.filter(x=> x.cliId == this.id)[0];
                     this.dialogEndereco = true;
                }
            }
            //console.log('Produto Adicional Selecionado:', this.selectedImobiliaria);
        },
        //consumidor
        //endereco
        Buscacep(value) {
            if (this.numCep != ''){
                fetch("https://viacep.com.br/ws/" + value + "/json/")
                .then(response => {
                    if (!response.ok) {
                        this.$mensagemAviso("CEP inexistente");
                        throw new Error("CEP inexistente");
                    }
                    return response.json();
                })
                .then(data => {
                    this.Retornocep = data;
                    if (this.Retornocep != null) {
                        this.endereco = this.Retornocep.logradouro;
                        this.numeroend = null;
                        this.complemento = null;
                        this.siglaEstado = this.Retornocep.uf;
                        this.nomeCidade = this.Retornocep.localidade;
                        this.nomeBairro = this.Retornocep.bairro;
                        this.showend = true;
                    } else {
                        this.$mensagemAviso("CEP inexistente");
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            }
            else{
                this.$mensagemAviso("Digite um cep válido");
            }
          
        },
        ValidaEndereco(){
            if (this.numCep == '' || this.numCep == null){
                this.$mensagemAviso("Preencha o CEP");
            }
            else if (this.endereco == '' || this.endereco == null){
                this.$mensagemAviso("Preencha o Endereço");
            }
            else if (this.numeroend == '' || this.numeroend == null){
                this.$mensagemAviso("Preencha o número do endereço");
            }
            else if (this.siglaEstado == '' || this.siglaEstado == null){
                this.$mensagemAviso("Preencha sigla do estado");
            }
            else if (this.nomeCidade == '' || this.nomeCidade == null){
                this.$mensagemAviso("Preencha nome da cidade");
            }
            else if (this.nomeBairro == '' || this.nomeBairro == null){
                this.$mensagemAviso("Preencha nome do bairro");
            }
            else if (this.tipoImovel == '' || this.tipoImovel == null){
                this.$mensagemAviso("Selecione tipo de imóvel");
            }
            else if (this.tipoImovel == 'Residencial' && (this.residencialTipo == null || this.residencialTipo == '')){
                this.$mensagemAviso("Preencha tipo de imóvel residencial no endereço.")
            }
            else{
              this.dialogEndereco = false;
              this.ListaPedidoItem = [];
              this.SalvarMemoria(2);
              this.ListProdutos = this.ListProdutosGeral.filter(x => 
                                                x.proProdutos.proNomeProduto && this.tipoImovel &&
                                                x.proProdutos.proNomeProduto.toLowerCase().includes(this.tipoImovel.toLowerCase())
                                                );
             //console.log(' this.ListProdutos')
            // console.log( this.ListProdutos)
            }
        },
        //endereco
        //pedido
        LimparMemoria(value){
            localStorage.removeItem(this.$DadosCompraManual);
            if (value == 1){
                this.$mensagemAviso("Memória Limpa.");
            }
            
            this.vshow = false;
            this.selectedImobiliaria = null;
            this.selectedConsumidor = null;
            this.ProdutoSelected = "";
            this.ListaPedidoItem = [];
            this.endereco = "";
            this.numeroend = "";
            this.complemento = "";
            this.siglaEstado = "";
            this.nomeCidade = "";
            this.nomeBairro = "";
            this.numCep = "";
            this.showend = false,
            this.tipoImovel = "";
            this.residencialTipo = "";
            this.TotalPedido = '';
            this.Get();
        },
        SalvarMemoria(value){
            localStorage.removeItem(this.$DadosCompraManual);
            this.DadosCompra.imobiliaria = [];
            this.DadosCompra.consumidor = [];
            this.DadosCompra.produtos = [];
            this.DadosCompra.mensagem = [];
            this.DadosCompra.agenda = [];
            this.DadosCompra.foto = [];
            this.DadosCompra.avulsos = [];
            this.DadosCompra.baloes = [];
            this.DadosCompra.informacao = [];
            this.DadosCompra.pagamento = [];
            this.DadosCompra.cupom = [];

            //imobiliaria
            this.DadosCompra.imobiliaria.push(this.selectedImobiliaria);

             //consumidor
             this.DadosCompra.consumidor.push(this.selectedConsumidor);

            //entrega
                let _agenda = {
                    TipoImovel: this.tipoImovel,
                    estado: this.siglaEstado,
                    cidade: this.nomeCidade,
                    bairro: this.nomeBairro,
                    endereco: this.endereco,
                    numero: this.numeroend,
                    complemento: this.complemento,
                    cep: this.numCep,
                    ResidencialTipo: this.residencialTipo,
                }
                
                this.DadosCompra.agenda.push(_agenda);

                
                //produto
                if (this.ListaPedidoItem.length > 0){
                    this.DadosCompra.produtos.push(this.ListaPedidoItem);
                }            

            localStorage.setItem(this.$DadosCompraManual, JSON.stringify(this.DadosCompra));
            if (value == 1){
                this.$mensagemAviso("Salvo em memória.")
            }
            
        },
        async GerarPedido(){
         await this.SalvarMemoria(2);

         let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);
        if (dadosArmazenados) {
            dadosArmazenados = JSON.parse(dadosArmazenados);
            if (dadosArmazenados.imobiliaria == '' || this.selectedImobiliaria == null){
                this.$mensagemAviso("Selecione imobiliaria.")
            }
            else if (dadosArmazenados.consumidor == '' || this.selectedConsumidor == null){
                this.$mensagemAviso("Selecione cliente.")
            }
            else if (dadosArmazenados.agenda[0].TipoImovel == '' || dadosArmazenados.agenda[0].TipoImovel == null){
                this.$mensagemAviso("Preencha tipo de imóvel no endereço.")
            }
            else if (dadosArmazenados.agenda[0].TipoImovel == 'Residencial' && (dadosArmazenados.agenda[0].ResidencialTipo == null || dadosArmazenados.agenda[0].ResidencialTipo == '')){
                this.$mensagemAviso("Preencha tipo de imóvel residencial no endereço.")
            }
            else if (dadosArmazenados.agenda[0].estado == '' || dadosArmazenados.agenda[0].estado == null){
                this.$mensagemAviso("Preencha sigla do estado do endereço.")
            }
            else if (dadosArmazenados.agenda[0].cidade == '' || dadosArmazenados.agenda[0].cidade == null){
                this.$mensagemAviso("Preencha cidade do endereco.")
            }
            else if (dadosArmazenados.agenda[0].bairro == null || dadosArmazenados.agenda[0].bairro == ""){
                this.$mensagemAviso("Preencha o bairro do endereço.")
            }
            else if (dadosArmazenados.agenda[0].endereco == '' || dadosArmazenados.agenda[0].endereco == null){
                this.$mensagemAviso("Preencha endereço.")
            }
            else if (dadosArmazenados.agenda[0].numero == "" || dadosArmazenados.agenda[0].numero == null ){
                this.$mensagemAviso("Preencha o número do endereço.")
            }
            else if (dadosArmazenados.agenda[0].cep == "" || dadosArmazenados.agenda[0].cep == null){
                this.$mensagemAviso("Preencha o CEP do endereço.")
            }
            else if (dadosArmazenados.produtos[0].length == 0){
                this.$mensagemAviso("Adicione um produto na lista.")
            }
            else{

                await axios.get(`/Process/PedPedidos/GetAllByCliId/`+ dadosArmazenados.consumidor[0].cliId)
                .then(response => {
                    this.PedidosCli = response.data
                    this.PropostaAndamento = this.PedidosCli.filter(x => x.pspId == 1 ||  x.pspId == 2 || x.pspId == 5 || x.pspId == 7 ||  x.pspId == 8);
                    this.PropostaVigMenor = this.PedidosCli.filter(x => x.pspId == 3 && moment(x.PedDataPedido).isAfter(moment().subtract(7, 'days')));
                });

               this.CarregaPedido();
               console.log('this.PedidosCli')
               console.log(this.PedidosCli)
               console.log('this.PropostaAndamento')
               console.log(this.PropostaAndamento)
               console.log('this.PropostaVigMenor')
               console.log(this.PropostaVigMenor)
            }
        }
        },
        calculatotal() {
            var valorProdutos = 0;
            var vcount = 0;

                if (this.ListaPedidoItem != '') {

                    this.ListaPedidoItem.forEach(item => {
                        valorProdutos += item.valorTotalProduto;
                        vcount++;
                    });
                }
                this.TotalPedido = (valorProdutos);
           
        },
        ExcluiProduto(index) {
            this.ListaPedidoItem.splice(index, 1);
            //this.calculatotal();
        },
        async CarregaPedido(){
            if (this.PropostaAndamento.length == 0){    
                this.startLoading();
                let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    let _situacao = 'Aguardando Analise';
                    let _pspId = 1; 

                    //dados do pedido
                        let _pedido = {
                            PedId: 0,
                            GrtId: 7,
                            CdtId: 28,
                            IdiId: 1,
                            PedDataPedido: moment().format('YYYY-MM-DD'),
                            PedOrigemPedido: this.DadosGarantidora[0].itiNome,
                            CliId: dadosArmazenados.consumidor[0].cliId,
                            ImobId: dadosArmazenados.imobiliaria[0].cliId,
                            PedFormaPgto: '',
                            PedTipoFrete: dadosArmazenados.agenda[0].TipoImovel,
                            PedValorFrete: 0,
                            CupId : 0,
                            PedCodigoCupom: '',
                            PedValorCupom: 0,
                            PedObsLoja: '',
                            PedRetornoPgto: 'pending',
                            PedCodigoRetornoPgto: '',
                            PspId: _pspId,
                            PedSituacaoPedido: _situacao,
                            PedObsCliente: dadosArmazenados.agenda[0].ObsCliente,
                            PedUser: this.User.id
                            }
                           // console.log('_pedido')
                          //  console.log(_pedido)

                           await axios.post(`/Process/PedPedidos/Add`, _pedido)
                                        .then(response => {
                                            if (response.data > 0) {
                                                    _pedido.PedId = response.data;   
                                                    this.NumeroPedido = _pedido.PedId;
                                                    if (this.PropostaVigMenor.length > 0){
                                                            axios.put(`/Process/PedPedidos/UpdateSerasa/` + _pedido.PedId + '/' + this.PropostaVigMenor[0].PedScore + '/' + this.PropostaVigMenor[0].PedSerasa)
                                                        }
                                                    console.log('_pedido adicionado')
                                                   // console.log(_pedido)                
                                            }
                                        });

                            //itens do pedido
                            let _pedidoItens = [];
                            if (dadosArmazenados.produtos[0].length > 0) {
                                await dadosArmazenados.produtos[0].forEach((itemprod) => {
                                _pedidoItens.push(
                                    {
                                        PdiId: 0,
                                        GrtId: 7,
                                        CdtId: 28,
                                        IdiId: 1,
                                        PedId: _pedido.PedId,
                                        ProId: itemprod.proProdutos.proId,
                                        PdiNomeProduto: itemprod.proProdutos.proNomeProduto,
                                        PdiQtdeProduto: parseInt(itemprod.qtdeProduto),
                                        PdiValorProduto: parseFloat(itemprod.valorProduto),
                                        PdiValorTotalProduto: itemprod.valorTotalProduto,
                                        PdiDataCadastro: moment().format('YYYY-MM-DD'),
                                        PdiDataVigenciaIni: moment(itemprod.vigenciaini).format('YYYY-MM-DD'),
                                        PdiDataVigenciaFim: moment(itemprod.vigenciafim).format('YYYY-MM-DD'),
                                        PdiCampoExtra1: itemprod.codSetup.toString(),
                                        PdiCampoExtra2: itemprod.taxa.toString(),
                                        Grid: itemprod.proProdutos.proProdutosGrids,
                                    }
                                );
                                });
                            }

                            //console.log('_pedidoItens')
                            //console.log(_pedidoItens)
                            
                            await  _pedidoItens.forEach((itens) => {
                            axios.post(`/Process/PedPedidoItem/Add`, itens)
                                    .then(response => {
                                        if (response.data > 0) {
                                           console.log('_pedidoItens adicionado')
                                           let _pedidoItensAvulsos = [];
                                           itens.Grid.forEach((itemprod) => {
                                                _pedidoItensAvulsos.push(
                                                    {
                                                        PiaId: 0,
                                                        GrtId: 7,
                                                        CdtId: 28,
                                                        IdiId: 1,
                                                        PedId: _pedido.PedId,
                                                        PraId: response.data,
                                                        PiaNomeProduto: itemprod.pgsNome,
                                                        PiaQtdeProduto: 1,
                                                        PiaValorProduto: parseFloat(itemprod.pgsCampoExtra1.replaceAll('.', '').replaceAll(',', '.')),
                                                        PiaValorTotalProduto: parseFloat(itemprod.pgsCampoExtra1),
                                                        PiaDataCadastro: moment().format('YYYY-MM-DD'),
                                                        PiaCampoExtra1: '',   //texto balao
                                                    }
                                                );
                                                });

                                            // console.log('_pedidoItensAvulsos')
                                            //  console.log(_pedidoItensAvulsos)

                                               _pedidoItensAvulsos.forEach((itens) => {
                                                axios.post(`/Process/PedPedidoItemAdicional/Add`, itens)
                                                    .then(response => {
                                                        if (response.data > 0) {
                                                            console.log('_pedidoItensAvulsos adicionado')
                                                        // console.log(itens)         
                                                        }
                                                    });
                                            })
                                        }
                                    });
                            })                     

                            //pedido Endereço
                            let _pedidoEndereco = {
                                PdcId: 0,
                                GrtId: 7,
                                CdtId: 28,
                                IdiId: 1,
                                PdcEndereco: dadosArmazenados.agenda[0].endereco,
                                PdcNumero: parseInt(dadosArmazenados.agenda[0].numero,10),
                                PdcComplemento: dadosArmazenados.agenda[0].complemento,
                                PdcObsEndereco:'',
                                pdcCep: dadosArmazenados.agenda[0].cep,
                                PdcPais:'Brasil',
                                PdcEstado: dadosArmazenados.agenda[0].estado,
                                PdcCidade: dadosArmazenados.agenda[0].cidade,
                                PdcBairro: dadosArmazenados.agenda[0].bairro,
                                CpaId:1,
                                CetId: 0,
                                CidId: 0,
                                BaiId: 0,
                                PedId:_pedido.PedId,
                                PdcTipo: dadosArmazenados.agenda[0].TipoImovel,
                                PdcResidencialTipo: dadosArmazenados.agenda[0].ResidencialTipo,
                            }

                            await axios.post(`/Process/PedPedidoEndereco/Add`, _pedidoEndereco)
                                    .then(response => {
                                        if (response.data > 0) {   
                                                console.log('_pedidoEndereco adicionado')
                                                //console.log(_pedidoEndereco)                
                                        }
                                    });
                         
                            this.ValidaAprovacao(_pedido);
                            //console.log('_pedido')
                            //console.log(_pedido)
                            
                        }
            }
            else{
                this.$mensagemAviso("Já existe uma contratação em andamento, cancele a contratação ou entre em contato via whatsapp com suporte Aqui Garante.");
            }
        },
        async ValidaAprovacao(pedidoDados){
            //console.log('pedidoDados')
            //console.log(pedidoDados)
          var _resultSituacao = await this.AprovacaoAutomatica(pedidoDados);

            if (_resultSituacao == 5){
                let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    pedidoDados.pedSituacaoPedido = 'Aprovado Gerar Cobrança';
                    await axios.put(`/Process/PedPedidos/Update`, pedidoDados)
                    this.$mensagemAviso("Aprovada, foi enviado link de pagamento via e-mail para imobiliária e Cliente.")
                    let _descricao = "";
                    let _valorProd = 0;

                    _descricao = "Referente a contratação: "
                    dadosArmazenados.produtos[0].forEach(element => {
                    if (element.proProdutos.proId == 161 || element.proProdutos.proId == 162){
                        _descricao += element.proProdutos.proNomeProduto + " ";
                        _valorProd += element.valorTotalProduto;
                    }
                    });
                    var _texto = _descricao + " no valor de R$ " + this.formatarValor(_valorProd);
                    var _dadoslink = "https://aquigarante.net.br/pgtocontratacaocliente/" + dadosArmazenados.consumidor[0].cliId + "/" + dadosArmazenados.imobiliaria[0].cliId + "/" + pedidoDados.PedId
                    await this.EnviaAvisoAprovacaoImobiliaria(_dadoslink,_texto,dadosArmazenados.consumidor[0], dadosArmazenados.imobiliaria[0], pedidoDados);
                    await this.EnviaAvisoPgtoCliente(_dadoslink,_texto,dadosArmazenados.consumidor[0], dadosArmazenados.imobiliaria[0], pedidoDados,dadosArmazenados.agenda[0]);
                    await this.EnviaAvisoAprovacaoUsuario(_dadoslink,_texto,dadosArmazenados.consumidor[0], dadosArmazenados.imobiliaria[0], pedidoDados);
                   
                    pedidoDados.pspId = 7;
                    pedidoDados.pedSituacaoPedido = 'Aguardando Pagamento';
                    await axios.put(`/Process/PedPedidos/Update`, pedidoDados)
                    this.LimparMemoria(2);
                    this.$router.push('/listacontratacoes');
                }
            }
            else if (_resultSituacao == 2) {
                let _dadosSituacaoPix = {
                        PspId: 2,
                        PedSituacaoPedido: "Analise Manual",
                        PedObsCliente: "",
                        PedCodigoRetornoPgto: "",
                        PedQtdeParcelas: 0,
                        PedValorParcelas: 0,
                        PedId: pedidoDados.PedId,
                    }

                    axios.put(`/Process/PedPedidos/UpdateSituacaoAsaas`, _dadosSituacaoPix)
                        .then(response => {
                            if (response.status == 200) {
                                console.log('Update Situacao')
                            }
                        });
                        let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);
                        if (dadosArmazenados) {
                                dadosArmazenados = JSON.parse(dadosArmazenados);
                                  await this.EnviaAvisoAnaliseImobiliaria(dadosArmazenados.consumidor[0], dadosArmazenados.imobiliaria[0], pedidoDados);
                                  await this.EnviaAvisoAnaliseAdm(dadosArmazenados.consumidor[0], dadosArmazenados.imobiliaria[0], pedidoDados);
                                  await this.EnviaAvisoAnaliseUsuario(dadosArmazenados.consumidor[0], dadosArmazenados.imobiliaria[0], pedidoDados);
                                    this.LimparMemoria(2);
                                    this.$mensagemAviso("A contratação foi enviada para análise.")
                                    this.$router.push('/listacontratacoes')
                            }
            }
            else if (_resultSituacao == 4) {
                let _dadosSituacaoPix = {
                        PspId: 4,
                        PedSituacaoPedido: "Não Aprovado",
                        PedObsCliente: "",
                        PedCodigoRetornoPgto: "",
                        PedQtdeParcelas: 0,
                        PedValorParcelas: 0,
                        PedId: pedidoDados.PedId,
                    }

                    axios.put(`/Process/PedPedidos/UpdateSituacaoAsaas`, _dadosSituacaoPix)
                        .then(response => {
                            if (response.status == 200) {
                                console.log('Update Situacao')
                            }
                        });
                        
                        let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);
                        if (dadosArmazenados) {
                                dadosArmazenados = JSON.parse(dadosArmazenados);
                                await this.EnviaAvisoReprovacaoImobiliaria(dadosArmazenados.consumidor[0], dadosArmazenados.imobiliaria[0], pedidoDados);
                                await this.EnviaAvisoReprovacaoUsuario(dadosArmazenados.consumidor[0], dadosArmazenados.imobiliaria[0], pedidoDados);
                                this.LimparMemoria(2);
                                this.$mensagemAviso("A contratação não foi aprovada.")
                                this.$router.push('/contratacoesNegadoCancelado')
                        }
            }
        },
        async AprovacaoAutomatica(pedi) {
            
            var result = 2;
            let PropostaVigMaior = await this.PedidosCli.filter(x => x.pspId == 3)
            if (this.PropostaVigMenor.length > 0){
             return result;
           }
           else
           {         
            let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);

            if (dadosArmazenados) {
                dadosArmazenados = JSON.parse(dadosArmazenados);
                let documentId = "";
                let tipo = "";
                let relatorio = "";
                let estado = dadosArmazenados.agenda[0].estado;

                if (dadosArmazenados.consumidor[0].cliClienteTipo.cctId == 1) {
                    tipo = 3;
                    relatorio = "RELATORIO_BASICO_PF_PME";
                    documentId = dadosArmazenados.consumidor[0].cliCpf.replaceAll('.', '').replaceAll('-', '');
                } else {
                    tipo = 4;
                    relatorio = "RELATORIO_BASICO_PJ_PME";
                    documentId = dadosArmazenados.consumidor[0].cliCnpj.replaceAll('.', '').replaceAll('-','').replaceAll('/', '');
                }

                try {
                    const response = await axios.get(`/Process/PedPedidos/GetByDocumentId/` + documentId + '/' + pedi.PedId);

                    if (response.data == 0) {
                        const serasaResponse = await axios.post(`/Process/ValidaSerasa/checkSerasa/` + documentId + '/' + tipo + '/' + relatorio + '/' + estado + '/' + pedi.PedId);
                        
                        //console.log('resposta Serasa');
                        //console.log(serasaResponse);
                        if (serasaResponse.data && serasaResponse.data.reports && serasaResponse.data.reports[0].score && serasaResponse.data.reports[0].score.score)
                        {
                            let resultScore = serasaResponse.data.reports[0].score.score;
                            let valorAluguel = parseFloat(dadosArmazenados.produtos[0][0].proProdutos.proProdutosGrids[0].pgsCampoExtra1.replaceAll('.','').replaceAll(',','.'));
                            let existsPendentes = false;
                            if (serasaResponse.data.reports[0].negativeData && 
                                serasaResponse.data.reports[0].negativeData.refin != '' && 
                                serasaResponse.data.reports[0].negativeData.refin.refinResponse &&
                                serasaResponse.data.reports[0].negativeData.refin.refinResponse.length > 0)
                            {
                                existsPendentes = true;
                            }
                            else if (serasaResponse.data.reports[0].negativeData && 
                                     serasaResponse.data.reports[0].negativeData.notary &&
                                     serasaResponse.data.reports[0].negativeData.notary.notaryResponse &&
                                     serasaResponse.data.reports[0].negativeData.notary.notaryResponse.length > 0)
                            {
                                existsPendentes = true;
                            }
                            else if (serasaResponse.data.reports[0].negativeData && 
                                     serasaResponse.data.reports[0].negativeData.check && 
                                     serasaResponse.data.reports[0].negativeData.check.checkResponse &&
                                     serasaResponse.data.reports[0].negativeData.check.checkResponse.length > 0)
                            {
                                existsPendentes = true;
                            }

                            if (existsPendentes){
                                result = 4;
                            }
                            else{
                                    if (resultScore > parseInt(this.DadosConfiguracao[0].itiNome)) {
                                        if (valorAluguel > parseFloat(this.DadosConfiguracao[0].itiCampoExtra4))
                                        {
                                            result = 2;
                                        }
                                        else{
                                            if (PropostaVigMaior !== null && PropostaVigMaior.length > 0){
                                                result = 2;
                                            }
                                            else{
                                                result = 5;
                                            }
                                        }
                                    } else if (resultScore > parseInt(this.DadosConfiguracao[0].itiReferencia) && resultScore < parseInt(this.DadosConfiguracao[0].itiNome)) {
                                        result = 2;
                                    } else if (resultScore == 0) {
                                        result = 2;
                                    } else {
                                        result = 4;
                                    }
                                }
                        }
                        else{
                            result = 2;
                        }
                    } else {
                        result = 4;
                    }

                    return result;
                } catch (error) {
                    console.error('Erro ao buscar dados', error);
                    throw error; // Caso deseje propagar o erro
                }
            }
          }
        },
        EnviaAvisoPgtoCliente(dadoslink, texto, consumidor, imob, pedi, endereco) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x => x.mpdId == 4);
                    let _endereco = endereco.endereco + "," + endereco.numero + " " + endereco.complemento + " - " + endereco.bairro + " - " + endereco.cidade + "/" + endereco.estado;
                    let _dadosEmail = {
                        nomeDe: this.DadosGarantidora[0].itiNome,
                        nomePara: consumidor.cliNomeEmpresa,
                        assunto: 'Análise de Credito Aprovada - Contratação: ' +  pedi.PedId,
                        destinatario: consumidor.cliEmail,
                        emailResposta: this.DadosGarantidora[0].itiReferencia,
                        textoEmail: this.templateEmail[0].mpdTexto.replaceAll('[NomeCliente]', consumidor.cliNomeEmpresa)
                                                                  .replaceAll('[NumeroPedido]', pedi.PedId)
                                                                  .replace('[imobiliaria]', imob.cliNomeEmpresa)
                                                                  .replace('[descricao]',texto)
                                                                  .replace('[LinkPgto]',dadoslink)
                                                                  .replace('[EndImovel]',_endereco)
                                                                  .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>')
                                                                  .replace('<table>', '<table style="background-color: #F6F6F6;padding: 30px;">'),
                        nomeAnexo: '',
                        anexo: '',
                        tipoAnexo: ''
                    }
                    axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                        .then(response => {
                            if (response.data == 1) {
                                console.log('email enviado')
                            }
                        });
                });
        },
        EnviaAvisoAprovacaoImobiliaria(dadoslink, texto, consumidor, imob, pedi) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x => x.mpdId == 11);

                    let _dadosEmail = {
                                nomeDe: this.DadosGarantidora[0].itiNome,
                                nomePara: imob.cliNomeEmpresa + ' - ' + consumidor.cliNomeEmpresa,
                                assunto: 'Análise de Credito Aprovada - Contratação: ' +  pedi.PedId,
                                destinatario: imob.cliEmail,
                                emailResposta: this.DadosGarantidora[0].itiReferencia,
                                textoEmail: this.templateEmail[0].mpdTexto.replaceAll('[CliNome]', consumidor.cliNomeEmpresa)
                                .replaceAll('[ContratacaoId]', pedi.PedId)
                                .replace('[LinkSistema]', 'https://aquigarante.net.br').replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>')
                                .replace('<table>', '<table style="background-color: #F6F6F6;padding: 30px;">'),
                                nomeAnexo: '',
                                anexo: '',
                                tipoAnexo: ''
                    }
                    axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                        .then(response => {
                            if (response.data == 1) {
                                console.log('email enviado')
                            }
                        });
                });
        },
        EnviaAvisoAprovacaoUsuario(dadoslink, texto, consumidor, imob, pedi) {  
                 axios.get(`/Process/UsrCadastroUsuarios/GetAll`)
                        .then(response => {
                            this.Usuarios = response.data.filter(x=> x.cusId == this.User.id);
                        });

                        axios.get(`/Process/CadMensagensPadrao/GetAll`)
                        .then(response => {
                            this.templateEmail = response.data.filter(x => x.mpdId == 11);

                            let _dadosEmail = {
                                        nomeDe: this.DadosGarantidora[0].itiNome,
                                        nomePara: this.Usuarios.cusNome,
                                        assunto: 'Análise de Credito Aprovada - Contratação: ' +  pedi.PedId,
                                        destinatario: this.Usuarios.cusEmail,
                                        emailResposta: this.DadosGarantidora[0].itiReferencia,
                                        textoEmail: this.templateEmail[0].mpdTexto.replaceAll('[CliNome]', consumidor.cliNomeEmpresa)
                                        .replaceAll('[ContratacaoId]', pedi.PedId)
                                        .replace('[LinkSistema]', 'https://aquigarante.net.br')
                                        .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>')
                                        .replace('<table>', '<table style="background-color: #F6F6F6;padding: 30px;">'),
                                        nomeAnexo: '',
                                        anexo: '',
                                        tipoAnexo: ''
                            }
                            axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                                .then(response => {
                                    if (response.data == 1) {
                                        console.log('email enviado')
                                    }
                                });
                        }); 

                
        },
        EnviaAvisoAnaliseImobiliaria(consumidor, imob, pedi) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x => x.mpdId == 7);

                    let _dadosEmail = {
                                nomeDe: this.DadosGarantidora[0].itiNome,
                                nomePara: imob.cliNomeEmpresa + ' - ' + consumidor.cliNomeEmpresa,
                                assunto: 'Análise Manual - Contratação: ' +  pedi.PedId,
                                destinatario: imob.cliEmail,
                                emailResposta: this.DadosGarantidora[0].itiReferencia,
                                textoEmail: this.templateEmail[0].mpdTexto
                                .replaceAll('[NomeCliente]', consumidor.cliNomeEmpresa)
                                .replaceAll('[NumeroPedido]', pedi.PedId)
                                .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>')
                                .replace('<table>', '<table style="background-color: #F6F6F6;padding: 30px;">'),
                                nomeAnexo: '',
                                anexo: '',
                                tipoAnexo: ''
                    }
                    axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                        .then(response => {
                            if (response.data == 1) {
                                console.log('email enviado')
                            }
                        });
                });
        },
        EnviaAvisoAnaliseAdm(consumidor, imob, pedi) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x => x.mpdId == 7);

                    let _dadosEmail = {
                                nomeDe: this.DadosGarantidora[0].itiNome,
                                nomePara: this.DadosGarantidora[0].itiNome + ' - ' + consumidor.cliNomeEmpresa,
                                assunto: 'Análise Manual - Contratação: ' +  pedi.PedId,
                                destinatario: this.DadosGarantidora[0].itiReferencia,
                                emailResposta: this.DadosGarantidora[0].itiReferencia,
                                textoEmail: this.templateEmail[0].mpdTexto
                                .replaceAll('[NomeCliente]', consumidor.cliNomeEmpresa)
                                .replaceAll('[NumeroPedido]', pedi.PedId)
                                .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>')
                                .replace('<table>', '<table style="background-color: #F6F6F6;padding: 30px;">'),
                                nomeAnexo: '',
                                anexo: '',
                                tipoAnexo: ''
                    }
                    axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                        .then(response => {
                            if (response.data == 1) {
                                console.log('email enviado')
                            }
                        });
                });
        },
        EnviaAvisoAnaliseUsuario(consumidor, imob, pedi) {
                axios.get(`/Process/UsrCadastroUsuarios/GetAll`)
                            .then(response => {
                            this.Usuarios = response.data.filter(x=> x.cusId == this.User.id);
                        });
                axios.get(`/Process/CadMensagensPadrao/GetAll`)
                    .then(response => {
                        this.templateEmail = response.data.filter(x => x.mpdId == 7);

                        let _dadosEmail = {
                                    nomeDe: this.DadosGarantidora[0].itiNome,
                                    nomePara: this.Usuarios.cusNome + ' - ' + consumidor.cliNomeEmpresa,
                                    assunto: 'Análise Manual - Contratação: ' +  pedi.PedId,
                                    destinatario: this.Usuarios.cusEmail,
                                    emailResposta: this.DadosGarantidora[0].itiReferencia,
                                    textoEmail: this.templateEmail[0].mpdTexto
                                    .replaceAll('[NomeCliente]', consumidor.cliNomeEmpresa)
                                    .replaceAll('[NumeroPedido]', pedi.PedId)
                                    .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>')
                                    .replace('<table>', '<table style="background-color: #F6F6F6;padding: 30px;">'),
                                    nomeAnexo: '',
                                    anexo: '',
                                    tipoAnexo: ''
                        }
                        axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                            .then(response => {
                                if (response.data == 1) {
                                    console.log('email enviado')
                                }
                            });
                    });
                
        },
        EnviaAvisoReprovacaoImobiliaria(consumidor, imob, pedi) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                .then(response => {
                    this.templateEmail = response.data.filter(x => x.mpdId == 12);

                    let _dadosEmail = {
                                nomeDe: this.DadosGarantidora[0].itiNome,
                                nomePara: imob.cliNomeEmpresa + ' - ' + consumidor.cliNomeEmpresa,
                                assunto: 'Status Contratação: ' +  pedi.PedId,
                                destinatario: imob.cliEmail,
                                emailResposta: this.DadosGarantidora[0].itiReferencia,
                                textoEmail: this.templateEmail[0].mpdTexto
                                .replaceAll('[CliNome]', consumidor.cliNomeEmpresa)
                                .replaceAll('[ContratacaoId]', pedi.PedId)
                                .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>')
                                .replace('<table>', '<table style="background-color: #F6F6F6;padding: 30px;">'),
                                nomeAnexo: '',
                                anexo: '',
                                tipoAnexo: ''
                    }
                    axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                        .then(response => {
                            if (response.data == 1) {
                                console.log('email enviado')
                            }
                        });
                });
        },
        EnviaAvisoReprovacaoUsuario(consumidor, imob, pedi) {
                        axios.get(`/Process/UsrCadastroUsuarios/GetAll`)
                                    .then(response => {
                                    this.Usuarios = response.data.filter(x=> x.cusId == this.User.id);
                                });
                    axios.get(`/Process/CadMensagensPadrao/GetAll`)
                        .then(response => {
                            this.templateEmail = response.data.filter(x => x.mpdId == 12);

                            let _dadosEmail = {
                                        nomeDe: this.DadosGarantidora[0].itiNome,
                                        nomePara: this.Usuarios.cusNome + ' - ' + consumidor.cliNomeEmpresa,
                                        assunto: 'Status Contratação: ' +  pedi.PedId,
                                        destinatario: this.Usuarios.cusEmail,
                                        emailResposta: this.DadosGarantidora[0].itiReferencia,
                                        textoEmail: this.templateEmail[0].mpdTexto
                                        .replaceAll('[CliNome]', consumidor.cliNomeEmpresa)
                                        .replaceAll('[ContratacaoId]', pedi.PedId)
                                        .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>')
                                        .replace('<table>', '<table style="background-color: #F6F6F6;padding: 30px;">'),
                                        nomeAnexo: '',
                                        anexo: '',
                                        tipoAnexo: ''
                            }
                            axios.post(`/Process/AdmControleSmtp/Enviamail`, _dadosEmail)
                                .then(response => {
                                    if (response.data == 1) {
                                        console.log('email enviado')
                                    }
                                });
                        });
            
        },
        //pedido
        //produtos
        toggle(mes) {
            this.ProdutoSelected = mes;
            if (mes.proProdutos.proId == 161 || mes.proProdutos.proId == 162){
                    let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);
                        if (dadosArmazenados) {
                            dadosArmazenados = JSON.parse(dadosArmazenados);
                            if (dadosArmazenados.produtos.length > 0){
                                this.ProdutosGrid =  dadosArmazenados.produtos[0][0].proProdutos.proProdutosGrids;
                            }
                            else{
                                //console.log('aqui')
                                axios.get(`/Process/ProProdutosGrids/GetAllByCtiId/${mes.proProdutos.proId}`)
                                .then(response => {
                                    this.ProdutosGrid = response.data; 
                                                
                                });
                            }
                        }
                        else{
                            axios.get(`/Process/ProProdutosGrids/GetAllByCtiId/${mes.proProdutos.proId}`)
                            .then(response => {
                                this.ProdutosGrid = response.data; /*.sort((a, b) => {
                                                                            const valorA = parseInt(a.pimOrdem) || 0;
                                                                            const valorB = parseInt(b.pimOrdem) || 0;
                                                                            return valorA - valorB;
                                                                        });*/
                                                                        
                           // console.log('mes')
                          //  console.log(mes)

                      //  console.log('ProdutosGrid')
                      //  console.log(this.ProdutosGrid)

                            });
                        }
                    

                            axios.get(`/Process/ProProdutosPrecos/GetAllByPtcId/5/${mes.proProdutos.proId}`)
                            .then(response => {
                                this.ProdutosTaxas = response.data;                                                                 
                               // console.log('ProdutosTaxas')
                               // console.log(this.ProdutosTaxas)

                            });

                    this.openModalProduto(1);
                            
            }
        },
        openModalProduto(value){
            if (value == 1)
            {
                this.dialogProdutoGarantia = true;
            }
            else{
                this.dialogProdutoSeguro = true;
            }
        },
        AdicionarGarantia(){
            if (this.ProdutosGrid.some(item => item.pgsCampoExtra1 === ''))
            {
                this.$mensagemAviso("Valores precisam ser preenchidos.")
            }
            else if (this.PlanosGarantiasSelected == '' || this.PlanosGarantiasSelected == null){
                this.$mensagemAviso("Selecione taxa de setup.")
            }
            else{
                this.ListaPedidoItem = [];
                var qtdeprod = 1;
                this.ProdutoSelected.qtdeProduto = qtdeprod;
                this.ProdutoSelected.valorTotalProduto = qtdeprod * (parseFloat(this.ValorTotalComputed * (this.ProdutosTaxas[0].ppcValorDe/100)) + parseFloat(this.PlanosGarantias.filter(x=> x.itiId == this.PlanosGarantiasSelected)[0].itiReferencia));
                this.ProdutoSelected.valorProduto = (parseFloat(this.ValorTotalComputed * (this.ProdutosTaxas[0].ppcValorDe/100)) + parseFloat(this.PlanosGarantias.filter(x=> x.itiId == this.PlanosGarantiasSelected)[0].itiReferencia));
                this.ProdutoSelected.codSetup = this.PlanosGarantiasSelected;
                this.ProdutoSelected.taxa = this.ProdutosTaxas[0].ppcValorDe;
                this.ProdutoSelected.vigenciaini = moment();
                this.ProdutoSelected.vigenciafim = moment(this.ProdutoSelected.vigenciaini).add(12, 'months');
                this.ProdutoSelected.proProdutos.proProdutosGrids = this.ProdutosGrid;
                this.ListaPedidoItem.push(this.ProdutoSelected);
                this.SalvarMemoria(2);
                this.dialogProdutoGarantia = false;
                this.calculatotal();
            }
           
        },
        //produtos
        formattedDate(value) {
            if (value != null || value != "") {
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else {
                return "";
            }

        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        //clientes
        LimpaCampo(value){
           if (value == 1){
            this.Clientes.cliCnpj = '';
           }
           else{
            this.Clientes.cliCpf = '';
           }
        },
        AddCliente() {
            if (this.Clientes.cctId == '') {
                this.$mensagemAviso("Campo Tipo Obrigatório.")
            }
            else if (this.Clientes.cliNomeEmpresa == '' && this.Clientes.cctId == 1) {
                this.$mensagemAviso("Campo Nome Cliente Obrigatório.")
            }
            else if (this.Clientes.cliCpf == '' && this.Clientes.cctId == 1) {
                this.$mensagemAviso("Campo CPF Obrigatório.")
            }
            else if (this.Clientes.cliNomeEmpresa == '' && this.Clientes.cctId == 2) {
                this.$mensagemAviso("Campo Nome Empresa Obrigatório.")
            }
            else if (this.Clientes.cliCnpj == '' && this.Clientes.cctId == 2) {
                this.$mensagemAviso("Campo CNPJ Obrigatório.")
            }
            else if (this.Clientes.cliRazaoSocial == '' && this.Clientes.cctId == 2) {
                this.$mensagemAviso("Campo Razão Social Obrigatório.")
            }
            else if (this.Clientes.cliWhatsapp === '' || this.cliWhatsapp === null) {
                this.$mensagemAviso("Campo Whatsapp Obrigatório.")
            }
            else if (this.Clientes.cliEmail === '' || this.cliEmail === null) {
                this.$mensagemAviso("Campo E-mail Obrigatório.")
            }
            else{
                axios.get(`/Process/CliClientes/GetAll`)
                            .then(response => {
                                //console.log('response.data')
                                // console.log(response.data)
                                this.ListClientes = response.data.filter(x=> x.cliTipoCli == 69 && x.cliIdPai == this.User.cliId); 
                                //console.log('this.ListClientes') 
                                //console.log(this.ListClientes)
                                const valida = this.ListClientes.filter(x=> ((x.cliClienteTipo.cctId == 2 && x.cliCnpj == this.Clientes.cliCnpj) || (x.cliClienteTipo.cctId == 1 && x.cliCpf == this.Clientes.cliCpf)) && x.cliId != this.Clientes.cliId)
                                
                                if (valida.length > 0){
                                    this.$mensagemAviso("Cliente com esse documento já cadastrado.")
                                }
                                else {
                                        if (this.Clientes.cliVisivel === "" || this.Clientes.cliVisivel === null) {
                                            this.Clientes.cliVisivel = true;
                                        }
                                        if (this.Clientes.cliAtivo === "" || this.Clientes.cliAtivo === null) {
                                            this.Clientes.cliAtivo = true;
                                        }
                                        this.Clientes.cliClienteTipo = { CctId: this.Clientes.cctId }
                                        if (this.Clientes.cliDataNascimento === "" || this.Clientes.cliDataNascimento === null ) {
                                            this.Clientes.cliDataNascimento = null;
                                        }
                                        else{
                                            this.Clientes.cliDataNascimento = this.$moment(this.Clientes.cliDataNascimento).format('YYYY-MM-DD')
                                        }
                                        if (this.Clientes.cliData1 === "" || this.Clientes.cliData1 === null) {
                                            this.Clientes.cliData1 = null;
                                        }
                                        else{
                                            this.Clientes.cliData1 = this.$moment(this.Clientes.cliData1).format('YYYY-MM-DD')
                                        }
                                        if (this.Clientes.cliData2 === "" || this.Clientes.cliData2 === null) {
                                            this.Clientes.cliData2 = null;
                                        }
                                        else{
                                            this.Clientes.cliData2 = this.$moment(this.Clientes.cliData2).format('YYYY-MM-DD')
                                        }
                                        if (this.Clientes.cliDataCadastro === "" || this.Clientes.cliDataCadastro === null ) {
                                            this.Clientes.cliDataCadastro = this.$moment().format('YYYY-MM-DD')
                                        }
                                        else{
                                            this.Clientes.cliDataCadastro = this.$moment(this.Clientes.cliDataCadastro).format('YYYY-MM-DD')
                                        }

                                        this.Clientes.cliSenha = SHA256(this.Clientes.cliSenha).toString();
                                        this.Clientes.cliTipoCli = 69;
                                        this.Clientes.cliIdPai = parseInt(this.User.cliId);
                                        axios.post(`/Process/CliClientes/Add`, this.Clientes)
                                            .then(response => {
                                                if (response.data > 0) {
                                                    this.LimparCliente();
                                                    this.$router.push('/Novacontratacao/' + response.data);
                                                 }
                                            });
                                }
                            });
            
            }
        },
        LimparCliente() {
            this.Clientes.cliId = 0;
            this.Clientes.cctId= "";
            this.Clientes.cliVisivel="";
            this.Clientes.cliDataCadastro="";
            this.Clientes.cliNomeEmpresa="";
            this.Clientes.cliRazaoSocial="";
            this.Clientes.cliCnpj="";
            this.Clientes.cliCpf="";
            this.Clientes.cliTelefone="";
            this.Clientes.cliWhatsapp="";
            this.Clientes.cliResponsavel="";
            this.Clientes.cliEmail="";
            this.Clientes.cliSenha="";
            this.Clientes.cliAtivo="";
            this.Clientes.cliDataNascimento="";
            this.Clientes.cliCampoExtra1="";
            this.Clientes.cliCampoExtra2="";
            this.Clientes.cliCampoExtra3="";
            this.Clientes.cliCampoExtra4="";
            this.Clientes.cliCampoExtra5="";
            this.Clientes.cliTexto1="";
            this.Clientes.cliTexto2="";
            this.Clientes.cliTexto3="";
            this.Clientes.cliData1="";
            this.Clientes.cliData2="";
        },
        //clientes
        async Get() {

            const valorArmazenado = localStorage.getItem('_useravImob');
                        if (valorArmazenado != '') {
                        this.User = JSON.parse(valorArmazenado);
                    axios.get(`/Process/CliClientes/GetAll`)
                        .then(response => {
                            this.ListCliGeral = response.data;
                            this.ListImobiliarias = this.ListCliGeral.filter(x=> x.cliTipoCli == 67 && x.cliId == this.User.cliId);  //imobiliaria
                            this.ListImobiliarias = this.ListImobiliarias.map(item => {
                                return {
                                    ...item,
                                    nomeConcatenado: `${item.cliId} - ${item.cliNomeEmpresa} - ${item.cliCpf == "" ? item.cliCnpj : item.cliCpf}`
                                };
                            });
                            this.selectedImobiliaria = this.ListImobiliarias[0];
                            this.SelecionarConsumidor();
                        });
               

                axios.get(`/Process/PedCadSituacaoPedido/GetAll`)
                .then(response => {
                    this.Situacoes = response.data
                    //console.log('this.Situacoes')
                    //console.log(this.Situacoes)
                });

              await  axios.get('/Process/ProProdutosSegmentos/GetAllBySite')
                    .then(response => {
                        this.ListProdutosGeral = response.data.filter(x=> x.proProdutos.proVisivel);
                        this.ListProdutosGeral = this.ListProdutosGeral.map(item => {
                            return {
                                ...item,
                                nomeConcatenado: `${item.proProdutos.proNomeProduto}`
                            };
                        });

                        // Fazer a distinção dos itens com base na propriedade nomeConcatenado
                        const distinctItems = this.ListProdutosGeral.filter((item, index, self) =>
                            index === self.findIndex(t => (
                                t.nomeConcatenado === item.nomeConcatenado
                            ))
                        );
                        this.ListProdutosGeral = distinctItems.sort((a, b) => {
                            const nomeA = a.proProdutos.proNomeProduto || "";
                            const nomeB = b.proProdutos.proNomeProduto || "";
                            return nomeA.localeCompare(nomeB);
                        });
                        //console.log('Itens distintos:');
                       // console.log(distinctItems);

                    })
                    .catch(error => {
                        console.error(error);
                    });

                    //planos Garantias
                    axios.get('/Process/InsInstitucional/institucional/32/planos')
                    .then(response => {
                        this.PlanosGarantias = response.data;
                    })
                    .catch(error => {
                        console.error(error);
                    });

                     //dados da Garantidora
                     await    axios.get('/Process/InsInstitucional/institucional/34/informacoes')
                    .then(response => {
                        this.DadosGarantidora = response.data;
                       // console.log('this.DadosGarantidora')
                       // console.log(this.DadosGarantidora)
                    })
                    .catch(error => {
                        console.error(error);
                    });

                    await    axios.get('/Process/InsInstitucional/institucional/35/configuracoes')
                    .then(response => {
                        this.DadosConfiguracao = response.data;
                      //  console.log('this.DadosConfiguracao')
                      //  console.log(this.DadosConfiguracao)
                    })
                    .catch(error => {
                        console.error(error);
                    });


                let dadosArmazenados = localStorage.getItem(this.$DadosCompraManual);
                if (dadosArmazenados) {
                    dadosArmazenados = JSON.parse(dadosArmazenados);
                    if (dadosArmazenados.consumidor != ''){
                        this.selectedImobiliaria = dadosArmazenados.imobiliaria[0];
                        this.SelecionarConsumidor();
                        this.selectedConsumidor = dadosArmazenados.consumidor[0];
                     
                        if (dadosArmazenados.agenda != ''){
                            //endereco
                            this.tipoImovel = dadosArmazenados.agenda[0].TipoImovel;
                            this.nomeCidade = dadosArmazenados.agenda[0].cidade;
                            this.siglaEstado = dadosArmazenados.agenda[0].estado;
                            this.nomeBairro = dadosArmazenados.agenda[0].bairro;                         
                            this.endereco = dadosArmazenados.agenda[0].endereco;
                            this.numeroend = dadosArmazenados.agenda[0].numero;
                            this.complemento = dadosArmazenados.agenda[0].complemento;
                            this.numCep = dadosArmazenados.agenda[0].cep;
                            this.residencialTipo = dadosArmazenados.agenda[0].residencialTipo;
                            this.showend = true;
                        }

                       // console.log('aqui')
                       // console.log(dadosArmazenados.produtos[0][0].proProdutos.proProdutosGrids[0].pgsCampoExtra1.replaceAll('.','').replaceAll(',','.'))
                       // console.log('this.tipoImovel')
                       // console.log(this.tipoImovel)
                        //console.log('this.ListProdutosGeral')
                       // console.log(this.ListProdutosGeral)

                        this.ListProdutos = this.ListProdutosGeral.filter(x => 
                                                x.proProdutos.proNomeProduto && this.tipoImovel &&
                                                x.proProdutos.proNomeProduto.toLowerCase().includes(this.tipoImovel.toLowerCase())
                                                );

                      
                        //produtos
                        if (dadosArmazenados.produtos != ''){
                            this.ListaPedidoItem = dadosArmazenados.produtos[0];
                            this.PlanosGarantiasSelected = dadosArmazenados.produtos[0][0].codSetup;
                        }
                        //pagamento
                        if (dadosArmazenados.pagamento != ''){
                           this.FormaPgto = dadosArmazenados.pagamento[0].codigo;
                        }

                      this.calculatotal();
                    }
                    }

                    axios.get(`/Process/CliClienteTipo/GetAll`)
                        .then(response => {
                            this.TipoClientes = response.data;                         
                        
                    });
                }
        }
    },
    created() {
        this.Get();
    },
    computed: {
    ValorTotalComputed() {
        let valorTotal = 0;
        this.ProdutosGrid.forEach(element => {
            // Certifique-se de que o valor de pgsCampoExtra1 é um número antes de somar
            const parsedValue = parseFloat(element.pgsCampoExtra1);
            if (!isNaN(parsedValue)) {
                valorTotal += parsedValue;
            }
        });
        return valorTotal * 12;
    }
}
}
</script>

<style scoped>.tabinfo {
    font-size: 14px;
    text-transform: capitalize;
}


td {
    border: 1px solid #333333;
    padding: 3px;
}

.small-text {
    font-size: 10px;
    color: #dc2626;
    margin-top: 0px !important;
    position: absolute;
}


@media (min-width:1200px) {

    .fonttext{
        font-size: 14px;
    }
    /* Estilos para o select */

    .styled-select {
  display: block;
  width: 100%;
  font-size: 15px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.altcel {
    min-height: 500px !important;
    height: auto !important;
}

.altavul {
    padding-top: 25px;
}

.btncel {
    color:#ffffff;
    min-width:80px !important;
    font-size: 18px;
}

.controlcelEnd {
    height: 600px;
        width: calc(100% - 248px) !important;
        display: flex;
        flex-direction: column;
    }

}

@media (min-width:768px) {
    .altavul {
    padding-top: 35px;
}

.fonttext{
        font-size: 14px;
    }
    /* Estilos para o select */
.styled-select {
  display: block;
  width: 100%;
  font-size: 15px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.altcel {
    min-height: 500px !important;
    height: auto !important;
}

.altavul {
    padding-top: 25px;
}

.btncel {
    color:#ffffff;
    min-width:80px !important;
    font-size: 18px;
}
}


@media (max-width:767px) {

    .fonttext{
        font-size: 12px;
    }

    .altcel {
    min-height: 300px !important;
    height: auto !important;
}
.altavul {
    padding-top: 5px;
}

.text-h5{
    font-size: 15px !important;
}

.btncel {
    color:#ffffff;
    min-width:80px !important;
    padding: 0 !important;
     font-size: 12px;
}

/* Estilos para o select */
.styled-select {
  display: block;
  width: 100%;
  font-size: 12px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.v-toolbar-title__placeholder {
    overflow: auto;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.v-toolbar-title {
    flex: auto !important;
    font-size: 1.25rem;
    min-width: 0;
    font-size: 1.25rem;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1.75rem;
    text-transform: none;
}

}

.border-green {
    border: 4px solid green !important; /* Defina a cor e largura da borda */
    border-radius: 8px; /* Adicione um raio de borda para suavizar a aparência */
}

.check-icon {
    position: absolute;
    bottom: 5px; /* Ajuste conforme necessário */
    right: 5px; /* Ajuste conforme necessário */
    color: green;
    background-color: white;
    border-radius: 50%;
    padding: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5); /* Adiciona sombra para destaque */
}

</style>
