<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Configura galerias de grids por tela</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="ConfigGrid.lgtId" label="Id" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4" v-if="ListaTelas!=''">
                                    <v-select v-model="ConfigGrid.ctiId" label="Telas" :items="ListaTelas"
                                        item-title="ctiNome" item-value="ctiId" variant="outlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="ConfigGrid.lgtNome" :rules="nameRules" :counter="200"
                                        label="Nome Galeria" variant="outlined" required></v-text-field>
                                </v-col>

                                <v-col cols="12" md="2">
                                    <v-text-field v-model="ConfigGrid.lgtOrdem" label="Ordem" type="number"
                                        variant="outlined"></v-text-field>
                                </v-col>

                                <v-col cols="12" md="3">
                                    <v-select v-model="ConfigGrid.lgtVisivel" label="Visível" :items="Status"
                                        item-title="nome" item-value="lgtVisivel" variant="outlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="ConfigGrid.lgtLargura" label="Largura px (width)" type="number"
                                        variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="ConfigGrid.lgtAltura" label="Altura px (height) " type="number"
                                        variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-text-field v-model="ConfigGrid.lgtQtd" label="Quantidade" type="number"
                                        variant="outlined"></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="ConfigGrid.lgtId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="outlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="preprocessData(ConfigGrids)" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-lgtVisivel="item">
                            <span>{{ item.lgtVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-lgtOrdem="item">
                            <span>{{ parseInt(item.lgtOrdem) }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'ConfigListaGridsView',
    components: {
        EasyDataTable
    },
    data: () => ({
        ConfigGrid: {
            lgtId: 0,
            ctiId: '',
            lgtNome: '',
            lgtOrdem: '',
            lgtVisivel: '',
            lgtLargura: '',
            lgtAltura: '',
            lgtQtd: '',
        },
        ConfigGrids: [],
        ListaTelas:[],
        selectedVisivel: null,
        Status: [{ nome: "Sim", lgtVisivel: true }, { nome: "Não", lgtVisivel: false }],
        nameRules: [
            value => {
                if (value?.length <= 200) return true
                return 'Nome pode conter até 200 caracteres.'
            },
        ],

        headers: [
            { text: "Id", value: "lgtId", sortable: true, },
            { text: "Tela", value: "admCadastroTelasInst.ctiNome", sortable: true, },
            { text: "Nome", value: "lgtNome", sortable: true, },
            { text: "Ordem", value: "lgtOrdem", sortable: true, },
            { text: "Visivel", value: "lgtVisivel", sortable: true, },
            { text: "Largura", value: "lgtLargura", sortable: true, },
            { text: "Altura", value: "lgtAltura", sortable: true, },
            { text: "Qtde", value: "lgtQtd", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
    }),
    methods: {
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                lgtOrdem: parseInt(item.lgtOrdem),
            };
            });
        },
        Limpar() {
            this.ConfigGrid.lgtId = 0;
            this.ConfigGrid.ctiId = "";
            this.ConfigGrid.lgtNome = "";
            this.ConfigGrid.lgtOrdem = "";
            this.ConfigGrid.lgtVisivel = "";
            this.ConfigGrid.lgtLargura = "";
            this.ConfigGrid.lgtAltura = "";
            this.ConfigGrid.lgtQtd = "";
            this.selectedVisivel = "";
            this.Get();
        },
        Add() {
            if (this.ConfigGrid.lgtNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.ConfigGrid.lgtOrdem === ''){
                this.$mensagemAviso("Ordem Obrigatória.")
            }
            else if (this.ConfigGrid.lgtVisivel === ''){
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else if (this.ConfigGrid.ctiId == ""){
                this.$mensagemAviso("Tela obrigatória.")
            }
            else if (this.ConfigGrid.lgtAltura == ''){
                this.$mensagemAviso("Altura Obrigatório.")
            }
            else if (this.ConfigGrid.lgtLargura == ''){
                this.$mensagemAviso("Largura Obrigatório.")
            }
            else if (this.ConfigGrid.lgtQtd === ''){
                this.$mensagemAviso("Quantidade Obrigatório.")
            }
            else {
                this.ConfigGrid.lgtOrdem = parseInt(this.ConfigGrid.lgtOrdem)
                this.ConfigGrid.lgtAltura = parseInt(this.ConfigGrid.lgtAltura)
                this.ConfigGrid.lgtLargura = parseInt(this.ConfigGrid.lgtLargura)
                this.ConfigGrid.lgtQtd = parseInt(this.ConfigGrid.lgtQtd)
                this.ConfigGrid.AdmCadastroTelasInst = { ctiId: this.ConfigGrid.ctiId};
                axios.post(`/Process/InsListaGaleriaInstGrids/Add`, this.ConfigGrid)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        Put() {
            //console.log(this.ConfigGrid)
            if (this.ConfigGrid.lgtNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.ConfigGrid.lgtOrdem === ''){
                this.$mensagemAviso("Ordem Obrigatória.")
            }
            else if (this.ConfigGrid.lgtVisivel === ''){
                this.$mensagemAviso("Visível Obrigatório.")
            }
            else if (this.ConfigGrid.ctiId == ""){
                this.$mensagemAviso("Tela obrigatória.")
            }
            else if (this.ConfigGrid.lgtAltura == ''){
                this.$mensagemAviso("Altura Obrigatório.")
            }
            else if (this.ConfigGrid.lgtLargura == ''){
                this.$mensagemAviso("Largura Obrigatório.")
            }
            else if (this.ConfigGrid.lgtQtd === ''){
                this.$mensagemAviso("Quantidade Obrigatório.")
            }
            else {
                this.ConfigGrid.lgtOrdem = parseInt(this.ConfigGrid.lgtOrdem)
                this.ConfigGrid.lgtAltura = parseInt(this.ConfigGrid.lgtAltura)
                this.ConfigGrid.lgtLargura = parseInt(this.ConfigGrid.lgtLargura)
                this.ConfigGrid.lgtQtd = parseInt(this.ConfigGrid.lgtQtd)
                this.ConfigGrid.AdmCadastroTelasInst = { ctiId: this.ConfigGrid.ctiId};
                axios.put(`/Process/InsListaGaleriaInstGrids/Update`, this.ConfigGrid)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        EditGrid(item) {
            // Lógica para editar o item
            this.ConfigGrid = item;
            this.ConfigGrid.ctiId = item.admCadastroTelasInst.ctiId
            this.$scrollToTop();
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.lgtId;
                axios.delete(`/Process/InsListaGaleriaInstGrids/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                    }
                });
            }
            });
        },
        Get() {
            
            axios.get(`Process/AdmCadastroTelasInst/GetTelasListaGrids`)
                .then(response => {
                    this.ListaTelas = response.data;
                });

                axios.get(`/Process/InsListaGaleriaInstGrids/GetAll`)
                .then(response => {
                    this.ConfigGrids = response.data;
                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
