<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                        <div v-if="MostraProdutos">
                           <v-tab value="produtos" class="tabinfo">Produtos Taxas Adm</v-tab>
                         </div>
                     </v-tabs>
                     <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="TabelaPreco.ptcId" label="Id" variant="outlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="TabelaPreco.grtId" label="Grupo" variant="outlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="TabelaPreco.cdtId" label="Tela" variant="outlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-show="vshowidioma">
                                            <v-select v-model="TabelaPreco.idiId" label="Idioma" :items="Idiomas" @update:modelValue="CarregaLista(TabelaPreco.idiId)"
                                                item-title="idiNome" item-value="idiId" variant="outlined">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="8">
                                            <v-text-field v-model="TabelaPreco.ptcIdentificador"
                                                label="Identificador" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-select v-model="TabelaPreco.ptcAtivo" label="Ativo" :items="StatusAtivo"
                                                item-title="nome" item-value="ptcAtivo" variant="outlined">
                                            </v-select>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                           <!--Lista Produtos-->
                           <v-window-item value="produtos" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Lista Produtos</div>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" md="6" v-if="ListaProdutos!=''">
                                        <v-autocomplete v-model="ProdutosPreco.proId" label="Produtos" :items="ListaProdutos"
                                            item-title="proNomeProduto" item-value="proId" variant="outlined" searchable>
                                        </v-autocomplete>
                                    </v-col>
                                    <v-col cols="12" md="2" v-if="ListaProdutos!=''">
                                    <button type="button" class="btn btn-success" @click="AddProdutoPreco(ProdutosPreco.proId)">
                                        Incluir
                                    </button>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field v-model="termoPesquisa" @input="filtrarLista" placeholder="Digite para pesquisar" variant="outlined" />
                                    </v-col>

                                </v-row>
                            </v-container>
                            <v-form>
                                <v-form>
                                    <v-container>
                                        <v-row  v-for="(item, index) in listaFiltrada" :key="item.ppcId" :class="index % 2 === 0 ? 'linha-cinza' : 'linha-branca'" style="border:1px solid #cccccc;">
                                            <v-col cols="12" md="12" style="background-color:#364f66;">
                                               <span style="padding: 5px;border-radius:5px;color:#c1cad4;min-width:200px">{{ "Cód.: " + item.proProdutos.proId + " | " + item.proProdutos.proNomeProduto + " | Ref.: " + item.proProdutos.proReferencia }}</span>
                                            </v-col>
                                         
                                            <v-col cols="12" md="3" sm="6" xs="6">
                                                <v-text-field v-model="item.ppcValorDe" type="number"
                                                            @change="item.ppcValorDe != null || item.ppcValorDe != '' ? (item.ppcValorDe = parseFloat(item.ppcValorDe,10)) : (item.ppcValorDe = 0.0)"
                                                         label="Valor %" variant="outlined" required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="3" sm="6" xs="6">
                                                <v-text-field v-model="item.ppcValorPor" type="number"
                                                            @change="item.ppcValorPor != null || item.ppcValorPor != '' ? (item.ppcValorPor = parseFloat(item.ppcValorPor,10)) : (item.ppcValorPor = 0.0)"
                                                         label="Valor R$" variant="outlined" required></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="2" sm="6" xs="6">
                                                <v-select v-model="item.ppcDisponivel" label="Disponível" :items="StatusProdAtivo"
                                                            item-title="nome" item-value="ppcDisponivel" variant="outlined">
                                                        </v-select>
                                            </v-col>
                                            <v-col cols="12" md="2" sm="6" xs="6" style="display: none;">
                                                <v-text-field v-model="item.ppcEstoque" label="Estoque" type="number"
                                                          variant="outlined"></v-text-field>
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ item.ppcPadrao?'Sim':'Não' }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ item.ppcOrdem }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ item.ppcCampoExtra1 }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ item.ppcCampoExtra2 }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ item.ppcCampoExtra3 }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ item.ppcCampoExtra4 }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ item.ppcCampoExtra5 }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ item.ppcTexto1 }}
                                            </v-col>
                                            <v-col cols="12" md="3" style="display:none;">
                                                {{ item.ppcTexto2 }}
                                            </v-col>
                                            <v-col cols="12" md="1" sm="6" xs="6" style="display:inline-flex;">
                                                <v-btn icon @click.prevent="SalvaProdutosPrecos(item)" variant="text">
                                                            <v-icon>mdi-content-save-outline</v-icon>
                                                             <v-tooltip activator="parent" location="top">Salvar</v-tooltip>
                                                        </v-btn>
                                                <v-btn icon @click.prevent="DeleteProdutosPrecos(item)" variant="text">
                                                    <v-icon>mdi-delete-forever-outline</v-icon>
                                                    <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-form>

                            </v-form>
                        </v-window-item>
                    </v-window>
                
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="TabelaPreco.ptcId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="" v-if="MostraGrid && tab=='dados'">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="outlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="ListTabelaPreco" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-ptcAtivo="item">
                            <span>{{ item.ptcAtivo ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'

export default {
    name: 'ProProdutosTabelaPrecosView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Produtos Tabela Taxas Adm',
        InfoTela:'Lista de produtos',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 20, cdtNome: "Produtos Tabela Taxas Adm" }],
        //dados
        TabelaPreco: {
            ptcId: 0,
            grtId: 0,
            cdtId: 0,
            idiId: '',
            moeId: 1,
            ptcIdentificador: '',
            ptcAtivo: '',
        },
        ListTabelaPreco: [],
        StatusAtivo: [{ nome: "Sim", ptcAtivo: true }, { nome: "Não", ptcAtivo: false }],
        MostraProdutos: false,
         //dados
         //Produtos 
         ListaProdutos: [],
         ListaProdutosGeral:[],
         ListaProdutosPrecos:[],
         ProdutosPreco:{
            ppcId: 0,
            ptcId: '',
            proId:'',
            ppcCampoExtra1:'',
            ppcCampoExtra2:'',
            ppcCampoExtra3:'',
            ppcCampoExtra4:'',
            ppcCampoExtra5:'',
            ppcTexto1:'',
            ppcTexto2:'',
            ppcValorDe:'',
            ppcValorPor:'',
            ppcDisponivel:'',
            ppcPadrao:'',
            ppcOrdem:'',
            ppcEstoque:''
         },
         StatusProdAtivo:  [{ nome: "Sim", ppcDisponivel: true }, { nome: "Não", ppcDisponivel: false }],
         termoPesquisa: '',
         //Produtos
        
    }),
    methods: {
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        CarregaLista(){
           this.ListTabelaPreco = this.ListTabelaPrecoGeral.filter(x=> x.idiId == this.TabelaPreco.idiId);
        },
        Limpar() {
            this.TabelaPreco.ptcId=0;
            this.TabelaPreco.grtId=0;
            this.TabelaPreco.cdtId=0;
            this.TabelaPreco.moeId=1;
            this.TabelaPreco.ptcIdentificador="";
            this.TabelaPreco.ptcAtivo="";
            this.MostraProdutos = false;
            this.tab = 'dados';
            this.Get();
        },
        Add() {
               if (this.TabelaPreco.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.TabelaPreco.ptcIdentificador == '') {
                    this.$mensagemAviso("Campo Identificador Obrigatório.")
                }
                else if (this.TabelaPreco.ptcAtivo === '') {
                    this.$mensagemAviso("Campo Ativo Obrigatório.")
                }
                else if (this.TabelaPreco.ptcAtivo == true && this.ListTabelaPreco.filter(x=> x.ptcAtivo && x.idiId == this.TabelaPreco.idiId).length > 0) {
                    this.$mensagemAviso("Não é possível inserir mais de uma Tabela de Preços Ativa.")
                }
                else {
                    if (this.TabelaPreco.ptcAtivo === "" || this.TabelaPreco.ptcAtivo === null) {
                        this.TabelaPreco.ptcAtivo = false;
                    }
                     this.TabelaPreco.admGrupoTelas = { GrtId: this.TabelaPreco.grtId }
                    this.TabelaPreco.admCadastroTelas = { CdtId: this.TabelaPreco.cdtId }
                    this.TabelaPreco.cadIdiomas = { IdiId: this.TabelaPreco.idiId }
                    this.TabelaPreco.cadMoedas = {MoeId: this.TabelaPreco.moeId }
                 
                    axios.post(`/Process/ProProdutosTabelaPrecos/Add`, this.TabelaPreco)
                        .then(response => {
                            if (response.data > 0) {
                                    this.TabelaPreco.ptcId = response.data;
                                    this.Get();
                                    this.EditGrid(this.TabelaPreco);
                                    this.$mensagemAvisoSucesso("Registro Incluído. aba Lista Produtos liberada.")                  
                            }
                        });
            }
        },
        Put() {
            if (this.TabelaPreco.idiId == '') {
                    this.$mensagemAviso("Campo Idioma Obrigatório.")
                }
                else if (this.TabelaPreco.ptcIdentificador == '') {
                    this.$mensagemAviso("Campo Identificador Obrigatório.")
                }
                else if (this.TabelaPreco.ptcAtivo === '') {
                    this.$mensagemAviso("Campo Ativo Obrigatório.")
                }
            else {
                if (this.TabelaPreco.ptcAtivo === "" || this.TabelaPreco.ptcAtivo === null) {
                        this.TabelaPreco.ptcAtivo = false;
                    }
                     this.TabelaPreco.admGrupoTelas = { GrtId: this.TabelaPreco.grtId }
                    this.TabelaPreco.admCadastroTelas = { CdtId: this.TabelaPreco.cdtId }
                    this.TabelaPreco.cadIdiomas = { IdiId: this.TabelaPreco.idiId }
                    this.TabelaPreco.cadMoedas = {MoeId: this.TabelaPreco.moeId }

             
                axios.put(`/Process/ProProdutosTabelaPrecos/Update`, this.TabelaPreco)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Limpar();
                            this.tab = 'dados';
                        }
                    });
            }
        },
        DeleteGrid(item) {
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.ptcId;
                axios.delete(`/Process/ProProdutosTabelaPrecos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                        this.tab = 'dados';
                    }
                });
            }
            });
        },
        EditGrid(item) {
            this.tab = 'dados';
            // Lógica para editar o item          
            this.TabelaPreco = item;

            //Carrega Lista de Produtos
            axios.get(`/Process/ProProdutos/GetAllByCtiId/14`)
                        .then(response => {
                            this.ListaProdutosGeral = response.data.filter(x=> x.idiId == this.TabelaPreco.idiId); 
                            this.ListaProdutos = this.ListaProdutosGeral;
                            this.MostraProdutos = true;
                            this.CarregaListaProdutosPrecos();
                        });
           
            this.$scrollToTop();
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "ptcId", sortable: true, });
            this.headers.push({ text: "Identificador", value: "ptcIdentificador", sortable: true, });
            this.headers.push({ text: "Ativo", value: "ptcAtivo", sortable: true, });   
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        //Produto Preco
        AddProdutoPreco(value){
           this.ProdutosPreco.ProProdutos = {ProId: value};
           this.ProdutosPreco.ProProdutosTabelaPrecos = { PtcId: this.TabelaPreco.ptcId};
           if (this.ProdutosPreco.ppcDisponivel === "" || this.ProdutosPreco.ppcDisponivel === null) {
                this.ProdutosPreco.ppcDisponivel = true;
           }

           if (this.ProdutosPreco.ppcPadrao === "" || this.ProdutosPreco.ppcPadrao === null) {
                this.ProdutosPreco.ppcPadrao = false;
           }

           if (this.ProdutosPreco.ppcOrdem === "" || this.ProdutosPreco.ppcOrdem === null) {
                this.ProdutosPreco.ppcOrdem = 0;
            }
            else {
                this.ProdutosPreco.ppcOrdem = parseInt(this.ProdutosPreco.ppcOrdem);
            }

            if (this.ProdutosPreco.ppcEstoque === "" || this.ProdutosPreco.ppcEstoque === null) {
                this.ProdutosPreco.ppcEstoque = 0;
            }
            else {
                this.ProdutosPreco.ppcEstoque = parseInt(this.ProdutosPreco.ppcEstoque);
            }

            if (this.ProdutosPreco.ppcValorDe === "") {
                this.ProdutosPreco.ppcValorDe = null;
            }
            else {
                this.ProdutosPreco.ppcValorDe = parseFloat(this.ProdutosPreco.ppcValorDe)
            }

            if (this.ProdutosPreco.ppcValorPor === "") {
                this.ProdutosPreco.ppcValorPor = null;
            }
            else {
                this.ProdutosPreco.ppcValorPor = parseFloat(this.ProdutosPreco.ppcValorPor)
            }

            axios.post(`/Process/ProProdutosPrecos/Add`, this.ProdutosPreco)
                        .then(response => {
                            if (response.data > 0) {
                                    this.ProdutosPreco.pccId = response.data;
                                    this.CarregaListaProdutosPrecos();
                                    this.$mensagemAvisoSucesso("Registro Incluído")      
                                    this.ProdutosPreco.proId = "";            
                            }
                        });
        },
        CarregaListaProdutosPrecos(){
            axios.get(`/Process/ProProdutosPrecos/GetAllByPtcId/${this.TabelaPreco.ptcId}/0`)
                        .then(response => {
                            this.ListaProdutosPrecos = response.data;  
                            const result = this.ListaProdutosGeral.filter((item1) => {
                            // Verificando se o proId do item1 não existe em nenhum item do array2
                            return !this.ListaProdutosPrecos.some((item2) => item2.proProdutos.proId === item1.proId);
                            });
                            this.ListaProdutos = result;

                        });
        },
        SalvaProdutosPrecos(value){
            value.ProProdutos = {ProId: value.proProdutos.proId};
            value.ProProdutosTabelaPrecos = { PtcId: this.TabelaPreco.ptcId};
           if (value.ppcDisponivel === "" || value.ppcDisponivel === null) {
            value.ppcDisponivel = true;
           }

           if (value.ppcPadrao === "" || value.ppcPadrao === null) {
            value.ppcPadrao = false;
           }

           if (value.ppcOrdem === "" || value.ppcOrdem === null) {
            value.ppcOrdem = 0;
            }
            else {
                value.ppcOrdem = parseInt(value.ppcOrdem);
            }

            if (value.ppcEstoque === "" || value.ppcEstoque === null) {
                value.ppcEstoque = 0;
            }
            else {
                value.ppcEstoque = parseInt(value.ppcEstoque);
            }

            if (value.ppcValorDe === "") {
                value.ppcValorDe = null;
            }
            else {
                value.ppcValorDe = parseFloat(value.ppcValorDe)
            }

            if (value.ppcValorPor === "") {
                value.ppcValorPor = null;
            }
            else {
                value.ppcValorPor = parseFloat(value.ppcValorPor)
            }

         axios.put(`/Process/ProProdutosPrecos/Update`, value)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.CarregaListaProdutosPrecos();
                            this.ProdutosPreco.proId = ""; 
                        }
                    });
        },
        DeleteProdutosPrecos(item){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.ppcId;
                axios.delete(`/Process/ProProdutosPrecos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.CarregaListaProdutosPrecos();
                    }
                });
            }
            });
        },
        filtrarLista() {
        // Não é necessário fazer nada aqui, pois a lista filtrada é gerada através da computed property.
        },
        //Produto Preco
        Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.TabelaPreco.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }
                    axios.get(`/Process/ProProdutosTabelaPrecos/GetAllByCtiId/20/${this.TabelaPreco.moeId}`)
                        .then(response => {
                            this.ListTabelaPrecoGeral = response.data; 
                            if (this.TabelaPreco.idiId != ''){
                                this.CarregaLista();
                            }                           
                        });
                    this.montaGrid();
                    this.TabelaPreco.cdtId = 20;
                    this.TabelaPreco.grtId = 3;

                });
        }
    },
    created() {
        this.Get();
    },
    computed: {
    listaFiltrada() {
      const termoPesquisaNormalizado = this.termoPesquisa.trim().toLowerCase();
      if (!termoPesquisaNormalizado) {
        return this.ListaProdutosPrecos;
      } else {
        return this.ListaProdutosPrecos.filter(item =>
          item.proProdutos.proNomeProduto.toLowerCase().includes(termoPesquisaNormalizado) ||
          item.proProdutos.proReferencia.toLowerCase().includes(termoPesquisaNormalizado)
        );
      }
    }
  },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }

  .linha-cinza {
    background-color: #f8f6f6; /* Substitua pelo código de cor desejado para cinza */
  }
  .linha-branca {
    background-color: #ffffff; /* Substitua pelo código de cor desejado para branca */
  }
</style>
