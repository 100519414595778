<template>
       <div>
        <div class="pd-ltr-20">
           <div class="faq-wrap">
                <div id="accordion">
                    <div class="card-box pd-20 height-100-p mb-30">
                        <div id="Topo" class="collapse show">
                            <div class="card-body">
                                <div class="row align-items-center" v-if="DadosGarantidora!=''">
                                    <div class="col-md-12">
                                        <p class="font-18 max-width-600">Termos de Aceite {{ DadosGarantidora[0].itiNome }}</p>

                                    <div style="overflow-y: auto; height:450px; border: 1px solid #ccc; padding: 10px;">
                                      <p v-html="Contrato.itiResumo1"></p>
                                      <p v-html="Contrato.itiResumo2"></p>
                                      <p v-html="Contrato.itiDescricao1"></p>
                                      <p v-html="Contrato.itiDescricao2"></p>
                                      <p v-html="Contrato.itiDescricao3"></p>
                                      <p v-html="Contrato.itiDescricao4"></p>
                                     </div>
                                     <div style="padding-top: 30px;">
                                       <a :loading="isLoading" class="btn btn-primary btn-lg btn-block" href="#" @click.prevent="AceiteContrato()">Aceitar</a>
                                    </div>
                                    <div style="padding-top: 30px;text-align: center;">
                                      <button  @click="CancelarContrato()">Cancelar</button>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
           </div>
        </div>
        
      </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import { useRoute } from 'vue-router';

export default {
  name: 'AceiteClienteView',
  components: {
    moment
  },
  setup() {
        const route = useRoute();
        const clienteId = route.params.cliId;
        const imobiliariaId = route.params.imobId;
        const pedidoId = route.params.pedId;
        return {
          clienteId,
          imobiliariaId,
          pedidoId
        };
    },
  data: () => ({
    accordion: false,
    User:{},
    ListContratos:[],
    DadosGarantidora:[],
    ContEnts:[],
    Contrato:{},
    //contratoscliente
    ContratoAceite:{
      ctrId: 0,
      ctrContratoCod: '',
      ctrContratoModelo: '',
      ctrContratoTexto: '',
      ctrDataCadastro: '',
      ctrUrlContrato: '',
      cliId: '',
      ctrAceiteIP: '',
      ctrAceiteData: '',
    }
  }),
  methods: {
    AceiteContrato(){
      this.ContratoAceite.ctrContratoCod = this.ContEnts[0].cteModeloContratoCod;
      this.ContratoAceite.ctrContratoModelo = this.ContEnts[0].cteTipoContratoCod;
      this.ContratoAceite.ctrContratoTexto = this.Contrato.itiResumo1 + "</br> " + this.Contrato.itiResumo2 + "</br> " + this.Contrato.itiDescricao1 + "</br> " + this.Contrato.itiDescricao2 + "</br> " + this.Contrato.itiDescricao3 + "</br> " + this.Contrato.itiDescricao4;
      this.ContratoAceite.ctrDataCadastro = moment().format('YYYY-MM-DD');
      this.ContratoAceite.ctrUrlContrato = '';
      this.ContratoAceite.cliId = parseInt(this.clienteId);
      this.ContratoAceite.ctrAceiteData = moment().format('YYYY-MM-DD');

      axios.post(`/Process/CliClientesContratos/Add`, this.ContratoAceite)
            .then(response => {
                if (response.data > 0) {
                    this.$mensagemAvisoSucesso("Aceite Registrado.")
                    this.$router.push('/pgtocontratacaocliente/' + this.clienteId + '/' + this.imobiliariaId + '/' + this.pedidoId);
                }
            });

    },
    CancelarContrato(){
      //this.$router.push('/ContratacaoCancelada/' + this.clienteId + '/' + this.imobiliariaId + '/' + this.pedidoId);
      window.location.href = 'https://www.google.com.br';
    },
  },
  async created() {

    await axios.get('/Process/InsInstitucional/institucional/31/modeloscontratos')
            .then(response => {
                this.ListContratos = response.data
            })
            .catch(error => {
                console.error(error);
            });

        await axios.get(`/Process/CliEntidadesContratos/GetAll`)
              .then(response => {
                  this.ContEnts = response.data.filter(x=> x.cteEntidadeCod == 69);
                  if (this.ContEnts!=''){
                    this.Contrato = this.ListContratos.filter(x=> x.itiId == this.ContEnts[0].cteModeloContratoCod)[0]
                  }
              });

          //dados da Garantidora
      axios.get('/Process/InsInstitucional/institucional/34/informacoes')
                .then(response => {
                    this.DadosGarantidora = response.data;
                })
                .catch(error => {
                    console.error(error);
                });
  
  },
}
</script>

<style>

</style>
