<template>
       <div>
        <div class="">
           <div class="faq-wrap">
                <div id="accordion">
                    <div class="card-box pd-20 height-100-p mb-30">
                        <div id="Topo" class="collapse show">
                            <div class="card-body">
                                <div class="row align-items-center">
                                    <div class="col-md-4">
                                        <img src="/images/banner-img.png" alt="">
                                    </div>
                                    <div class="col-md-8">
                                        <h4 class="font-20 weight-500 mb-10 text-capitalize">
                                            Olá, <div class="weight-600 font-30 text-blue">{{  User.nome }}</div>
                                        </h4>
                                        <p class="font-18 max-width-600" v-html="TextoInicial"></p>
                                        <p v-if="textoavisoEndereco!='' || textoavisoContas!='' || textoavisoDocumentos!=''" class="font-18 max-width-600" v-html="TextoSecundario"></p>
                                        <p v-if="textoavisoEndereco!=''" class="font-18 max-width-600" v-html="textoavisoEndereco"></p>
                                        <p v-if="textoavisoContas!=''" class="font-18 max-width-600" v-html="textoavisoContas"></p>
                                        <p v-if="textoavisoDocumentos!=''" class="font-18 max-width-600" v-html="textoavisoDocumentos"></p>
                                        <router-link v-if="textoavisoEndereco!='' || textoavisoContas!='' || textoavisoDocumentos!=''" to="/Imobiliarias" class="btn btn-info" style="margin-right: 15px;">Ir para meu cadastro</router-link>
                                        <router-link v-if="textoavisoEndereco==''" to="/novacontratacao" class="btn btn-success">Iniciar nova contratação</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
           </div>
        </div>
        
      </div>
</template>

<script>
//import moment from 'moment'
import axios from 'axios';
export default {
  name: 'HomeView',
  components: {
  },
  data: () => ({
    accordion: false,
    User: [],
    TextoInicial:'Seja bem vindo ao portal Aqui Garante Imobiliárias',
    TextoSecundario: 'Não esqueça de ir no seu cadastro, e preencher os dados faltantes:',
    ListEndereco:[],
    textoavisoEndereco:'',
    ListContas:[],
    textoavisoContas:'',

    TiposDocumentos:[],
    ListEntidadesDocumentos:[],
    ListDocumentos:[],
    textoavisoDocumentos:'',
  }),
  methods: {
    documentoPresente(documento) {
      return this.ListDocumentos.some(doc => doc.cdoDocumento === documento.itiId);
    },
    encontrarDocumentosFaltantes() {
      const documentosFaltantes = [];
      this.TiposDocumentos.forEach(documento => {
        if (!this.documentoPresente(documento)) {
          documentosFaltantes.push(documento);
        }
      });
      return documentosFaltantes;
    }
    // formattedDate (value) {
    //    return this.$moment(value).format('DD/MM/YYYY')
    // }
  },
  async created() {
    const valorArmazenado = localStorage.getItem('_useravImob');
    if (valorArmazenado != '') {
      this.User = JSON.parse(valorArmazenado);
            //carrega endereco
            axios.get(`/Process/CliClientesEndereco/GetEnderecoByCli/`+ this.User.cliId + '/0')
                          .then(response => {
                              this.ListEndereco = response.data;                             
                              if (this.ListEndereco  == '' || this.ListEndereco == null){
                                  this.textoavisoEndereco = "- Dados do seu endereço.";
                              }            
                          });

             //carrega conta
             axios.get(`/Process/CliClientesContas/GetContasByCli/`+ this.User.cliId)
                        .then(response => {
                            this.ListContas = response.data;                             
                            if (this.ListContas  == '' || this.ListContas == null){
                              this.textoavisoContas = "- Dados da sua conta bancária.";
                            }            
                        });

             //carrega documentos
             await  axios.get(`/Process/CliClientesDocumentos/GetDocumentosByCli/`+ this.User.cliId + '/0')
                .then(response => {
                    this.ListDocumentos = response.data;                                      
                });

              await  axios.get('/Process/InsInstitucional/institucional/28/tiposdocumentos')
                .then(response => {
                    this.TiposDocumentos = response.data;
                    
                    axios.get(`/Process/CliEntidadesDocumentos/GetByEntiDocs/67/0`)
                        .then(response => {
                            this.ListEntidadesDocumentos = response.data;    
                                this.TiposDocumentos = this.TiposDocumentos.filter(x => {
                                    return this.ListEntidadesDocumentos.some(w => w.cteDocumentoCod === x.itiId);
                                });
                                const documentosFaltantes = this.encontrarDocumentosFaltantes();

                                if (documentosFaltantes.length > 0){
                                  this.textoavisoDocumentos = "- Envio dos documentos necessários.";
                                }  
              
                        });

                })
                .catch(error => {
                    console.error(error);
                });


    }
  },
}
</script>

<style>

</style>
