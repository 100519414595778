<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Cadastro de idiomas</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="Idioma.idiId" label="Id" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="8">
                                    <v-text-field v-model="Idioma.idiNome"
                                        label="Nome" variant="outlined" required></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-select v-model="Idioma.idiAtivo" label="Ativo" :items="StatusAtivo"
                                        item-title="nome" item-value="idiAtivo" variant="outlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="3">
                                    <v-file-input v-model="selectedFile" label="Imagem Bandeira" variant="outlined"
                                        prepend-icon="mdi-flag" accept="image/*"></v-file-input>
                                </v-col>
                                <v-col cols="12" md="3" v-if="Idioma.idiUrlImagem !== '' && Idioma.idiUrlImagem !== null">
                                    <img :src="$imgURL + Idioma.idiUrlImagem" style="width: 100px" />
                                    <v-btn icon density="compact" color="red" class="delete-icon" variant="text">
                                        <v-icon @click="DeleteImagem()" style="font-size:22px;">mdi-alpha-x-circle</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="Idioma.idiId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="outlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="Idiomas" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-idiUrlImagem="item">
                            <img v-if="item.idiUrlImagem!='' && item.idiUrlImagem!=null" :src="$imgURL + item.idiUrlImagem" style="width: 40px;height:auto;border-radius:50px" />
                            <img v-else src="/images/userNo.png" style="width: 40px;height:auto;border-radius:50px" />
                        </template>
                        <template #item-idiAtivo="item">
                            <span>{{ item.idiAtivo ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'CadastroIdiomasView',
    components: {
        EasyDataTable
    },
    data: () => ({
        Idioma: {
            idiId: 0,
            idiNome: '',
            idiAtivo: '',
            idiUrlImagem: '',
        },
        Idiomas: [],
        StatusAtivo: [{ nome: "Sim", idiAtivo: true }, { nome: "Não", idiAtivo: false }],
        headers: [
            { text: "Id", value: "idiId", sortable: true, },
            { text: "Nome", value: "idiNome", sortable: true, },
            { text: "Ativo", value: "idiAtivo", sortable: true, },
            { text: "Imagem", value: "idiUrlImagem"},
            { text: 'Ações', value: 'actions' }
        ],
        itemsSelected: [],
        selectedItem: null,
        searchValue: '',
        selectedFile: null,
    }),
    methods: {
        Limpar() {
            this.Idioma.idiId = 0;
            this.Idioma.idiNome = '';
            this.Idioma.idiAtivo = '';
            this.Idioma.idiUrlImagem = '';
            this.selectedFile = null;
            this.Get();
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        async Add() {
            if (this.Idioma.idiNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else {
                if (this.selectedFile != '' && this.selectedFile != null) {
                    //console.log(this.selectedFile[0].size)
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.Idioma.idiUrlImagem = uploadSuccess.data.nameFile;
                        if (this.Idioma.idiAtivo === "" || this.Idioma.idiAtivo === null){
                            this.Idioma.idiAtivo = true;
                        }
                        axios.post(`/Process/CadIdiomas/Add`, this.Idioma)
                            .then(response => {
                                if (response.data > 0) {
                                    this.$mensagemAvisoSucesso("Registro Incluído.")
                                    this.Get();
                                    this.Limpar();
                                }
                            });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else {
                    this.Idioma.idiUrlImagem = "";
                    if (this.Idioma.idiAtivo === "" || this.Idioma.idiAtivo === null){
                            this.Idioma.idiAtivo = true;
                        }
                    axios.post(`/Process/CadIdiomas/Add`, this.Idioma)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.Get();
                                this.Limpar();
                            }
                        });
                }
            }
        },
        async Put() {
            if (this.Idioma.idiNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else {
                if (this.selectedFile != '' && this.selectedFile != null) {
                    //console.log(this.selectedFile[0].size)
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.Idioma.idiUrlImagem = uploadSuccess.data.nameFile;
                        if (this.Idioma.idiAtivo == "" || this.Idioma.idiAtivo == null){
                            this.Idioma.idiAtivo = true;
                        }
                        axios.put(`/Process/CadIdiomas/Update`, this.Idioma)
                            .then(response => {
                                if (response.status == 200) {
                                    this.$mensagemAvisoSucesso("Registro Alterado.")
                                    this.Get();
                                    this.Limpar();
                                }
                            });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else {
                    if (this.Idioma.idiUrlImagem != "") {
                        this.Idioma.idiUrlImagem = "0";
                    }
                    axios.put(`/Process/CadIdiomas/Update`, this.Idioma)
                        .then(response => {
                            if (response.status == 200) {
                                this.$mensagemAvisoSucesso("Registro Alterado.")
                                this.Get();
                                this.Limpar();
                            }
                        });
                }
            }
        },
        EditGrid(item) {
            // Lógica para editar o item
            this.Idioma = item;
            this.$scrollToTop();
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.idiId;
                axios.delete(`/Process/CadIdiomas/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                    }
                });
            }
            });
        },
        DeleteImagem(){
            this.Idioma.idiUrlImagem = "";
            /*this.$mensagemConfirma('Tem certeza que deseja excluir?')
                .then((result) => {
                    if (result.isConfirmed) {
                        // Lógica para excluir o item
                        axios.post(`/Process/UploadImg/delete/${value}`)
                            .then(response => {
                                if (response.status == 200) {
                                    this.$mensagemAvisoSucesso("Imagem Excluida.")
                                    
                                }
                            });
                    }
                });*/
        },
        Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data;
                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
