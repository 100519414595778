<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <v-tabs v-model="tab" color="deep-purple-accent-4" bg-color="#f7f7f7" >
                        <v-tab value="dados" class="tabinfo">Dados</v-tab>
                        <v-tab value="endereco" class="tabinfo" v-if="Clientes.cliId!=0 && Clientes.cliId!=''">Endereço</v-tab>
                        <v-tab value="contratos" class="tabinfo" v-if="Clientes.cliId!=0 && Clientes.cliId!=''">Contratos</v-tab>
                        <v-tab value="documentos" class="tabinfo" v-if="Clientes.cliId!=0 && Clientes.cliId!='' && TiposDocumentos!='' && TiposDocumentos!= null">Documentos</v-tab>
                        <v-tab value="contas" class="tabinfo" v-if="Clientes.cliId!=0 && Clientes.cliId!=''">Dados Bancários</v-tab>
                     </v-tabs>
                     <v-window v-model="tab">
                        <v-window-item value="dados">
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Clientes.cliId" label="Id" variant="outlined"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-select v-model="Clientes.cctId" label="Tipo" :items="TipoClientes" @update:modelValue="LimpaCampo(Clientes.cctId)"
                                                item-title="cctNome" item-value="cctId" variant="outlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="7">
                                            <v-text-field v-model="Clientes.cliNomeEmpresa"
                                                label="Nome" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-select v-model="Clientes.cliVisivel" label="Visível" :items="StatusVisivel"
                                                item-title="nome" item-value="cliVisivel" variant="outlined">
                                            </v-select>
                                        </v-col>                                     
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Clientes.cliTelefone" v-mask="'(##) ####-#####'"
                                                label="Telefone" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Clientes.cliWhatsapp" v-mask="'(##) ####-#####'"
                                                label="Whatsapp" variant="outlined" required></v-text-field>
                                        </v-col>
                                     
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="Clientes.cliEmail" label="E-mail" 
                                            variant="outlined"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" style="display: none;">
                                            <v-text-field v-model="Clientes.cliSenha" :type="senhaVisivel ? 'text' : 'password'"
                                                label="Senha" variant="outlined"
                                                :append-icon="senhaVisivel ? 'mdi-eye-off' : 'mdi-eye'"
                                                @click:append="toggleSenhaVisivel"></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="Clientes.cctId == 1" style="display: none;"> 
                                            <v-text-field v-model="Clientes.cliDataNascimento" type="date"
                                                label="Data Nascimento" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-if="Clientes.cctId == 1">
                                            <v-text-field v-model="Clientes.cliCpf" v-mask="'###.###.###-##'"
                                                label="CPF" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="Clientes.cctId == 2">
                                            <v-text-field v-model="Clientes.cliCnpj" v-mask="'##.###.###/####-##'"
                                                label="CNPJ" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="5" v-if="Clientes.cctId == 2">
                                            <v-text-field v-model="Clientes.cliRazaoSocial"
                                                label="Razão Social" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" v-if="Clientes.cctId == 2">
                                            <v-text-field v-model="Clientes.cliResponsavel" 
                                                label="Responsável" variant="outlined" required></v-text-field>
                                        </v-col>

                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="Clientes.cliCampoExtra1"
                                                label="CRECI" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Clientes.cliDataCadastro" type="date" :disabled="Clientes.cliId !== 0"
                                                label="Cadastro" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" style="background-color:beige;display: none;">
                                            <v-select v-model="Clientes.cliAtivo" label="Ativado" :items="StatusAtivo"
                                                item-title="nome" item-value="cliAtivo" variant="outlined">
                                            </v-select>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliCampoExtra2"
                                                label="Onde Conheceu" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliCampoExtra3"
                                                label="Campo Extra 3" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliCampoExtra4"
                                                label="Campo Extra 4" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliCampoExtra5"
                                                label="Campo Extra 5" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliData1" type="date"
                                                label="Data 1" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4" style="display: none;">
                                            <v-text-field v-model="Clientes.cliData2" type="date"
                                                label="Data 2" variant="outlined" required></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                        <v-window-item value="endereco" >
                            <v-form>
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Endereco.cceId" label="Id" variant="outlined"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="3">
                                            <v-text-field v-model="Endereco.cliCep" v-mask="'#####-###'"
                                                label="CEP" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <button type="button" class="btn btn-info" @click.prevent="Buscacep(Endereco.cliCep)" style="color:#ffffff;">
                                                Verificar CEP
                                            </button>
                                        </v-col>
                                        <v-col cols="12" md="7" v-if="showend">
                                            <v-text-field v-model="Endereco.cliEndereco"
                                                label="Endereço" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2" v-if="showend">
                                            <v-text-field v-model="Endereco.cliNumero" type="number"
                                                label="Número" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="showend">
                                            <v-text-field v-model="Endereco.cliComplemento" type="number"
                                                label="Complemento" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="showend" style="display:none;">
                                            <v-text-field v-model="Endereco.cliPais" 
                                                label="País" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="showend">
                                            <v-text-field v-model="Endereco.cliEstado" maxlength="2"
                                                label="Sigla Estado" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="showend">
                                            <v-text-field v-model="Endereco.cliCidade" 
                                                label="Cidade" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="3" v-if="showend">
                                            <v-text-field v-model="Endereco.cliBairro" 
                                                label="Bairro" variant="outlined" required></v-text-field>
                                        </v-col>                                  
                                    </v-row>
                                    <v-row v-if="showend">
                                        <v-col cols="12" md="12" style="text-align: center;">
                                            <button v-if="Endereco.cceId != 0" type="button" class="btn btn-success" @click="PutEndereco()">
                                                Alterar Endereço
                                            </button>
                                            <button v-else type="button" class="btn btn-success" @click="AddEndereco()">
                                                Salvar Endereço
                                            </button>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                        <v-window-item value="contas" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Adicione Conta Bancária</div>
                            <v-form>
                               <v-container>
                                   <v-row>
                                        <v-col cols="12" md="4" v-show="false">
                                            <v-text-field v-model="Contas.cdcId" label="Id" variant="outlined"></v-text-field>
                                        </v-col>
                                    </v-row>
                                   <v-row>
                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="Contas.cdcBanco" 
                                                label="Cód. Banco" variant="outlined" required></v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="Contas.cdcBancoNome" 
                                                label="Nome Banco" variant="outlined" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="2">
                                            <v-text-field v-model="Contas.cdcAgencia" 
                                                label="Agência" variant="outlined" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="Contas.cdcConta" 
                                                label="Conta" variant="outlined" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="Contas.cdcTipoConta" 
                                                label="Tipo Conta" variant="outlined" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="Contas.cdcContaTitular" 
                                                label="Nome Titular Conta" variant="outlined" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="Contas.cdcTitularDocumento" 
                                                label="Documento Titular Conta" variant="outlined" required>
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="12" md="6">
                                            <v-text-field v-model="Contas.cdcChavePix" 
                                                label="Chave Pix" variant="outlined" required>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" md="12" style="text-align: center;">
                                            <button v-if="Contas.cdcId != 0" type="button" class="btn btn-success" @click="PutConta()">
                                                Alterar Conta
                                            </button>
                                            <button v-else type="button" class="btn btn-success" @click="AddConta()">
                                                Salvar Conta
                                            </button>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-form>
                        </v-window-item>
                         <!--Grids-->
                         <v-window-item value="contratos" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Contratos</div>
                            <v-form>
                                <v-form>
                                    <v-container>
                                        <v-row>
                                            <v-table>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">
                                                         Data Contrato
                                                    </th>
                                                    <th class="text-left">
                                                       Contrato
                                                    </th>
                                                    <th class="text-left">
                                                       Visualização
                                                    </th>
                                                    <th class="text-left">
                                                       IP Aceite
                                                    </th>
                                                    <th class="text-left">
                                                       Data Aceite
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in ListContratos" :key="item.ctrId">
                                                    <td>{{ formattedDate(item.ctrDataCadastro) }}</td>
                                                    <td>{{ ModelosContrato.filter(x=>x.itiId == item.ctrContratoCod)[0].itiNome }}</td>
                                                    <td style="text-align: center;"><a href="#" @click.prevent="AbreDialog(ModelosContrato.filter(x=>x.itiId == item.ctrContratoCod)[0].itiNome,item.ctrContratoTexto)"><v-icon style="font-size:35px;">mdi-file-eye</v-icon></a></td>
                                                    <td>{{ item.ctrAceiteIP }}</td>
                                                    <td>{{ item.ctrAceiteData != null ? formattedDate(item.ctrAceiteData) : '' }}</td>
                                                </tr>
                                                </tbody>
                                            </v-table>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-form>
                        </v-window-item>
                        <v-window-item value="documentos" >
                            <div style="padding-top: 20px;padding-bottom: 10px;padding-left: 20px;">Selecione Documento e faça o envio</div>
                            <v-form>
                                <v-form>
                                    <v-container>
                                    <v-row>
                                            <v-col cols="12" md="3">
                                                <v-select v-model="DocSelected" label="Nome" :items="TiposDocumentos"
                                                    item-title="itiNome" item-value="itiId" variant="outlined">
                                                </v-select>
                                            </v-col>
                                            <v-col cols="12" md="5">
                                                <v-file-input v-model="selectedFile" label="Selecione Documento" variant="outlined"
                                                   prepend-icon="mdi-image-outline" accept="image/jpeg, image/png, image/jpg, application/pdf"></v-file-input>
                                            </v-col>
                                            <v-col cols="12" md="3">
                                                <button type="button" class="btn btn-info" @click.prevent="EnviarDocumento()" style="color:#ffffff;">
                                                    Enviar Documento
                                                </button>
                                            </v-col>
                                    </v-row>
                                        <v-row>
                                            <v-table>
                                                <thead>
                                                <tr>
                                                    <th class="text-left">
                                                         Cód.
                                                    </th>
                                                    <th class="text-left">
                                                       Documento
                                                    </th>
                                                    <th class="text-left">
                                                       Visualização
                                                    </th>
                                                    <th>
                                                        Ações
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="item in ListDocumentos" :key="item.cdoId">
                                                    <td>{{ item.cdoDocumento }}</td>
                                                    <td>{{ item.cdoNomeDocumento }}</td>
                                                    <td style="text-align: center;">
                                                         <a :href="$imgURL + item.cdoUrlDocumento" target="_blank">
                                                            <v-icon style="font-size:35px;">mdi-file-eye</v-icon>
                                                        </a>
                                                    </td>
                                                    <td>
                                                        <v-btn icon @click.prevent="DeleteDocumento(item)" variant="text">
                                                            <v-icon>mdi-delete-forever-outline</v-icon>
                                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                                        </v-btn>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </v-table>
                                        </v-row>
                                    </v-container>
                                </v-form>
                            </v-form>
                        </v-window-item>
                    </v-window>
                
                    <form v-if="tab=='dados'">
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="Clientes.cliId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="" v-if="MostraGrid && tab=='dados'">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="outlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="ListClientes" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-cliVisivel="item">
                            <span>{{ item.cliVisivel ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-cliAtivo="item">
                            <span :class="{ 'btn-ativo': item.cliAtivo, 'btn-inativo': !item.cliAtivo }">{{ item.cliAtivo ? 'Sim' : 'Não' }}</span>
                        </template>
                        <template #item-cliDataCadastro="item">
                            <span>{{ item.cliDataCadastro!=null? formattedDate(item.cliDataCadastro) : null }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
            <v-dialog v-model="dialog" max-width="700">
                <v-card>
                <v-card-title>
                    {{TituloDialog}}
                </v-card-title>
                <v-card-text style="width: 100%;height:500px;overflow-y: scroll;">
                   <p v-html="TextoDialog" style="font-size:12px;text-decoration:justify;"></p>
                </v-card-text>
                <v-card-actions>
                    <v-btn color="primary" text @click="dialog = false">Fechar</v-btn>
                </v-card-actions>
                </v-card>
            </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import moment from 'moment'
import { SHA256 } from 'crypto-js';

export default {
    name: 'ImobiliariasView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        editor: DecoupledEditor,
        tab: null,
        searchValue: '',
        itemsSelected: [],
        NomeTela:'Cadastro de Parceiros',
        InfoTela:'Lista de Parceiros',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 17, cdtNome: "Cadastro Parceiros" }],
        dialog:false,
        TituloDialog:'',
        TextoDialog:'',
        //dados
        Clientes: {
            cliId: 0,
            cctId: "",
            cliVisivel: '',
            cliDataCadastro: '',
            cliNomeEmpresa: '',
            cliRazaoSocial: '',
            cliCnpj: '',
            cliCpf: '',
            cliTelefone: '',
            cliWhatsapp: '',
            cliResponsavel: '',
            cliEmail: '',
            cliSenha: '',
            cliAtivo: '',
            cliDataNascimento: '',
            cliCampoExtra1: '',
            cliCampoExtra2: '',
            cliCampoExtra3: '',
            cliCampoExtra4: '',
            cliCampoExtra5: '',
            cliTexto1: '',
            cliTexto2: '',
            cliTexto3: '',
            cliData1: '',
            cliData2: '',
            cliIdPai: 0,
            cliTipoCli:70,
            cliUserPar:0,
        },
        ListClientes: [],
        StatusVisivel: [{ nome: "Sim", cliVisivel: true }, { nome: "Não", cliVisivel: false }],
        StatusAtivo: [{ nome: "Sim", cliAtivo: true }, { nome: "Não", cliAtivo: false }],
        //dados
        TipoClientes:[],
        senhaVisivel: false,
        isEmailValid:false,
        emailRules: [
        (v) => !!v || "E-mail é obrigatório",
        (v) => /.+@.+\..+/.test(v) || "E-mail deve ser válido",
      ],
        //endereco
        ListEndereco:[],
        showend:false,
        Endereco: {
            cceId: 0,
            grtId: '',
            cdtId:'',
            idiId:1,
            cliEndereco: '',
            cliNumero:'',
            cliComplemento:'',
            cliObsEndereco:'',
            cliCep:'',
            cliPais:'Brasil',
            cliEstado:'',
            cliCidade:'',
            cliBairro:'',
            cliId:'',
        },
        //contas
        ListContas:[],
        showconta:false,
        Contas: {
            cdcId:0,
            cliId:'',
            cdcBanco:'',
            cdcBancoNome:'',
            cdcAgencia:'',
            cdcConta:'',
            cdcContaTitular:'',
            cdcTitularDocumento:'',
            cdcChavePix:'',
            cdcTipoConta:'',
        },
        //contratos
        ListContratos:[],
        Contratos:{
            ctrId:0,
            ctrContratoCod:'',
            ctrContratoModelo:'',
            ctrContratoTexto:'',
            ctrDataCadastro:'',
            ctrUrlContrato:'',
            cliId:'',
            ctrAceiteIP:'', 
            ctrAceiteData:''
        },
        ModelosContrato:[],
        //Documentos
        ListDocumentos:[],
        Documentos: {
            cdoId:0,
            cdoDocumento:'', 
            cdoNomeDocumento:'', 
            cdoUrlDocumento:'',
            cliId:'',
        },
        DocSelected:'',
        ListaDocs:[],
        TiposDocumentos:[],
        ListEntidadesDocumentos:[],
        selectedFile: null,
          //Usuarios
          Usuarios:[],
        Usuario: {
            cusId: 0,
            cusNome: '',
            cusEmail: '',
            cusTelefone: '',
            cusWhatsapp: '',
            cusSenha: '',
            cusDtCadastro: null,
            tusId: '',
            cusAtivo: '',
            cusUrlImagem: '',
            cliId:''
        },
        TelasParcAcesso: [
        {
            "cdtId": 1,
            "tusId": 0,
            "cusTipo": 1
        }, 
        {
            "cdtId": 34,
            "tusId": 0,
            "cusTipo": 1
        },
        {
            "cdtId": 37,
            "tusId": 0,
            "cusTipo": 1
        },
        {
            "cdtId": 36,
            "tusId": 0,
            "cusTipo": 1
        },
    ],
    DadosGarantidora:[],
    }),
    methods: {
        LimpaCampo(value){
           if (value == 1){
            this.Clientes.cliCnpj = '';
           }
           else{
            this.Clientes.cliCpf = '';
           }
        },
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        toggleSenhaVisivel() {
            this.senhaVisivel = !this.senhaVisivel;
        },
        Limpar() {
            this.Clientes.cliId = 0;
            this.Clientes.cctId= "";
            this.Clientes.cliVisivel="";
            this.Clientes.cliDataCadastro="";
            this.Clientes.cliNomeEmpresa="";
            this.Clientes.cliRazaoSocial="";
            this.Clientes.cliCnpj="";
            this.Clientes.cliCpf="";
            this.Clientes.cliTelefone="";
            this.Clientes.cliWhatsapp="";
            this.Clientes.cliResponsavel="";
            this.Clientes.cliEmail="";
            this.Clientes.cliSenha="";
            this.Clientes.cliAtivo="";
            this.Clientes.cliDataNascimento="";
            this.Clientes.cliCampoExtra1="";
            this.Clientes.cliCampoExtra2="";
            this.Clientes.cliCampoExtra3="";
            this.Clientes.cliCampoExtra4="";
            this.Clientes.cliCampoExtra5="";
            this.Clientes.cliTexto1="";
            this.Clientes.cliTexto2="";
            this.Clientes.cliTexto3="";
            this.Clientes.cliData1="";
            this.Clientes.cliData2="";
            //endereco
            this.ListEndereco = [];
            this.Endereco.cceId=0;
            this.Endereco.grtId='';
            this.Endereco.cdtId='';
            this.Endereco.idiId=1;
            this.Endereco.cliEndereco='';
            this.Endereco.cliNumero='';
            this.Endereco.cliComplemento='';
            this.Endereco.cliObsEndereco='';
            this.Endereco.cliCep='';
            this.Endereco.cliPais='';
            this.Endereco.cliEstado='';
            this.Endereco.cliCidade='';
            this.Endereco.cliBairro='';
            this.Endereco.cliId='';
            //contas
            this.Contas.cdcId = 0;
            this.Contas.cliId = '';
            this.Contas.cdcBanco = '';
            this.Contas.cdcBancoNome = '';
            this.Contas.cdcAgencia = '';
            this.Contas.cdcConta = '';
            this.Contas.cdcContaTitular = '';
            this.Contas.cdcTitularDocumento = '';
            this.Contas.cdcChavePix = '';
            this.Contas.cdcTipoConta = '';
            //contratos
            this.Contratos.ctrId = 0;
            this.Contratos.ctrContratoCod = '';
            this.Contratos.ctrContratoModelo = '';
            this.Contratos.ctrContratoTexto = '';
            this.Contratos.ctrDataCadastro = '';
            this.Contratos.ctrUrlContrato = '';
            this.Contratos.cliId = '';
            this.Contratos.ctrAceiteIP = '';
            this.Contratos.ctrAceiteData = '';
            //documentos
            this.Documentos.cdoId = 0;
            this.Documentos.cdoDocumento = '';
            this.Documentos.cdoNomeDocumento = '';
            this.Documentos.cdoUrlDocumento = '';
            this.Documentos.cliId = '';
            //usuarios
            this.Usuario.cusId = 0;
            this.Usuario.cusNome = '';
            this.Usuario.cusEmail = '';
            this.Usuario.cusTelefone = '';
            this.Usuario.cusWhatsapp = '';
            this.Usuario.cusSenha = '';
            this.Usuario.cusDtCadastro = '';
            this.Usuario.tusId = '';
            this.Usuario.cusAtivo = '';
            this.Usuario.cusUrlImagem = '';
            this.tab = 'dados';
            this.Get();
        },
        Add() {
            const valida = this.ListClientes.filter(x=> ((x.cliClienteTipo.cctId == 2 && x.cliCnpj == this.Clientes.cliCnpj) || (x.cliClienteTipo.cctId == 1 && x.cliCpf == this.Clientes.cliCpf)) && x.cliId != this.Clientes.cliId)
            
            if (valida.length > 0){
                this.$mensagemAviso("Documento já cadastrado.")
            } else if (this.Clientes.cctId == '') {
                this.$mensagemAviso("Campo Tipo Obrigatório.")
            }
            else if (this.Clientes.cliNomeEmpresa == '' && this.Clientes.cctId == 1) {
                this.$mensagemAviso("Campo Nome Imobiliária Obrigatório.")
            }
            else if (this.Clientes.cliCpf == '' && this.Clientes.cctId == 1) {
                this.$mensagemAviso("Campo CPF Obrigatório.")
            }
            else if (this.Clientes.cliNomeEmpresa == '' && this.Clientes.cctId == 2) {
                this.$mensagemAviso("Campo Nome Empresa Obrigatório.")
            }
            else if (this.Clientes.cliCnpj == '' && this.Clientes.cctId == 2) {
                this.$mensagemAviso("Campo CNPJ Obrigatório.")
            }
            else if (this.Clientes.cliRazaoSocial == '' && this.Clientes.cctId == 2) {
                this.$mensagemAviso("Campo Razão Social Obrigatório.")
            }
            else if (this.Clientes.cliVisivel === '' || this.cliVisivel === null) {
            this.$mensagemAviso("Campo Visível Obrigatório.")
            }
            else if (this.Clientes.cliWhatsapp === '' || this.cliWhatsapp === null) {
                this.$mensagemAviso("Campo Whatsapp Obrigatório.")
            }
            else if (this.Clientes.cliEmail === '' || this.cliEmail === null) {
                this.$mensagemAviso("Campo E-mail Obrigatório.")
            }
            else if (this.Clientes.cliCampoExtra1 === '' || this.cliCampoExtra1 === null){
                    this.$mensagemAviso("Campo CRECI Obrigatório.")
                }
                else {
                    if (this.Clientes.cliVisivel === "" || this.Clientes.cliVisivel === null) {
                        this.Clientes.cliVisivel = true;
                    }
                    if (this.Clientes.cliAtivo === "" || this.Clientes.cliAtivo === null) {
                        this.Clientes.cliAtivo = true;
                    }
                    this.Clientes.cliClienteTipo = { CctId: this.Clientes.cctId }
                    if (this.Clientes.cliDataNascimento === "" || this.Clientes.cliDataNascimento === null ) {
                        this.Clientes.cliDataNascimento = null;
                    }
                    else{
                        this.Clientes.cliDataNascimento = this.$moment(this.Clientes.cliDataNascimento).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliData1 === "" || this.Clientes.cliData1 === null) {
                        this.Clientes.cliData1 = null;
                    }
                    else{
                        this.Clientes.cliData1 = this.$moment(this.Clientes.cliData1).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliData2 === "" || this.Clientes.cliData2 === null) {
                        this.Clientes.cliData2 = null;
                    }
                    else{
                        this.Clientes.cliData2 = this.$moment(this.Clientes.cliData2).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliDataCadastro === "" || this.Clientes.cliDataCadastro === null ) {
                        this.Clientes.cliDataCadastro = this.$moment().format('YYYY-MM-DD')
                    }
                    else{
                        this.Clientes.cliDataCadastro = this.$moment(this.Clientes.cliDataCadastro).format('YYYY-MM-DD')
                    }

                    this.Clientes.cliSenha = SHA256(this.Clientes.cliSenha).toString();
                    this.Clientes.cliTipoCli = 70;
                    axios.post(`/Process/CliClientes/Add`, this.Clientes)
                        .then(response => {
                            if (response.data > 0) {
                                  this.$mensagemAvisoSucesso("Registro Incluído.")
                                  this.AddUsuario(response.data);               
                            }
                        });
            }
        },
        Put() {
            const valida = this.ListClientes.filter(x=> ((x.cliClienteTipo.cctId == 2 && x.cliCnpj == this.Clientes.cliCnpj) || (x.cliClienteTipo.cctId == 1 && x.cliCpf == this.Clientes.cliCpf)) && x.cliId != this.Clientes.cliId)
            
            if (valida.length > 0){
                this.$mensagemAviso("Documento já cadastrado.")
            }
            else if (this.Clientes.cctId == '') {
                this.$mensagemAviso("Campo Tipo Obrigatório.")
            }
            else if (this.Clientes.cliNomeEmpresa == '' && this.Clientes.cctId == 1) {
                this.$mensagemAviso("Campo Nome Imobiliária Obrigatória.")
            }
            else if (this.Clientes.cliCpf == '' && this.Clientes.cctId == 1) {
                this.$mensagemAviso("Campo CPF Obrigatório.")
            }
            else if (this.Clientes.cliNomeEmpresa == '' && this.Clientes.cctId == 2) {
                this.$mensagemAviso("Campo Nome Empresa Obrigatório.")
            }
            else if (this.Clientes.cliCnpj == '' && this.Clientes.cctId == 2) {
                this.$mensagemAviso("Campo CNPJ Obrigatório.")
            }
            else if (this.Clientes.cliRazaoSocial == '' && this.Clientes.cctId == 2) {
                this.$mensagemAviso("Campo Razão Social Obrigatório.")
            }
            else if (this.Clientes.cliVisivel === '' || this.cliVisivel === null) {
                this.$mensagemAviso("Campo Visível Obrigatório.")
            }
            else if (this.Clientes.cliWhatsapp === '' || this.cliWhatsapp === null) {
                this.$mensagemAviso("Campo Whatsapp Obrigatório.")
            }
            else if (this.Clientes.cliEmail === '' || this.cliEmail === null) {
                this.$mensagemAviso("Campo E-mail Obrigatório.")
            }
            else if (this.Clientes.cliCampoExtra1 === '' || this.Clientes.cliCampoExtra1 === null){
                    this.$mensagemAviso("Campo CRECI Obrigatório.")
                }
            else {
                if (this.Clientes.cliVisivel === "" || this.Clientes.cliVisivel === null) {
                        this.Clientes.cliVisivel = false;
                    }
                    this.Clientes.cliClienteTipo = { CctId: this.Clientes.cctId }

                    if (this.Clientes.cliDataNascimento === "" || this.Clientes.cliDataNascimento === null ) {
                        this.Clientes.cliDataNascimento = null;
                    }
                    else{
                        this.Clientes.cliDataNascimento = this.$moment(this.Clientes.cliDataNascimento).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliData1 === "" || this.Clientes.cliData1 === null) {
                        this.Clientes.cliData1 = null;
                    }
                    else{
                        this.Clientes.cliData1 = this.$moment(this.Clientes.cliData1).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliData2 === "" || this.Clientes.cliData2 === null) {
                        this.Clientes.cliData2 = null;
                    }
                    else{
                        this.Clientes.cliData2 = this.$moment(this.Clientes.cliData2).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliDataCadastro === "" || this.Clientes.cliDataCadastro === null ) {
                        this.Clientes.cliDataCadastro = this.$moment().format('YYYY-MM-DD')
                    }
                    else{
                        this.Clientes.cliDataCadastro = this.$moment(this.Clientes.cliDataCadastro).format('YYYY-MM-DD')
                    }
                    if (this.Clientes.cliSenha != "" && this.Clientes.cliSenha != null){
                        this.Clientes.cliSenha = SHA256(this.Clientes.cliSenha).toString();
                    }
                    this.Clientes.cliTipoCli = 70;
                axios.put(`/Process/CliClientes/Update`, this.Clientes)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Limpar();
                            this.tab = 'dados';
                        }
                    });
            }
        },
        AddEndereco(){
            if (this.Endereco.cliCep == '') {
                this.$mensagemAviso("Digite um CEP válido.")
            }
            else if (this.Endereco.cliEndereco == '' || this.Endereco.cliEndereco == null) {
                this.$mensagemAviso("Campo Endereço Obrigatório.")
            }
            else if (this.Endereco.cliNumero == '' || this.Endereco.cliNumero == null) {
                this.$mensagemAviso("Campo Número Obrigatório.")
            }
            else if (this.Endereco.cliEstado == '' || this.Endereco.cliEstado == null) {
                this.$mensagemAviso("Campo Sigla Estado Obrigatório.")
            }
            else if (this.Endereco.cliCidade === '' || this.Endereco.cliCidade === null) {
            this.$mensagemAviso("Campo Cidade Obrigatório.")
            }
            else if (this.Endereco.cliBairro === '' || this.Endereco.cliBairro === null) {
                this.$mensagemAviso("Campo Bairro Obrigatório.")
            }
            else{
                this.Endereco.cliEstado = this.Endereco.cliEstado.toUpperCase();
                this.Endereco.cliId = this.Clientes.cliId;
                this.Endereco.grtId = 0;
                this.Endereco.cdtId = 0;
                this.Endereco.cliNumero = parseInt(this.Endereco.cliNumero);

                axios.post(`/Process/CliClientesEndereco/Add`, this.Endereco)
                .then(response => {
                    if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")  
                            this.Endereco.cceId = response.data;                 
                    }
                });
            }
        },
        PutEndereco(){
            if (this.Endereco.cliCep == '') {
                this.$mensagemAviso("Digite um CEP válido.")
            }
            else if (this.Endereco.cliEndereco == '' || this.Endereco.cliEndereco == null) {
                this.$mensagemAviso("Campo Endereço Obrigatório.")
            }
            else if (this.Endereco.cliNumero == '' || this.Endereco.cliNumero == null) {
                this.$mensagemAviso("Campo Número Obrigatório.")
            }
            else if (this.Endereco.cliEstado == '' || this.Endereco.cliEstado == null) {
                this.$mensagemAviso("Campo Sigla Estado Obrigatório.")
            }
            else if (this.Endereco.cliCidade === '' || this.Endereco.cliCidade === null) {
            this.$mensagemAviso("Campo Cidade Obrigatório.")
            }
            else if (this.Endereco.cliBairro === '' || this.Endereco.cliBairro === null) {
                this.$mensagemAviso("Campo Bairro Obrigatório.")
            }
            else{
                this.Endereco.cliEstado = this.Endereco.cliEstado.toUpperCase();
                this.Endereco.cliId = this.Clientes.cliId;
                this.Endereco.grtId = 0;
                this.Endereco.cdtId = 0;
                this.Endereco.cliNumero = parseInt(this.Endereco.cliNumero);

                axios.put(`/Process/CliClientesEndereco/Update`, this.Endereco)
                .then(response => {
                    if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")                
                    }
                });
            }
        },
        AddConta(){
            if (this.Contas.cdcBancoNome == '') {
                this.$mensagemAviso("Digite Nome do Banco válido.")
            }
            else if (this.Contas.cdcAgencia == '' || this.Contas.cdcAgencia == null) {
                this.$mensagemAviso("Campo Agência Obrigatório.")
            }
            else if (this.Contas.cdcConta == '' || this.Contas.cdcConta == null) {
                this.$mensagemAviso("Campo Conta Obrigatório.")
            }
            else if (this.Contas.cdcContaTitular == '' || this.Contas.cdcContaTitular == null) {
                this.$mensagemAviso("Campo Nome Titular da conta Obrigatório.")
            }
            else if (this.Contas.cdcTitularDocumento == '' || this.Contas.cdcTitularDocumento == null) {
                this.$mensagemAviso("Campo Documento (CPF/CNPJ) do Titular da conta Obrigatório.")
            }
            else{
                this.Contas.cliId = this.Clientes.cliId;

                axios.post(`/Process/CliClientesContas/Add`, this.Contas)
                .then(response => {
                    if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")  
                            this.Contas.cdcId = response.data;                 
                    }
                });
            }
        },
        PutConta(){
            if (this.Contas.cdcBancoNome == '') {
                this.$mensagemAviso("Digite Nome do Banco válido.")
            }
            else if (this.Contas.cdcAgencia == '' || this.Contas.cdcAgencia == null) {
                this.$mensagemAviso("Campo Agência Obrigatório.")
            }
            else if (this.Contas.cdcConta == '' || this.Contas.cdcConta == null) {
                this.$mensagemAviso("Campo Conta Obrigatório.")
            }
            else if (this.Contas.cdcContaTitular == '' || this.Contas.cdcContaTitular == null) {
                this.$mensagemAviso("Campo Nome Titular da conta Obrigatório.")
            }
            else if (this.Contas.cdcTitularDocumento == '' || this.Contas.cdcTitularDocumento == null) {
                this.$mensagemAviso("Campo Documento (CPF/CNPJ) do Titular da conta Obrigatório.")
            }
            else{
                this.Contas.cliId = this.Clientes.cliId;
                axios.put(`/Process/CliClientesContas/Update`, this.Contas)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Alterado.")                
                    }
                });
            }
        },
        async AddUsuario(value) {
            await axios.get(`/Process/UsrCadastroUsuarios/GetAll`)
                    .then(response => {
                        this.Usuarios = response.data.filter(x=> x.cusId != 2);
                    });

        const valida = this.Usuarios.filter(x=> x.cusEmail.toLowerCase() == this.Clientes.cliEmail.toLowerCase() && x.admTipoUsuario.tusId == 4)
          // console.log('this.Clientes')
          // console.log(this.Clientes)
           if (valida.length > 0){
             this.$mensagemAviso("E-mail de Usuário já cadastrado.")
           }
           else {
                this.Usuario.cusNome = this.Clientes.cliNomeEmpresa;
                this.Usuario.cusEmail = this.Clientes.cliEmail;
                this.Usuario.cusSenha = this.Clientes.cliWhatsapp.slice(-4);
                this.Usuario.cusWhatsapp = this.Clientes.cliWhatsapp;
                this.Usuario.tusId = 4;
                this.Usuario.cusAtivo = true;

                this.Usuario.admTipoUsuario = { tusId: 4 };
                this.Usuario.cliId = value;
                
                this.Usuario.cusDtCadastro = moment();
                this.Usuario.cusUrlImagem = "";

                axios.post(`/Process/UsrCadastroUsuarios/Add`, this.Usuario)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Usuário Incluído.")
                            this.CarregaPermissao(response.data);
                            this.EnviaEmailImob(this.Usuario.cusSenha);
                        }
                    });
                
            }
        },
        CarregaPermissao(value){
            this.TelasParcAcesso.map(element => {
                            const Permissoes2 = {
                                cusId: value,
                                cdtId: parseInt(element.cdtId),
                                cusTipo: parseInt(element.cusTipo)
                            };
                            return axios.post(`/Process/UsrControlePermissoes/Add`, Permissoes2)
                                .then(response => {
                                    if (response.data > 0) {
                                        return null;
                                    }
                                });
                            });
        },
        EnviaEmailImob(senha) {
            axios.get(`/Process/CadMensagensPadrao/GetAll`)
                    .then(response => {
                        this.templateEmail = response.data.filter(x=> x.mpdId == 6);
                            let _dadosEmail = {
                                            nomeDe: this.DadosGarantidora[0].itiNome,
                                            nomePara: this.Clientes.cliNomeEmpresa,
                                            assunto: 'Cadastro Sistema Aqui Garante Parceiros',
                                            destinatario: this.Clientes.cliEmail,
                                            emailResposta: this.DadosGarantidora[0].itiReferencia,
                                            textoEmail: this.templateEmail[0].mpdTexto.replace('[Email]',this.Clientes.cliEmail).replace('[SenhaInicial]', senha).replace('[LinkSistema]', 'https://parceiros.aquigarante.net.br'),
                                            nomeAnexo: '',
                                            anexo: '',
                                            tipoAnexo: ''
                                        }
                                axios.post(`/Process/AdmControleSmtp/Enviamail`,_dadosEmail)
                                .then(response => {
                                    if (response.data == 1){
                                    this.$mensagemSucesso("Enviado e-mail de acesso Parceiros! ");
                                    this.Limpar();
                                    this.tab = 'dados';        
                                    }
                                });

            });
        },
        AbreDialog(tit,texto){
            this.TituloDialog = "";
            this.TextoDialog = "";
            this.TituloDialog = tit;
            this.TextoDialog = texto;
            this.dialog = true;
        },
        async EnviarDocumento(){
            if (this.DocSelected == '') {
                this.$mensagemAviso("Selecione o Nome do Documento a ser enviado.")
            }
            else if (this.selectedFile == '' || this.selectedFile == null){
                this.$mensagemAviso("Selecione um Documento a ser enviado.")
            }
            else{
                if (this.selectedFile != '' && this.selectedFile != null) {
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.Documentos.cdoDocumento = this.DocSelected;
                        this.Documentos.cdoNomeDocumento = this.TiposDocumentos.filter(x=>x.itiId == this.DocSelected)[0].itiNome;
                        this.Documentos.cdoUrlDocumento = uploadSuccess.data.nameFile;
                        this.Documentos.cliId = this.Clientes.cliId;

                        axios.post(`/Process/CliClientesDocumentos/Add`, this.Documentos)
                            .then(response => {
                                if (response.data > 0) {
                                    this.$mensagemAvisoSucesso("Registro Incluído.")
                                    this.selectedFile = null;
                                    this.DocSelected = '';

                                     //carrega documentos
                                    axios.get(`/Process/CliClientesDocumentos/GetDocumentosByCli/`+ this.Clientes.cliId + '/0')
                                                .then(response => {
                                                    this.ListDocumentos = response.data;                               
                                                });

                                }
                        });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
              }
         }
        },
        DeleteDocumento(item){
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.cdoId;
                axios.delete(`/Process/CliClientesDocumentos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                         //carrega documentos
                         axios.get(`/Process/CliClientesDocumentos/GetDocumentosByCli/`+ this.Clientes.cliId + '/0')
                        .then(response => {
                            this.ListDocumentos = response.data;                               
                        });
                    }
                });
            }
            });
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.cliId;
                axios.delete(`/Process/CliClientes/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                        this.tab = 'dados';
                    }
                });
            }
            });
        },
        EditGrid(item) {
            this.tab = 'dados';
            // Lógica para editar o item   
            if (item.cliDataCadastro != "" && item.cliDataCadastro != null) {
                item.cliDataCadastro = this.$moment(item.cliDataCadastro).format('YYYY-MM-DD')
            }
            else{
                item.cliDataCadastro = '';
            }
            if (item.cliDataNascimento != "" && item.cliDataNascimento != null) {
                item.cliDataNascimento = this.$moment(item.cliDataNascimento).format('YYYY-MM-DD')
            }
            else{
                item.cliDataNascimento = '';
            }
            if (item.cliData1 != "" && item.cliData1 != null) {
                item.cliData1 = this.$moment(item.cliData1).format('YYYY-MM-DD')
            }
            else{
                item.cliData1 = '';
            }
            if (item.cliData2 != "" && item.cliData2 != null) {
                item.cliData2 = this.$moment(item.cliData2).format('YYYY-MM-DD')
            }
            else{
                item.cliData2 = '';
            }

            if (item.cliTexto1 === null){
                this.Clientes.cliTexto1 = " ";
            }

            if (item.cliTexto2 === null){
                this.Clientes.cliTexto2 = " ";
            }

            if (item.cliTexto3 === null){
                this.Clientes.cliTexto3 = " ";
            }

            item.cliSenha = null;
            this.Clientes = item;
            this.Clientes.cctId = item.cliClienteTipo.cctId; 
           
            //carrega endereco
            axios.get(`/Process/CliClientesEndereco/GetEnderecoByCli/`+ item.cliId + '/0')
                        .then(response => {
                            this.ListEndereco = response.data;                             
                            if (this.ListEndereco != ''){
                                this.Endereco = this.ListEndereco[0];
                                this.showend = true;
                            }            
                        });

             //carrega conta
             axios.get(`/Process/CliClientesContas/GetContasByCli/`+ item.cliId)
                        .then(response => {
                            this.ListContas = response.data;                             
                            if (this.ListContas != ''){
                                this.Contas = this.ListContas[0];
                                this.showconta = true;
                            }            
                        });

            //carrega contratos
            axios.get(`/Process/CliClientesContratos/GetContratosByCli/`+ item.cliId + '/0')
                        .then(response => {
                            this.ListContratos = response.data;                               
                        });

             //carrega documentos
             axios.get(`/Process/CliClientesDocumentos/GetDocumentosByCli/`+ item.cliId + '/0')
                        .then(response => {
                            this.ListDocumentos = response.data;                               
                        });


            this.$scrollToTop();
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "cliId", sortable: true, });
            this.headers.push({ text: "Nome", value: "cliNomeEmpresa", sortable: true, });
            this.headers.push({ text: "CPF", value: "cliCpf", sortable: true, });
            this.headers.push({ text: "CNPJ", value: "cliCnpj", sortable: true, });
            this.headers.push({ text: "Whatsapp", value: "cliWhatsapp", sortable: true, });
            this.headers.push({ text: "E-mail", value: "cliEmail", sortable: true, });
            this.headers.push({ text: "Cadastro", value: "cliDataCadastro" });
            this.headers.push({ text: "Visível", value: "cliVisivel", sortable: true, });
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        Buscacep(value) {
            if (value == ""){
                this.$mensagemAviso("Digite um CEP válido.")
            }
            else{
                fetch("https://viacep.com.br/ws/" + value + "/json/")
                .then(response => {
                    if (!response.ok) {
                        throw new Error("CEP inexistente");
                    }
                    return response.json();
                })
                .then(data => {
                    this.Retornocep = data;
                   // console.log('this.Retornocep')
                   // console.log(this.Retornocep)
                    if (this.Retornocep != null) {
                        this.Endereco.cliEndereco = this.Retornocep.logradouro;
                        this.Endereco.cliNumero = null;
                        this.Endereco.cliComplemento = null;
                        this.Endereco.cliEstado = this.Retornocep.uf;
                        this.Endereco.cliCidade = this.Retornocep.localidade;
                        this.Endereco.cliBairro = this.Retornocep.bairro;
                        this.showend = true;
                    } else {
                        this.$mensagemAviso("CEP inexistente");
                    }
                })
                .catch(error => {
                    console.error(error);
                });
            }
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        Get() {
            axios.get(`/Process/CliClienteTipo/GetAll`)
                        .then(response => {
                            this.TipoClientes = response.data;                         
                        
                    });

                    axios.get(`/Process/CliClientes/GetAll`)
                        .then(response => {
                            this.ListClientes = response.data.filter(x=> x.cliTipoCli == 70);  
               
                        });

                        axios.get('/Process/InsInstitucional/institucional/31/modeloscontratos')
                            .then(response => {
                                this.ModelosContrato = response.data
                            })
                            .catch(error => {
                                console.error(error);
                            });

                            axios.get('/Process/InsInstitucional/institucional/28/tiposdocumentos')
                            .then(response => {
                                this.TiposDocumentos = response.data;
                               
                                axios.get(`/Process/CliEntidadesDocumentos/GetByEntiDocs/70/0`)
                                    .then(response => {
                                        this.ListEntidadesDocumentos = response.data;    
                                            this.TiposDocumentos = this.TiposDocumentos.filter(x => {
                                                return this.ListEntidadesDocumentos.some(w => w.cteDocumentoCod === x.itiId);
                                            });

                                    });

                            })
                            .catch(error => {
                                console.error(error);
                            });
                           
                              //dados da Garantidora
                     axios.get('/Process/InsInstitucional/institucional/34/informacoes')
                    .then(response => {
                        this.DadosGarantidora = response.data;
                       // console.log('this.DadosGarantidora')
                       // console.log(this.DadosGarantidora)
                    })
                    .catch(error => {
                        console.error(error);
                    });

                    this.montaGrid();
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }

  .btn-ativo {
    background-color: green;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
}

.btn-inativo {
    background-color: red;
    color: white;
    padding: 4px 8px;
    border-radius: 4px;
}
</style>
