<template>
  <v-list>
    <v-card style="border-radius:0px !important">
      <v-layout> 
        <v-navigation-drawer v-model="drawer" v-if="DadosGarantidora!=''" :rail="rail" permanent @click="rail = false" style="background-color:#001a32"> <!--style="background-image: url('/images/1.png');background-size: cover;"-->
          <center><div style="padding: 10px;border-radius: 5px;background-color: #ffffff;width:180px;margin-top: 10px;">
            <a v-if="DadosGarantidora[0].insInstitucionalImagens!=''" :href="DadosGarantidora[0].itiCampoExtra2" target="_blank">
              <img :src="$imgURL + DadosGarantidora[0].insInstitucionalImagens[0].itgUrlImagem" alt="" style="width:140px;height:auto;">
            </a>
          </div></center>
          <v-list-item class="white-text" :prepend-avatar="User.urlimage" :title="User.nome" nav>
           <template v-slot:append>
              <v-btn class="white-text" variant="text" icon="mdi-export" @click.stop="Logout()"></v-btn>
            </template>
          </v-list-item>  
          <hr style="border-bottom: 1px solid #ffffff;" />
          <div v-if="User.nome == 'Avance'">
          <v-list v-for="item in menuItems" :key="item">
              <v-list-group :value="item.title">
                <template v-slot:activator="{ props }">
                  <v-list-item v-bind="props" :title="item.title" prepend-icon="mdi-cog-outline" class="white-text"></v-list-item>
                </template>
                 <v-list-item v-for="subItem in item.subItems" :key="subItem.title" :value="subItem.title" :title="subItem.title" :to="subItem.link" prepend-icon="'-'" class="white-text">
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
        <v-list-item value="Início" title="Início" to="/home" prepend-icon="'-'" class="white-text"></v-list-item>
        <v-list-item value="Meu cadastro" title="Meu cadastro" to="/imobiliarias" prepend-icon="mdi-passport" class="white-text"></v-list-item>
        <v-list-item value="Cadastro Usuários" title="Cadastro Usuários" to="/cadastrousuarios" prepend-icon="mdi-account-check" class="white-text"></v-list-item>
        <v-list-item value="Cadastro Clientes" title="Cadastro Clientes" to="/clientes" prepend-icon="mdi-human-queue" class="white-text"></v-list-item>
        <v-list-group value="Contratações">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" title="Contratações" prepend-icon="mdi-store" class="white-text"></v-list-item>
            </template>
              <v-list-item value="Em Andamento" title="Em Andamento" to="/listacontratacoes" prepend-icon="'-'" class="white-text"></v-list-item>
              <v-list-item value="Vigentes" title="Vigentes" to="/listacontratacoesvigentes" prepend-icon="'-'" class="white-text"></v-list-item>
              <v-list-item value="Negadas ou Canceladas" title="Negadas ou Canceladas" to="/ContratacoesNegadoCancelado" prepend-icon="'-'" class="white-text"></v-list-item>
        </v-list-group>
        <v-list-group value="Financeiro">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" title="Financeiro" prepend-icon="mdi-alarm-panel" class="white-text"></v-list-item>
            </template>
              <v-list-item value="Extrato financeiro" title="Extrato financeiro" to="" prepend-icon="'-'" class="white-text"></v-list-item>
        </v-list-group>
        <v-list-group value="Relatorios">
            <template v-slot:activator="{ props }">
              <v-list-item v-bind="props" title="Relatorios" prepend-icon="mdi-chart-bar" class="white-text"></v-list-item>
            </template>
              <v-list-item value="Relatorio financeiro" title="Relatório financeiro" to="" prepend-icon="'-'" class="white-text"></v-list-item>
              <v-list-item value="Relatorios de contratacoes" title="Relatório de contratações" to="" prepend-icon="'-'" class="white-text"></v-list-item>
              <v-list-item value="Lista de Imobiliarias" title="Lista de imobiliárias" to="" prepend-icon="'-'" class="white-text"></v-list-item>
              <v-list-item value="Lista de Parceiros" title="Lista de Parceiros" to="" prepend-icon="'-'" class="white-text"></v-list-item>
        </v-list-group>
        
        <!--v-list v-for="item in GrupoTelas" :key="item.grtId">
              <v-list-group :value="item.grtNome">
                <template v-slot:activator="{ props }">
                  <v-list-item v-bind="props" :title="item.grtNome" :prepend-icon="item.grtIcon" class="white-text"></v-list-item>
                </template>
                 <v-list-item v-for="subItem in Telas.filter(x=> x.admGrupoTelas.grtId == item.grtId)" :key="subItem.cdtId" :value="subItem.cdtNome" :title="subItem.cdtNome" :to="subItem.cdtUrl" prepend-icon="'-'" class="white-text">
              </v-list-item>
            </v-list-group>
          </v-list>
          <div v-if="QtdeSegInst > 0">
          <v-list v-for="item in menuSegInst" :key="item">
              <v-list-group :value="item.title">
                <template v-slot:activator="{ props }">
                  <v-list-item v-bind="props" :title="item.title" prepend-icon="mdi-pencil" class="white-text"></v-list-item>
                </template>
                 <v-list-item v-for="subItem in item.subItems" :key="subItem.title" :value="subItem.title" :title="subItem.title" :to="subItem.link" prepend-icon="'-'" class="white-text">
              </v-list-item>
            </v-list-group>
          </v-list>
        </div>
          <v-list-- v-for="itemi in GrupoTelasInst" :key="itemi.gtiId">
              <v-list-group :value="itemi.gtiNome">
                <template v-slot:activator="{ props }">
                  <v-list-item v-bind="props" :title="itemi.gtiNome" :prepend-icon="itemi.gtiIcon" class="white-text"></v-list-item>
                </template>
                 <v-list-item v-for="subItemi in TelasInst.filter(x=> x.admGrupoTelasInst.gtiId == itemi.gtiId)" :key="subItemi.ctiId" :value="subItemi.ctiNome" :title="subItemi.ctiNome" :to="'/institucional/' + subItemi.ctiId + '/' + subItemi.ctiUrl" prepend-icon="'-'" class="white-text">
              </v-list-item>
            </v-list-group>
          </v-list-->
        </v-navigation-drawer>
        <v-main style="min-height: 800px;"></v-main>
      </v-layout>
    </v-card>
  </v-list>
</template>

<script>
import axios from 'axios';
export default {
  name: 'PageHeader',
  components: {
  },
  data: () => ({
    drawer: true,
    rail: false,
    isSidebarVisible: false,
    isDropdownOpen: false,
    menuItems: [
      {
        title: 'Adm', isOpen: false, subItems: [
          { title: 'Cadastro Grupo Telas', link: '/GrupoTelas' },
          { title: 'Cadastro Telas', link: '/CadastroTelas' },
          { title: 'Configura Imgs Produtos', link: '/ConfigListaImagensTelas' },
          { title: 'Configura Arqs Produtos', link: '/ConfigListaArquivosTelas' },
          { title: 'Configura Grids Produtos', link: '/ConfigListaGridsTelas' },
          { title: 'Cadastro Grupo Institucional', link: '/GrupoTelasInstitucional' },
          { title: 'Cadastro Telas Institucional', link: '/CadastroTelasInstitucional' },
          { title: 'Configura Institucional', link: '/ConfigInstitucional' },
          { title: 'Configura Imgs Institucional', link: '/ConfigListaImagens' },
          { title: 'Configura Arqs Institucional', link: '/ConfigListaArquivos' },
          { title: 'Configura Grids Institucional', link: '/ConfigListaGrids' },
          { title: 'Controle Smtp', link: '/ControleSmtp' },
          { title: 'Cadastro Tipos de Usuário ', link: '/CadastroTipoUsuario' },
        ]
      },
    ],
    menuSegInst: [
      {
        title: 'Segmentos Inst', isOpen: false, subItems: [
          { title: 'Cadastro Segmentos', link: '/SegmentosInst' },
        ]
      },
    ],
    QtdeSegInst: 0,
    Telas:[],
    GrupoTelas:[],
    TelasInst:[],
    GrupoTelasInst:[],
    DadosGarantidora:[],
  }),
  methods: {
    toggleMenu(item) {
      item.isOpen = !item.isOpen;
    },
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },
    checkLogin() {
      let cvalue = this.$Cookies.get("_vusavImob");
      if (cvalue != this.$CookieUsuario) {
        window.location.href = '/';
      }
    },
    Logout() {
            this.$Cookies.remove("_vusavImob");
            localStorage.removeItem('_useravImob');
            window.location.href = '/';
        },
  },
  created() {
    this.checkLogin();
    const valorArmazenado = localStorage.getItem('_useravImob');
    if (valorArmazenado != '') {
      this.User = JSON.parse(valorArmazenado);

      if (this.User.urlimage == '') {
        this.User.urlimage = "/images/userNo.png";
      }
      else {
        this.User.urlimage = this.$imgURL + this.User.urlimage;
      }
    }

    if (window.innerWidth <= 768){
      this.rail = true;
    }

    //carrega menu grupo de telas e telas
    axios.get(`/Process/AdmGrupoTelas/GetAll`)
      .then(response => {
        this.GrupoTelas = response.data.filter(x => x.grtVisivel).sort((a, b) => {
                                                                                  const valorA = parseInt(a.grtOrdem) || 0; 
                                                                                  const valorB = parseInt(b.grtOrdem) || 0; 
                                                                                  return valorA - valorB;
                                                                                  });

            axios.get(`Process/AdmCadastroTelas/GetAll`)
                .then(response => {
                    this.Telas = response.data.filter(x => x.cdtVisivel).sort((a, b) => {
                                                                                          const valorA = parseInt(a.cdtOrdem) || 0;
                                                                                          const valorB = parseInt(b.cdtOrdem) || 0;
                                                                                          return valorA - valorB;
                                                                                        });
                 

                    const arrayFiltrado2 = this.Telas.filter(objeto => this.User.menu.some(item => item.cdtId === objeto.cdtId && item.cusTipo === 1));
                    this.Telas = arrayFiltrado2;
                    const arrayFiltrado1 = this.GrupoTelas.filter(objeto => this.Telas.some(item => item.admGrupoTelas && item.admGrupoTelas.grtId === objeto.grtId));
                    this.GrupoTelas = arrayFiltrado1;
                });
      });

        //carrega menu grupo de telas e telas
    axios.get(`/Process/AdmGrupoTelasInst/GetAll`)
      .then(response => {
        this.GrupoTelasInst = response.data.filter(x => x.gtiVisivel).sort((a, b) => {
                                                                                  const valorA = parseInt(a.gtiOrdem) || 0; 
                                                                                  const valorB = parseInt(b.gtiOrdem) || 0; 
                                                                                  return valorA - valorB;
                                                                                  });

            axios.get(`Process/AdmCadastroTelasInst/GetAll`)
                .then(response => {
                    this.TelasInst = response.data.filter(x => x.ctiVisivel).sort((a, b) => {
                                                                                          const valorA = parseInt(a.ctiOrdem) || 0;
                                                                                          const valorB = parseInt(b.ctiOrdem) || 0;
                                                                                          return valorA - valorB;
                                                                                        });
                 

                    const arrayFiltrado3 = this.TelasInst.filter(objeto => this.User.menu.some(item => item.cdtId === objeto.ctiId && item.cusTipo === 2));
                    this.TelasInst = arrayFiltrado3;
                    const arrayFiltrado4 = this.GrupoTelasInst.filter(objeto => this.TelasInst.some(item => item.admGrupoTelasInst && item.admGrupoTelasInst.gtiId === objeto.gtiId));
                    this.GrupoTelasInst = arrayFiltrado4;
                });
      });

    axios.get(`/Process/AdmConfigTelaInstitucional/GetByQtdSegImg`)
      .then(response => {
        this.QtdeSegInst = response.data;
      });

         //dados da Garantidora
         axios.get('/Process/InsInstitucional/institucional/34/informacoes')
                                .then(response => {
                                    this.DadosGarantidora = response.data;
                                    //console.log('this.DadosGarantidora')
                                   // console.log(this.DadosGarantidora)
                                })
                                .catch(error => {
                                    console.error(error);
                                });
  },

}
</script>

<style>
.white-text{
  color:#ffffff;
}

.v-list-group__items .v-list-item {
    -webkit-padding-start: 0px !important;
    padding-inline-start: 0px !important;
}

.v-list-item-title{
  font-size: 14px !important;
}

.v-list-item--density-default.v-list-item--one-line {
    min-height: 28px !important;
    padding-top: 4px;
    padding-bottom: 4px;
}

.v-list {
    padding: 0px 0 !important;
}

</style>
