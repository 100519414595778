<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Controle SMTP</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="ControleSmtp.stpId" label="Id" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="ControleSmtp.stpSmtp"
                                        label="SMTP" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="ControleSmtp.stpEmail"
                                        label="E-mail" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model="ControleSmtp.stpSenha" :type="senhaVisivel ? 'text' : 'password'" label="Senha" variant="outlined" :append-icon="senhaVisivel ? 'mdi-eye-off' : 'mdi-eye'" @click:append="toggleSenhaVisivel"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-text-field v-model.number="ControleSmtp.stpPorta" type="number"
                                        label="Porta" variant="outlined"></v-text-field>
                                </v-col>
                              
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Get()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="ControleSmtp.stpId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'ControleSmtpView',
    components: {
        EasyDataTable
    },
    data: () => ({
        ControleSmtp: {
            stpId: 0,
            stpSmtp: '',
            stpEmail: '',
            stpSenha: '',
            stpPorta: 0,
        },
        ControleSmtps: [],
        senhaVisivel: false,
    }),
    methods: {
        toggleSenhaVisivel() {
        this.senhaVisivel = !this.senhaVisivel;
        },
        Add() {
            if (this.ControleSmtp.stpSmtp == '') {
                this.$mensagemAviso("SMTP Obrigatório.")
            }
            else if (this.ControleSmtp.stpEmail == ''){
                this.$mensagemAviso("E-mail Obrigatório.")
            }
            else if (this.ControleSmtp.stpSenha == ''){
                this.$mensagemAviso("Senha Obrigatória.")
            }
            else if (this.ControleSmtp.stpPorta == ''){
                this.$mensagemAviso("Porta Obrigatória.")
            }
            else {
                axios.post(`/Process/AdmControleSmtp/Add`, this.ControleSmtp)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                        }
                    });
            }
        },
        Put() {
            if (this.ControleSmtp.stpSmtp == '') {
                this.$mensagemAviso("SMTP Obrigatório.")
            }
            else if (this.ControleSmtp.stpEmail == ''){
                this.$mensagemAviso("E-mail Obrigatório.")
            }
            else if (this.ControleSmtp.stpSenha == ''){
                this.$mensagemAviso("Senha Obrigatória.")
            }
            else if (this.ControleSmtp.stpPorta == ''){
                this.$mensagemAviso("Porta Obrigatória.")
            }
            else {
                //console.log(this.ControleSmtp)
                axios.put(`/Process/AdmControleSmtp/Update`, this.ControleSmtp)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                        }
                    });
            }
        },
        Get() {
            axios.get(`/Process/AdmControleSmtp/GetAll`)
                .then(response => {
                    this.ControleSmtps = response.data;
                    this.ControleSmtp = this.ControleSmtps;
                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
