<template>
    <div>
        
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 10px;padding-right: 10px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left" style="display: inline-flex;text-decoration: underline;">
                                <h4 class="text-black h4" style="padding-right: 5px;">Tela: {{ NomeTela }} </h4>
                            </div>
                        </div>
                    </div>
                    <div class="">
                        <div>
                            <table>
                                <tr class="semana">
                                <td style="padding: 5px;">
                                    <button type="button" class="btn btn-info" @click="SelecionaSituacao(4)">
                                        Não Aprovado
                                    </button>
                                </td>
                                <td style="padding: 5px;">
                                    <button type="button" class="btn btn-info" @click="SelecionaSituacao(6)">
                                        Cancelado
                                    </button>
                                </td>
                                </tr>
                            </table>
                        </div>
                        <div class="faq-wrap">
                            <div class="card-box" style="padding-left: 10px;padding-right: 10px;padding-bottom: 10px;margin-top: 20px;">
                                <v-container>
                                    <v-row>
                                        <v-col cols="12" md="4">
                                            <v-text-field v-model="searchValue" label="Pesquisa" variant="outlined"
                                                required></v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <easy-data-table :headers="headers" :items="ListPedidosGeralFiltro" v-model="itemsSelected" alternating
                                    theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                                    <template #item-pedDataPedido="item">
                                        <span> {{ item.pedDataPedido !== null ? formattedDate(item.pedDataPedido) : null }}</span>
                                    </template>
                                    <template #item-pedValorTotalPedido="item">
                                        {{ item.pedValorTotalPedido !== null ? formatarValor(item.pedValorTotalPedido) : 0 }}
                                    </template>
                                    <template #item-pedSituacaoPedido="item">
                                        <button v-if="item.pedSituacaoPedido=='Não Aprovado'" class="btn btn-danger">{{ item.pedSituacaoPedido }}</button>
                                        <button v-if="item.pedSituacaoPedido=='Cancelado'" class="btn btn-orange">{{ item.pedSituacaoPedido }}</button>
                                    </template>
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon>mdi-folder-open</v-icon>
                                            <v-tooltip activator="parent" location="top">Detalhes</v-tooltip>
                                        </v-btn>

                                   </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
     
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'

export default {
    name: 'ContratacoesNegadoCanceladoView',
    components: {
        EasyDataTable,
        moment
    },
    data: () => ({
        searchValue: '',
        NomeTela:'Contratações',
        InfoTela:'Contratações Negadas ou Canceladas',
        MostraGrid: true,
        headers: [],
        Idiomas:[],
        vshowidioma: true,
        CadastroTelas:[{ cdtId: 28, cdtNome: "Lista Contratações" }],
        Pedidos:[],
        ListPedidosGeral:[],
        ListPedidosGeralFiltro:[],
    }),
    methods: {
     proximaDataDoDia(numeroDoDia) {
        // Obtém a data atual
        const dataAtual = moment(); // Ou você pode passar uma data específica para o moment(), se desejar

        // Obtém o número do dia da semana atual
        const numeroDoDiaAtual = dataAtual.isoWeekday();

        // Calcula a diferença de dias até o próximo dia desejado
        if (numeroDoDia != numeroDoDiaAtual){
            let diferencaDias = numeroDoDia - numeroDoDiaAtual;
            if (diferencaDias <= 0) {
                diferencaDias += 7; // Se o dia já passou na semana atual, adiciona 7 dias para a próxima ocorrência
            }

            // Calcula a próxima data do dia desejado
            const proximaData = dataAtual.add(diferencaDias, 'days');
            return proximaData.format('YYYY-MM-DD');
        }
        else{
           // console.log('dataAtual')
           // console.log(dataAtual)
            return dataAtual.format('YYYY-MM-DD');
        }
       
        },
        SelecionaSituacao(value){
            if (value > 0){          
                this.ListPedidosGeralFiltro = this.ListPedidosGeral.filter(x=> x.pspId == value);
            }
            else{
                this.ListPedidosGeralFiltro = this.ListPedidosGeral;
            }
          
        },
        onReady(editor)  {
            // Insert the toolbar before the editable area.
            editor.ui.getEditableElement().parentElement.insertBefore(
                editor.ui.view.toolbar.element,
                editor.ui.getEditableElement()
            );
        },
        preprocessData(data) {
            return data.map(item => {
            return {
                ...item,
                proOrdem: parseInt(item.proOrdem),
            };
            });
        },
        formattedDate(value) {
            if (value!=null || value != ""){
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else{
                return "";
            }
           
        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        EditGrid(item) {
            this.$router.push('/contratacaonegadadetalhe/' + item.pedId);
        },
        montaGrid(){
            this.headers=[];
            this.headers.push({ text: "Id", value: "pedId", sortable: true, });
            this.headers.push({ text: "Data", value: "pedDataPedido", sortable: true, });
            this.headers.push({ text: "Cliente", value: "cliNome", sortable: true, });
            this.headers.push({ text: "CPF", value: "cliCpf", sortable: true, });
            this.headers.push({ text: "CNPJ", value: "cliCnpj", sortable: true, });
            this.headers.push({ text: "Whatsapp", value: "cliWhatsapp", sortable: true, });
            this.headers.push({ text: "Valor", value: "pedValorTotalPedido", sortable: true, });
            this.headers.push({ text: "Situação", value: "pedSituacaoPedido", sortable: true, });
            this.headers.push({ text: 'Ações', value: 'actions' });
        },
        Get() {
            axios.get(`/Process/CadIdiomas/GetAll`)
                .then(response => {
                    this.Idiomas = response.data.filter(x => x.idiAtivo);
                    if (this.Idiomas.length == 1) {
                        this.Pedidos.idiId = this.Idiomas[0].idiId;
                        this.vshowidioma = false;
                    }

                    const valorArmazenado = localStorage.getItem('_useravImob');
                        if (valorArmazenado != '') {
                        this.User = JSON.parse(valorArmazenado);
                                axios.get(`/Process/PedPedidos/GetAllValorTotal`) //GetAllByCtiId/14
                                    .then(response => {
                                        this.ListPedidosGeral = response.data.filter(x=> x.idiId == 1 && x.pspId == 4 || x.pspId == 6 && x.cliIdPai == this.User.cliId);     
                                        this.ListPedidosGeralFiltro = this.ListPedidosGeral.sort((a, b) => {
                                                                                            const valorA = parseInt(a.pedId) || 0;
                                                                                            const valorB = parseInt(b.pedId) || 0;
                                                                                            return valorA - valorB;
                                                                                        }).reverse();
                                        
                                       // console.log('this.ListPedidosGeralFiltro')   
                                      //  console.log(this.ListPedidosGeralFiltro)   
                                    });
                                this.montaGrid();
                                this.Pedidos.cdtId = 28;
                                this.Pedidos.grtId = 7;

                               // console.log('this.Pedidos')    
                              //  console.log(this.Pedidos)    
                        }     


                });
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style scoped>
  .tabinfo{
    font-size:14px;
    text-transform: capitalize;
  }
</style>
