<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Cadastro de Documentos x Entidades</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="DocEnt.cdeId" label="Id" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select v-model="DocEnt.cdeEntidadeCod" label="Entidade" :items="ListEntidades"
                                        item-title="itiNome" item-value="itiId" variant="outlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="6">
                                    <v-select v-model="DocEnt.cteDocumentoCod" label="Documentos" :items="ListDocumentos"
                                        item-title="itiNome" item-value="itiId" variant="outlined">
                                    </v-select>
                                </v-col>
                             
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="DocEnt.cdeId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="outlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="DocEnts" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-cdeEntidadeCod="item">
                            <span>{{ ListEntidades.filter(x=> x.itiId == item.cdeEntidadeCod)[0].itiNome }}</span>
                        </template>
                        <template #item-cteDocumentoCod="item">
                            <span>{{ ListDocumentos.filter(x=> x.itiId == item.cteDocumentoCod)[0].itiNome }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'DocumentosEntidadesView',
    components: {
        EasyDataTable
    },
    data: () => ({
        DocEnt: {
            cdeId: 0,
            cdeEntidadeCod: '',
            cteDocumentoCod: '',
        },
        DocEnts: [],
        headers: [
            { text: "Id", value: "cdeId", sortable: true, },
            { text: "Entidade", value: "cdeEntidadeCod", sortable: true, },
            { text: "Documento", value: "cteDocumentoCod", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        ListDocumentos:[],
        ListEntidades:[],
        searchValue: '',
    }),
    methods: {
        Limpar() {
            this.DocEnt.cdeId = 0;
            this.DocEnt.cdeEntidadeCod = '';
            this.DocEnt.cteDocumentoCod = '';
            this.Get();
        },
        Add() {
            if (this.DocEnt.cdeEntidadeCod == '') {
                this.$mensagemAviso("Entidade Obrigatório.")
            }
            else if (this.DocEnt.cteDocumentoCod == ''){
                this.$mensagemAviso("Documento Obrigatório.")
            }
            else {
              
                axios.post(`/Process/CliEntidadesDocumentos/Add`, this.DocEnt)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        Put() {
            if (this.DocEnt.cdeEntidadeCod == '') {
                this.$mensagemAviso("Entidade Obrigatório.")
            }
            else if (this.DocEnt.cteDocumentoCod == ''){
                this.$mensagemAviso("Documento Obrigatório.")
            }
            else {               
                axios.put(`/Process/CliEntidadesDocumentos/Update`, this.DocEnt)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        EditGrid(item) {
            // Lógica para editar o item
            this.DocEnt = item;           
            this.$scrollToTop();
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.cdeId;
                axios.delete(`/Process/CliEntidadesDocumentos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                    }
                });
            }
            });
        },
        Get() {
            axios.get(`/Process/CliEntidadesDocumentos/GetAll`)
                .then(response => {
                    this.DocEnts = response.data;
                    //console.log('this.DocEnts')
                   // console.log(this.DocEnts)
                });

            axios.get('/Process/InsInstitucional/institucional/28/tiposdocumentos')
                .then(response => {
                    this.ListDocumentos = response.data
                })
                .catch(error => {
                    console.error(error);
                });

                axios.get('/Process/InsInstitucional/institucional/29/cadastrooentidades')
                .then(response => {
                    this.ListEntidades = response.data
                })
                .catch(error => {
                    console.error(error);
                });

               
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
