<template>
  <div>
    <div class="" style="padding-top: 20px;">
      <div class="footer-wrap pd-20 mb-20">
          <div class="container">
            <div class="row">
                <div class="col-lg-12">
                     
                </div>
            </div>
          </div>
      </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'FooterPage',
  components: {

  },
  data: () => ({
  }),
  methods: {
  },
  created () {

  }
}
</script>

<style scoped>
</style>
