<template>
   <div class="loading-overlay">
      <v-progress-circular
        :size="70"
        :width="7" 
        color="white"
        indeterminate
      ></v-progress-circular>
    </div>
</template>

<script>

export default {
  name: 'PageLoader',
  components: {

  },
  data: () => ({
  
  }),
  methods: {
  
  },
  created () {
  }
}
</script>

<style scoped>
.v-progress-circular {
  margin: 1rem;
}
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>
