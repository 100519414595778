<template>
    <div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box"
                    style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;padding-top: 20px;">
                    <div class="card-header">
                        <div class="clearfix">
                            <div class="pull-left">
                                <h4 class="text-black h4">Cadastro de Contratos x Entidades</h4>
                            </div>
                        </div>
                    </div>
                    <v-form>
                        <v-container>
                            <v-row>
                                <v-col cols="12" md="4" v-show="false">
                                    <v-text-field v-model="ContEnt.cteId" label="Id" variant="outlined"></v-text-field>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-select v-model="ContEnt.cteEntidadeCod" label="Entidade" :items="ListEntidades"
                                        item-title="itiNome" item-value="itiId" variant="outlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-select v-model="ContEnt.cteTipoContratoCod" label="Tipo Contrato" :items="ListTiposContratos"
                                        item-title="itiNome" item-value="itiId" variant="outlined">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-select v-model="ContEnt.cteModeloContratoCod" label="Contratos" :items="ListContratos"
                                        item-title="itiNome" item-value="itiId" variant="outlined">
                                    </v-select>
                                </v-col>
                             
                            </v-row>
                        </v-container>
                    </v-form>
                    <form>
                        <div class="row">
                            <div class="col-md-12 text-center">
                                <button type="button" @click="Limpar()" class="btn btn-link">
                                    Cancelar
                                </button>
                                <button v-if="ContEnt.cteId != 0" type="button" class="btn btn-success" @click="Put()">
                                    Alterar
                                </button>
                                <button v-else type="button" class="btn btn-success" @click="Add()">
                                    Salvar
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="">
            <div class="faq-wrap">
                <div class="card-box" style="padding-left: 20px;padding-right: 20px;padding-bottom: 10px;margin-top: 20px;">
                    <v-container>
                        <v-row>
                            <v-col cols="12" md="4">
                                <v-text-field v-model="searchValue" label="Pesquisa" variant="outlined"
                                    required></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <easy-data-table :headers="headers" :items="ContEnts" v-model="itemsSelected" alternating
                        theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                        body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                        <template #item-cteEntidadeCod="item">
                            <span>{{ ListEntidades.filter(x=> x.itiId == item.cteEntidadeCod)[0].itiNome }}</span>
                        </template>
                        <template #item-cteTipoContratoCod="item">
                            <span>{{ ListTiposContratos.filter(x=> x.itiId == item.cteTipoContratoCod)[0].itiNome }}</span>
                        </template>
                        <template #item-cteModeloContratoCod="item">
                            <span>{{ ListContratos.filter(x=> x.itiId == item.cteModeloContratoCod)[0].itiReferencia }}</span>
                        </template>
                        <template #item-actions="item">
                            <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                <v-icon>mdi-file-edit-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                            </v-btn>

                            <v-btn icon @click="DeleteGrid(item)" variant="text">
                                <v-icon>mdi-delete-forever-outline</v-icon>
                                <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                            </v-btn>
                        </template>
                    </easy-data-table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
export default {
    name: 'ContratosEntidadesView',
    components: {
        EasyDataTable
    },
    data: () => ({
        ContEnt: {
            cteId: 0,
            cteEntidadeCod: '',
            cteTipoContratoCod: '',
            cteModeloContratoCod: '',
        },
        ContEnts: [],
        headers: [
            { text: "Id", value: "cteId", sortable: true, },
            { text: "Entidade", value: "cteEntidadeCod", sortable: true, },
            { text: "Tipo", value: "cteTipoContratoCod", sortable: true, },
            { text: "Contrato", value: "cteModeloContratoCod", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        ListContratos:[],
        ListEntidades:[],
        ListTiposContratos:[],
        searchValue: '',
        itemsSelected: [],
    }),
    methods: {
        Limpar() {
            this.ContEnt.cteId = 0;
            this.ContEnt.cteEntidadeCod = '';
            this.ContEnt.cteTipoContratoCod = '';
            this.ContEnt.cteModeloContratoCod = '';
            this.Get();
        },
        Add() {
            if (this.ContEnt.cteEntidadeCod == '') {
                this.$mensagemAviso("Entidade Obrigatório.")
            }
            else if (this.ContEnt.cteTipoContratoCod == ''){
                this.$mensagemAviso("Tipo Obrigatório.")
            }
            else if (this.ContEnt.cteModeloContratoCod == ''){
                this.$mensagemAviso("Contrato Obrigatório.")
            }
            else {
              
                axios.post(`/Process/CliEntidadesContratos/Add`, this.ContEnt)
                    .then(response => {
                        if (response.data > 0) {
                            this.$mensagemAvisoSucesso("Registro Incluído.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        Put() {
            if (this.ContEnt.cteEntidadeCod == '') {
                this.$mensagemAviso("Entidade Obrigatório.")
            }
            else if (this.ContEnt.cteTipoContratoCod == ''){
                this.$mensagemAviso("Tipo Obrigatório.")
            }
            else if (this.ContEnt.cteModeloContratoCod == ''){
                this.$mensagemAviso("Contrato Obrigatório.")
            }
            else {               
                axios.put(`/Process/CliEntidadesContratos/Update`, this.ContEnt)
                    .then(response => {
                        if (response.status == 200) {
                            this.$mensagemAvisoSucesso("Registro Alterado.")
                            this.Get();
                            this.Limpar();
                        }
                    });
            }
        },
        EditGrid(item) {
            // Lógica para editar o item
            this.ContEnt = item;     
            this.$scrollToTop();
        },
        DeleteGrid(item) {

            this.$mensagemConfirma('Tem certeza que deseja excluir?')
            .then((result) => {
            if (result.isConfirmed) {
                // Lógica para excluir o item
                const param1 = item.cteId;
                axios.delete(`/Process/CliEntidadesContratos/Delete/${param1}`)
                .then(response => {
                    if (response.status == 200) {
                        this.$mensagemAvisoSucesso("Registro Excluido.")
                        this.Get();
                        this.Limpar();
                    }
                });
            }
            });
        },
        Get() {
            axios.get(`/Process/CliEntidadesContratos/GetAll`)
                .then(response => {
                    this.ContEnts = response.data;
                    //console.log('this.ContEnts')
                    //console.log(this.ContEnts)
                });

            axios.get('/Process/InsInstitucional/institucional/31/modeloscontratos')
                .then(response => {
                    this.ListContratos = response.data
                })
                .catch(error => {
                    console.error(error);
                });

                axios.get('/Process/InsInstitucional/institucional/30/tiposcontratos')
                .then(response => {
                    this.ListTiposContratos = response.data
                })
                .catch(error => {
                    console.error(error);
                });

                axios.get('/Process/InsInstitucional/institucional/29/cadastrooentidades')
                .then(response => {
                    this.ListEntidades = response.data
                })
                .catch(error => {
                    console.error(error);
                });

               
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
